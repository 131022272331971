import './style.scss';
import useBodyClass from 'hooks/useBodyClass';
import BlogPosts from 'outside/components/BlogPosts';
import FAQ, { faqData } from 'outside/components/FAQ';
import CTAHero from 'outside/components/CTAHero';
import Customers from 'outside/components/Customers';
import Features, { FeaturesIcons } from 'outside/components/Features';
import PricingTiers from 'outside/components/PricingTiers';

function SST(props) {
  const lang = props.lang;
  useBodyClass('outside-wide');

  const formatNumber = (num) => {
    const formatWithSuffix = (num, divisor, suffix) => {
      const dividedNum = num / divisor;
      // Check if the number is whole (no decimals) after division
      if (Math.floor(dividedNum) === dividedNum) {
        return dividedNum + suffix; // Return without decimal point
      } else {
        return dividedNum.toFixed(1) + suffix; // Format to one decimal place and add suffix
      }
    }

    if (num >= 1000000000) {
      return formatWithSuffix(num, 1000000000, 'b');
    } else if (num >= 1000000) {
      return formatWithSuffix(num, 1000000, 'm');
    } else if (num >= 1000) {
      return formatWithSuffix(num, 1000, 'k');
    } else {
      return num.toString(); // Convert to string if less than 1000
    }
  }

  const meta_en = {
    starts_at: "Starts at",
    per_month: <>per<br /> month</>,
    vat_excl: "Prices excl VAT",
    sites_monitored: "Sites monitored",
    per_week: <>per<br /> week</>,
  }

  const meta_sv = {
    starts_at: "Från",
    per_month: <>per<br /> månad</>,
    vat_excl: "Priser exkl moms",
    sites_monitored: "Bevakade sidor",
    per_week: <>per<br /> vecka</>,
  }

  const meta = lang === "sv" ? meta_sv : meta_en;

  const generateSections = (tier) => [
    {
      tier: tier.name.charAt(0).toUpperCase() + tier.name.slice(1),
      type: "header"
    },
    {
      type: "price",
      title: meta.starts_at,
      value: tier.name === "micro" ? "€25*" : "€" + tier.price,
      unit: meta.per_month,
      main: true
    },
    {
      type: "sites",
      title: meta.sites_monitored,
      value: tier.monitors,
      unit: meta.per_week,
      main: false
    },
    {
      type: "CTA",
      terms: meta.vat_excl,
    }
  ];

  return (
    <div className="SST">
      <CTAHero
        lang={lang}
        title={
          lang === "sv" ?
            <>GDPR monitorering<br /> snabbt och enkelt</>
            :
            <>Easy and reliable<br /> GDPR monitoring</>
        }
        tagline={
          lang === "sv" ?
            <>Skanna din webbplats för GDPR-sårbarheter<br /> för bättre compliance och användarintegritet</>
            :
            <>Scan your site for GDPR vulnerabilities and<br /> increase your compliance and user integrity</>
        }
        cta="hubspot"
      />

      <Customers lang={lang} />

      <Features
        items={
          lang === "sv" ?
            []
            :
            [
              {
                preTitle: <>Ensure GDPR<br /> compliance</>,
                icon: <FeaturesIcons.GDPR className="Features__icon" />,
                title: <>Keep your users'<br /> data in-house</>,
              },
              {
                preTitle: <>Be fully<br /> informed</>,
                icon: <FeaturesIcons.Analysis className="Features__icon" />,
                title: <>Get detailed reports<br /> on data leakage</>,
              },
              {
                preTitle: <>Never miss<br /> a day</>,
                icon: <FeaturesIcons.Fast className="Features__icon" />,
                title: <>Up-to-date compliance<br /> with daily monitoring</>,
              }
            ]
        }
      />

      {/* <Integrations lang={lang} /> */}

      <FAQ faq={lang === "sv" ? faqData.gdpr.en : faqData.gdpr.en} />

      <BlogPosts
        filter={[19, 20]}
        cols={2}
        title={
          lang === "sv" ?
            "Mer på vår blogg"
            :
            "More on our blog"
        }
        description={
          lang === "sv" ?
            "Läs våra jämförelser mellan Cogny och andra produkter"
            :
            "Read our comparisons of Cogny and other products"
        }
      />

      <PricingTiers
        lang={lang}
        title={
          lang === "sv" ?
            "Priser"
            :
            "Pricing"
        }
        intro={
          lang === "sv" ?
            <>
              Varje GDPR-skanning kostar 50 Credits och kan ställas in att skanna dagligen eller veckovis, nedan är prisexempel på hur många sidor du skulle kunna skanna veckovis per tier.
            </>
            :
            <>
              Each GDPR scan charges 50 Credits and can be set to scan daily or weekly, below are price examples on how many sites you could scan weekly per tier.
            </>
        }
        generateSections={generateSections}
        theme="gray"
        features={{
          title: lang === "sv" ? "Mer om Micro" : "More about our Micro tier",
          content: (lang === "sv" ?
            (
              <>
                Den fasta avgiften på 9 EUR för Micro ger 75 Credits och eftersom 4 skanningar kostar 200 credits kommer 125 ytterligare credits att köpas för 16,25 EUR vilket ger ett totalt pris på 25,25 EUR för Micro-paketet.
              </>
            ) : (
              <>
                The fixed 9 EUR fee for Micro gets 75 Credits and as 4 scans costs 200 credits an 125 additional credits will be purchased for 16.25 EUR adding up to total 25,25 EUR for the Micro tier.
              </>
            )
          ),
        }}
      />

    </div>
  );
}

export default SST;
