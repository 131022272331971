import './style.scss';
import useBodyClass from 'hooks/useBodyClass';
import { useOutsideData } from 'hooks/useOutsideData';
import sstBackgroundImage from './assets/sst-background.webp';
import { useEffect } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import FAQ, { faqData } from 'outside/components/FAQ';
import PricingTiers from 'outside/components/PricingTiers';

function Pricing(props) {
  const windowSize = useWindowSize();
  const lang = props.lang;
  const { setOutsideBackgroundStyle } = useOutsideData();
  useBodyClass('outside-wide');

  useEffect(() => {
    const backgroundPosition = windowSize.width >= 768 ? "1600px auto" : "680px auto";
    const backgroundStyles = {
      backgroundImage: "url(" + sstBackgroundImage + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top center",
      backgroundSize: backgroundPosition,
      backgroundBlendMode: "normal",
      width: "100%",
      height: 978,
      top: 0,
      left: "50%",
      right: 0,
      transform: "translateX(-50%)",
    };
    setOutsideBackgroundStyle(backgroundStyles);

    return () => {
      setOutsideBackgroundStyle({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize])

  const tiers = [
    { name: "micro", credits: 75, events: 400000, price: 9, additional: 0.13, trial: true },
    { name: "small", credits: 900, events: 4000000, price: 99, additional: 0.12, trial: true },
    { name: "medium", credits: 2990, events: 40000000, price: 299, additional: 0.11, trial: false },
    { name: "large", credits: 6655, events: 500000000, price: 599, additional: "0.10", trial: false },
  ];

  const features_en = [
    "400+ API Connectors",
    "BigQuery Data Warehouse in EU",
    "AI-powered data analyst Copilot",
    "Google Analytics 4 data hosting",
    "Nordic Server-side GTM",
    "GDPR monitoring of your website",
    "Easy integration with Looker Studio",
    "Email support"
  ];

  const features_sv = [
    "400+ API-anslutningar",
    "BigQuery Data Warehouse i EU",
    "AI-drivna dataanalytiker Copilot",
    "Google Analytics 4 data hosting",
    "Nordisk server-side GTM",
    "GDPR-övervakning av din webbplats",
    "Enkel integrering med Looker Studio",
    "E-postsupport"
  ];

  const features = lang === "sv" ? features_sv : features_en;

  const meta_en = {
    starts_at: "Starts at",
    per_month: <>per<br /> month</>,
    vat_excl: "Prices excl VAT",
    includes: "Includes",
    cogny_credits: <>Cogny<br /> credits</>,
    extra_cost: "Additional credits cost",
    per_credit: <>per<br /> credit</>,
  }

  const meta_sv = {
    starts_at: "Börjar vid",
    per_month: <>per<br /> månad</>,
    vat_excl: "Priser exkl moms",
    includes: "Inkluderar",
    cogny_credits: <>Cogny<br /> credits</>,
    extra_cost: "Extra kostnad för credits",
    per_credit: <>per<br /> credit</>,
  }

  const meta = lang === "sv" ? meta_sv : meta_en;

  const generateSections = (tier) => [
    {
      type: "header"
    },
    {
      type: "price",
      title: meta.starts_at,
      value: "€" + tier.price,
      unit: meta.per_month,
      main: true
    },
    {
      type: "credits",
      title: meta.includes,
      value: tier.credits,
      unit: meta.cogny_credits
    },
    {
      type: "additional",
      title: meta.extra_cost,
      value: "€" + tier.additional,
      unit: meta.per_credit
    },
    {
      type: "CTA",
      terms: meta.vat_excl,
    }
  ];

  return (
    <div className="Pricing">
      <PricingTiers
        lang={lang}
        tiers={tiers}
        title={
          lang === "sv" ?
            "Priser"
            :
            "Pricing"
        }
        intro={
          lang === "sv" ?
            <>
              I Cogny använder du credits för att få tillgång till funktioner, med kostnader baserade på datavolym och användning.<br />
              Välj mellan fyra olika paket för att hitta det som passar dig bäst.
            </>
            :
            <>
              In Cogny, using features consumes credits, with costs based on data volume and usage.<br />
              Choose from four different sizes to find the best fit for you.
            </>
        }
        features={{
          data: features,
          title: lang === "sv" ? "Alla paket innehåller" : "All tiers include",
        }}
        generateSections={generateSections}
      />

      <FAQ
        lang={lang}
        title={lang === "sv" ? "Vanliga frågor" : "How does it work?"}
        faq={faqData.pricing.en} />
    </div>
  );
}

export default Pricing;
