import './style.scss';
import { NavLink } from 'react-router-dom';

function MenuItem(props) {
  return (
    <NavLink
      className={({ isActive }) => "MenuItem " + props.className + (isActive ? " MenuItem--active" : "")}
      to={props.href}
      onClick={props.onClick}
    >
      {props.label || props.children}
    </NavLink>
  );
}

export default MenuItem;