import OutsideBox from 'outside/components/OutsideBox';
import './style.scss';

function Terms() {
  return (
    <OutsideBox classBase="Terms">
      <div className="Terms">

	  <h1>Server-Side Hosting Security Policy</h1>

<h2>1. Introduction</h2>
<p>This Security Policy outlines the measures and protocols implemented by Cogny.com to ensure the security and protection of data sent to our server-side infrastructure. It is designed to establish a comprehensive framework for safeguarding sensitive information and maintaining the integrity of our systems.</p>

<h2>2. Access Control</h2>

<h3>2.1 Authentication:</h3>
<p>Access to server resources is restricted to authorized personnel only.<br />
Multi-factor authentication is enforced for all privileged accounts.</p>

<h3>2.2 Authorization:</h3>
<p>Role-based access controls are in place to limit access to specific resources based on job responsibilities. Access permissions are reviewed and updated regularly.</p>

<h2>3. Physical Security</h2>

<h3>3.1 Data Center Security:</h3>
<p>Server facilities are equipped with robust physical security measures, including access controls, CCTV surveillance, and intrusion detection systems.<br />
Access to data centers is restricted to authorized personnel and closely monitored. <a href="https://www.hetzner.com/assets/Uploads/downloads/Sicherheit-en.pdf">Link to Hetzners Security Brochure</a>.</p>

<h3>3.2 Redundancy and Disaster Recovery:</h3>
<p>Redundant power supplies, cooling systems, and network connections are maintained to ensure uninterrupted service.<br />
Regular data backups are performed, and disaster recovery plans are tested periodically.</p>

<h2>4. Network Security</h2>

<h3>4.1 Firewalls and Intrusion Detection:</h3>
<p>Firewalls are configured to filter incoming and outgoing traffic, and intrusion detection systems monitor for suspicious activity.<br />
Network traffic is regularly analyzed for anomalies and potential security breaches.</p>

<h3>4.2 Encryption:</h3>
<p>Data in transit is encrypted using industry-standard protocols (TLS/SSL) to prevent unauthorized interception or tampering.</p>

<h2>5. Software Security</h2>

<h3>5.1 Patch Management:</h3>
<p>Critical security patches and updates are applied promptly to mitigate known vulnerabilities.</p>

<h3>5.2 Application Security:</h3>
<p>Secure coding practices are followed to prevent common application-level vulnerabilities.<br />
Regular security assessments, including penetration testing, are conducted to identify and address potential weaknesses.</p>

<h2>6. Data Security</h2>

<h3>6.1 Data Classification:</h3>
<p>Data is classified based on sensitivity, and appropriate security controls are applied accordingly.</p>

<h3>6.2 Access Monitoring:</h3>
<p>Access logs are generated and monitored for unusual or unauthorized activities.</p>

<h2>7. Incident Response and Monitoring</h2>

<h3>7.1 Incident Response Plan:</h3>
<p>A comprehensive incident response plan is in place to guide the identification, containment, eradication, recovery, and lessons learned from security incidents.</p>

<h3>7.2 Security Monitoring:</h3>
<p>Real-time monitoring systems are employed to detect and respond to security events promptly.</p>

<h2>8. Vendor Management</h2>

<h3>8.1 Third-Party Security:</h3>
<p>Vendors and subcontractors are selected based on their commitment to security best practices, and their compliance is monitored regularly.</p>

<h3>8.2 Vendor Certification:</h3>
<p>Hetzner Certification<br />
Certified in accordance with DIN ISO/IEC 27001, Internationally recognized standard for information security, Certification for all of Hetzner data center parks and entire infrastructure. <a href="https://www.hetzner.com/unternehmen/zertifizierung">Read more</a></p>

<h2>9. Employee Training and Awareness</h2>

<h3>9.1 Security Training:</h3>
<p>All employees receive regular security awareness training to stay informed about current threats and best practices.</p>

<h3>9.2 Reporting Security Incidents:</h3>
<p>Employees are encouraged to report any suspicious activities or potential security incidents promptly.</p>

<h2>10. Compliance and Audit</h2>

<h3>10.1 Regulatory Compliance:</h3>
<p>We maintain compliance with applicable data protection and privacy regulations.</p>

<h3>10.2 Regular Audits:</h3>
<p>Security controls and procedures are audited regularly to ensure ongoing effectiveness.</p>
<p>This Security Policy is a living document and will be updated as necessary to reflect changes in technology and security practices. It serves as a foundation for maintaining the confidentiality, integrity, and availability of hosted data on Cogny.com servers.</p>

      </div>
    </OutsideBox>
  );
}

export default Terms;
