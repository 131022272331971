const post = {
  title: "Most common formulas for rev ops KPIs",
  slug: "most-common-formulas-for-rev-ops-kpis",
  date: "2023-10-18",
  author: "Tom Ström, Revenue Operations Specialist",
  description: "These formulas, some tailored for the B2C environment and some for B2B environment, ensure businesses can accurately measure their performance, refine their strategies, and ultimately drive better customer experiences and revenue growth.",
  image: "post10.webp",
  body: `
## 1. Customer Acquisition Cost (CAC)

![CAC](/static/CAC.svg)

Where:
Total Cost of Sales and Marketing includes salaries, overheads, marketing campaigns, advertisements, etc., over a specific period.
Number of New Customers Acquired is the count of new clients gained over the same period.

## 2. Customer Lifetime Value(CLTV)

![CLTV](/static/CLTV.svg)

Where:

* Average Revenue per Account(ARPA) is the average revenue generated from a customer each month.
* Gross Margin is the percentage profit earned from the customer after deducting servicing costs.
* Average Customer Lifespan is the average number of months a customer stays with the company.

## 3. Sales Cycle Length

This isn't a formulaic KPI but a measurement. It's calculated by taking the average number of days it takes to close a deal, from the initial contact to the final sale.

## 4. Lead Conversion Rate

![LCR](/static/LCR.svg)

## 5. Account Expansion Rate

![AER](/static/AER.svg)

## 6. Net Promoter Score (NPS)

![NPS](/static/NPS.svg)

Where:

* Promoters are customers who give a score of 9 or 10(on a scale of 0 to 10) when asked about their likelihood to recommend the company.
* Detractors are those who give a score of 0 to 6.

## 7. Churn Rate

![CR](/static/CR.svg)

## 8. Proposal-to-Close Ratio

![PCR](/static/PCR.svg)

## 9. Average Order Value (AOV)

![AOV](/static/AOV.svg)

## 10. Purchase Frequency (PF)

![PF](/static/PF.svg)

## 11. Cart Abandonment Rate (CAR)

![CAR](/static/CAR.svg)

## 12. Customer Retention Rate (CRR)

![CRR](/static/CRR.svg)

## 13. Return on Advertising Spend (ROAS)

![ROAS](/static/ROAS.svg)
`
};

export default post;
