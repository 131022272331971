import React from "react";
import { PageSidebarProvider } from "hooks/usePageSidebar";
import { Helmet } from 'react-helmet-async';
import Main from "components/templates/Main";

const getTitle = (name) => {
  return name + " - Cogny";
}

const Provider = (props) => {
  const { children, scope } = props;
  if (scope) {
    return (
      <PageSidebarProvider
        scope={scope}
      >
        {children}
      </PageSidebarProvider>
    );
  } else {
    return children;
  }
}

const Page = (props) => {
  const { title, children, sidebar, checkAccess, size, isError } = props;

  let scope = null;
  let hasPageSidebar = false;
  let pageSidebar = null;
  let fullScreen = false;

  if (typeof props.pageSidebar === "string") {
    scope = props.pageSidebar;
    hasPageSidebar = true;
    fullScreen = true;
  } else if (React.isValidElement(props.pageSidebar)) {
    pageSidebar = props.pageSidebar;
  }

  let noSidebar;
  if (sidebar === false) {
    noSidebar = true;
  }

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      <Provider
        scope={scope}
      >
        <Main
          noSidebar={noSidebar}
          pageSidebar={pageSidebar}
          checkAccess={checkAccess}
          size={size}
          hasPageSidebar={hasPageSidebar}
          fullScreen={fullScreen}
          isError={isError}
        >
          {children}
        </Main>
      </Provider>
    </>
  );
}

export default Page;