import './style.scss';
import OutsideBox from 'outside/components/OutsideBox';
import YouTube from 'react-youtube';
import ReactMarkdown from 'react-markdown'
import Button from 'components/atoms/Button';

function MediaBox(props) {
  const { titleLevel = 2 } = props;
  const hasMediaClass = !props.video && !props.image ? " MediaBox--nomedia" : "";
  const mediaPositionClass = props.mediaFirst ? " MediaBox--mediafirst" : "";

  const TitleTag = `h${titleLevel}`;

  return (
    <OutsideBox classBase="MediaBox">
      <div
        className={"MediaBox" + hasMediaClass + mediaPositionClass}
      >
        {props.mediaFirst && props.image && (
          <img
            className="MediaBox__image"
            srcSet={props.srcSet}
            src={props.image}
            alt={props.image}
          />
        )}

        {props.mediaFirst && props.video && (
          <YouTube videoId={props.video} className="MediaBox__video" />
        )}

        {props.title && (
          <TitleTag className="MediaBox__title">{props.title}</TitleTag>
        )}

        {props.text && (
          <div className="MediaBox__text">{props.text}</div>
        )}

        {!props.mediaFirst && props.image && (
          <img
            className="MediaBox__image"
            srcSet={props.srcSet}
            src={props.image}
            alt={props.image}
          />
        )}

        {!props.mediaFirst && props.video && (
          <YouTube videoId={props.video} className="MediaBox__video" />
        )}

        {props.markdown && (
          <ReactMarkdown className="MediaBox__moretext" children={props.markdown} />
        )}

        {props.link && props.linkText && (
          <Button type="link" href={props.link} className="MediaBox__link" icon="arrowRight" iconSide="right" onClick={props.linkOnClick}>{props.linkText}</Button>
        )}

      </div>
    </OutsideBox>
  );
}

export default MediaBox;