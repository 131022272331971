import './style.scss';
import SubpageHero from 'outside/components/SubpageHero';
import MediaBox from 'outside/components/MediaBox';
import OutsideCTA from 'outside/components/OutsideCTA';

function GA4() {

  const apiHost = process.env.REACT_APP_API_BASE_URL; 

  return (
    <div className="GA4">
      <SubpageHero
        title="Your Google Analytics 4 data in BigQuery"
        list={[
          "Get started less than 10 minutes",
          "European Server-side tagging",
          "Import other data sources",
          "Use your data in Looker studio"
        ]}
        tagline={"Using Cogny's data management platform that anyone can run."}
        text={<>
          <p>We at Cogny have built a simple solution to help our customers get started with storing their Google Analytics 4 data in Google's BigQuery.</p>

          <p>Cogny also supports connecting other data sources to your BigQuery, so you can build reports in Looker Studio using data from many sources.</p>

          <p>When you are happy with your dashboards, we'll help you reach the next level with Media Mix Modelling, Attribution and by prediction your Life Time Value (LTV).</p>
        </>}
        button={<OutsideCTA label="Get started" href={apiHost+"/api/v1/accounts/login"} />}
      />

      <MediaBox 
        title="Get started with BigQuery in just a few clicks" 
        text="Using Cogny's data management platform that anyone can run." 
        video="1gPiPCnfPak"
      />

      <MediaBox 
        title="Use your data in Looker Studio" 
        text="Using Cogny's data management platform that anyone can run." 
        video="Lq0cT2u2rW8"
      />

      <MediaBox 
        title="Connect your Google Universal Analytics property" 
        text="This way you can build reports comparing with historic data from before you implemented Google Analytics 4, give that you had Google Universal Analytics installed." 
        video="UyIg5QAMxBQ"
      />
    </div>
  );
}

export default GA4;
