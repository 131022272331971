import './style.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import Tags from 'components/molecules/Tags';
import { useAppData } from 'hooks/useAppData';

function ImpactCard(props) {
  const { task } = props;
  const { getPath } = useAppData();

  var tags = [
    {
      "gid": "status"
      , "name": props.task.status
    }
  ];

  return (
    <div className="ImpactCard" key={task.gid}>
      <div className="ImpactCard__main">
        <Link className="ImpactCard__link" to={getPath("impact", task.id)}>
          <h3 className="ImpactCard__title">{task.name}</h3>
        </Link>
        <Tags tags={tags} />
      </div>
      <div className="ImpactCard__meta">
        <p>{task.summary}</p>
      </div>
    </div>
  );
}

export default ImpactCard;
