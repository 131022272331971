import './style.scss';
//import logo from './cogny-logo.svg';
import { ReactComponent as LogoSvg } from './cogny-logo.svg';

function Logo(props) {
  const { className = "" } = props;
  return (
    <div className={"Logo " + className}>
      <LogoSvg className="Logo__image" alt={props.alt} />
    </div>
  );
}

export default Logo;
