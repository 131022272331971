import './style.scss';

function SSGTM(props) {
  return (
    <div className="SSGTM">
      {props.children}
    </div>
  );
}

export default SSGTM;
