import "./style.scss";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import CognyAPI from "components/_classes/CognyAPI";
import Icon from "components/atoms/Icon";
import Code from "components/molecules/Code";
import Button from "components/atoms/Button";
import LoadingDots from "components/atoms/LoadingDots";
import ModalDialog from "components/molecules/ModalDialog";

const ToolMessageTopMeta = (props) => {
  const { items } = props;
  return (
    <div className="ToolMessage__top-meta">
      <ul>
        {items.map(({ title, data }, index) => (
          data && (
            <li key={index}>
              {title}: <code>{data}</code>
            </li>
          )
        ))}
      </ul>
    </div>
  );
};

const jsonReparser = (input) => {
  let current = input;
  while (typeof current === 'string') {
    try {
      current = JSON.parse(current);
    } catch (e) {
      return '';
    }
  }
  return current;
};


const ImageFromApi = (props) => {
  const [modalState, setModalState] = useState({
    open: false,
    sent: false,
    primary: {
      label: "",
      onClick: () => { }
    },
  });

  const updateModalState = (newState) => {
    setModalState(currentState => ({ ...currentState, ...newState }));
  }

  const { path, alt } = props;
  const [cookies] = useCookies(["token"]);
  const api = new CognyAPI(cookies.token);
  const apiHost = api.getApiHost();
  const authToken = api.getToken();

  const params = useParams();
  const warehouseId = params.warehouse_id;

  const getPath = (pathSegments) => {
    if (Array.isArray(pathSegments)) {
      const combinedPath = pathSegments.join("/").replace(/^\/|\/$/g, "").replace(/\/+/g, "/");
      return combinedPath;
    }
    return "";
  }

  const src = apiHost + "/api/v1/copilot/" + warehouseId + "/" + getPath(path) + "?auth=" + authToken;

  return (
    <ModalDialog
      button={
        <>
          <img
            className="ToolMessage__thumbnail"
            src={src}
            alt={alt}
          />
          <div className="ToolMessage__thumbnail-overlay">
            <Icon icon="expand" />
            <span>Open image</span>
          </div>
        </>
      }
      icon="expand"
      iconSide="left"
      className="ToolMessage__imageToggle"
      size="max"
      modalTitle={alt}
      state={{ modalState, updateModalState }}
    >
      <img
        width="100%"
        className="ToolMessage__image"
        src={src}
        alt={alt}
      />
    </ModalDialog>
  );
};

function ShowFunction(props) {
  const func = props?.function;
  const options = props?.options;
  const id = props?.id;

  const { name, arguments: args, output } = func;

  const funcs = {
    run_bigquery_sql_query: RunBigQuerySqlQuery,
    list_tables: ListTables,
    list_datasets: ListDatasets,
    inspect_schema: InspectSchema,
    run_mmm_model: RunMmmModel,
    run_prophet_forecast: RunProphetForecast,
    run_impact_analysis: RunImpactAnalysis,
  }

  if (typeof funcs[name] !== "undefined") {
    const FuncComponent = funcs[name];
    return (
      <FuncComponent
        id={id}
        args={args}
        output={output}
        options={options}
      />
    );
  }

  return (
    <ToolMessageToggle
      title={"Running function " + name}
      top={
        <Code language="json">{args}</Code>
      }
      options={options}
    >
      <Code language="json">{output}</Code>
    </ToolMessageToggle>
  );
}

function RunImpactAnalysis(props) {
  const { options, id, args, output } = props;
  const setWarning = options?.setWarning || (() => { });

  const [parsedArgs, setParsedArgs] = useState({});
  const [argsError, setArgsError] = useState(null);

  const [parsedOutput, setParsedOutput] = useState({});
  const [outputError, setOutputError] = useState(null);

  useEffect(() => {
    if (args !== null && args.length > 0) {
      try {
        const parsedJson = JSON.parse(args);
        setParsedArgs(parsedJson);
      } catch (e) {
        setArgsError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args]);

  useEffect(() => {
    if (output !== null && output.length > 0) {
      try {
        const parsedJson = JSON.parse(output);
        if (parsedJson["Error"]) {
          setOutputError(parsedJson["Error"]);
        } else {
          setParsedOutput(parsedJson);
        }
      } catch (e) {
        setOutputError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [output]);

  useEffect(() => {
    if (outputError) {
      setWarning(outputError);
    } else if (argsError) {
      setWarning(argsError);
    } else {
      setWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputError, argsError]);

  // {"channel_names":"facebook,tiktok","media_interaction_postfix":"_total_impressions","media_spend_postfix":"_total_spend","query":"SELECT month, facebook_total_impressions, facebook_total_spend, tiktok_total_impressions, tiktok_total_spend, total_sales FROM `cogny-elexir-pharma.cogny_xforms.marketing_2023`","target_name":"total_sales"}

  return (
    <>
      <ToolMessageToggle
        title="Doing Impact Analysis"
        options={options}
        top={
          <>
            <ToolMessageTopMeta
              items={[
                {
                  title: "Metrics",
                  data: parsedArgs?.metrics
                },
                {
                  title: "Date name",
                  data: parsedArgs?.date_name
                },
                {
                  title: "Start date",
                  data: parsedArgs?.start_date
                },
                {
                  title: "Change date",
                  data: parsedArgs?.change_date
                },
                {
                  title: "Stop date",
                  data: parsedArgs?.stop_date
                },
              ]}
            />
            {parsedArgs?.query &&
              <Code language="sql">{parsedArgs.query}</Code>
            }
          </>
        }
      >
        {typeof output === "object" &&
          Object.entries(parsedOutput).map((section, index) => (
            <div key={index}>
              <h4>{section[0]}</h4>
              <ImageFromApi
                path={["asset", id, section[1].plot]}
                alt={section[1].plot}
              />
              <Code language="json">{section[1].summary}</Code>
            </div>
          ))
        }
      </ToolMessageToggle>
    </>
  );
}

function RunMmmModel(props) {
  const { options, args, output } = props;
  const setWarning = options?.setWarning || (() => { });

  const [parsedArgs, setParsedArgs] = useState({});
  const [argsError, setArgsError] = useState(null);

  const [parsedOutput, setParsedOutput] = useState([]);
  const [outputError, setOutputError] = useState(null);

  useEffect(() => {
    if (args !== null && args.length > 0) {
      try {
        const parsedJson = JSON.parse(args);
        setParsedArgs(parsedJson);
      } catch (e) {
        setArgsError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args]);

  useEffect(() => {
    if (output !== null && output.length > 0) {
      try {
        const parsedJson = jsonReparser(output);
        if (parsedJson["Error"]) {
          setOutputError(parsedJson["Error"]);
        } else {
          setParsedOutput(parsedJson);
        }
      } catch (e) {
        setOutputError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [output]);

  useEffect(() => {
    if (outputError) {
      setWarning(outputError);
    } else if (argsError) {
      setWarning(argsError);
    } else {
      setWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputError, argsError]);

  // {"channel_names":"facebook,tiktok","media_interaction_postfix":"_total_impressions","media_spend_postfix":"_total_spend","query":"SELECT month, facebook_total_impressions, facebook_total_spend, tiktok_total_impressions, tiktok_total_spend, total_sales FROM `cogny-elexir-pharma.cogny_xforms.marketing_2023`","target_name":"total_sales"}

  var file_order = [
    { "name": "media_baseline_contribution_area_plot.png", "title": "Attribution over time", "description": "This plot shows the break-down of ad channel impact over time as attributed by the mmm.", "visible": true },
    { "name": "response_curves.png", "title": "Response curves", "description": "This plot shows the response curves for each channel, i.e. how much each channel can deliver as estimated by the model.", "visible": true },
    { "name": "pre_post_budget_allocation_comparison.png", "title": "Pre/post budget allocation comparison", "description": "This plot shows the comparison of budget allocation before and after the mmm has done optimzations.", "visible": true },
    { "name": "model_fit.png", "title": "Model fit", "description": "This plot shows the model fit vs actual outcome of the target KPI over time for the traning data.", "visible": false },
    { "name": "out_of_sample_model_fit.png", "title": "Out of sample model fit", "description": "This plot shows the model fit vs actual outcome of the target KPI over time for the test data.", "visible": false },
    { "name": "media_contribution_percentage.png", "title": "Media contribution percentage", "description": "This plot shows the percentage of contribution of each channel.", "visible": false },
    { "name": "roi_hat.png", "title": "ROI hat", "description": "This plot shows the estimated ROI of each channel.", "visible": false },
    { "name": "media_channel_posteriors.png", "title": "Media channel posteriors", "description": "This plot shows the posteriors of each channel.", "visible": false },
    { "name": "prior_and_posterior.png", "title": "Prior and posterior", "description": "This plot shows the prior and posterior of the model.", "visible": false },
  ];

  const result = parsedOutput?.result ? jsonReparser(parsedOutput.result) : parsedOutput;

  const outputData = {
    files: result?.files || parsedOutput?.files || [],
    job_id: parsedOutput?.job_id || "",
    summary: result?.summary || parsedOutput?.summary || "",
    correlations: result?.correlations || parsedOutput?.correlations || "",
    variances: result?.variances || parsedOutput?.variances || "",
    spend_fractions: result?.spend_fractions || parsedOutput?.spend_fractions || "",
    variance_inflation_factors: result?.variance_inflation_factors || parsedOutput?.variance_inflation_factors || "",
  }

  return (
    <>
      <ToolMessageToggle
        title="Running a MMM model"
        options={options}
        top={
          <>
            <ToolMessageTopMeta
              items={[
                {
                  title: "Channels",
                  data: parsedArgs?.channel_names
                },
                {
                  title: "Media interaction postfix",
                  data: parsedArgs?.media_interaction_postfix
                },
                {
                  title: "Media spend postfix",
                  data: parsedArgs?.media_spend_postfix
                },
                {
                  title: "Target name",
                  data: parsedArgs?.target_name
                },
              ]}
            />
            {parsedArgs?.query &&
              <Code language="sql">{parsedArgs.query}</Code>
            }
          </>
        }
      >
        {outputData.job_id && outputData.files.length &&
          file_order.map((file, index) => (
            <div key={index}>
              <h4>{file.title}</h4>
              <div className="ToolMessage__file-description">
                {file.description}
              </div>
              <ImageFromApi
                path={["asset", outputData.job_id, file.name]}
                alt={file.name}
              />
            </div>
          ))
        }

        {outputData.summary &&
          <div>
            <h4>Summary</h4>
            <Code language="json">{outputData.summary}</Code>
          </div>
        }

        {outputData.correlations &&
          <div>
            <h4>Correlations</h4>
            <Code language="json">{outputData.correlations}</Code>
          </div>
        }

        {outputData.variances &&
          <div>
            <h4>Variances</h4>
            <Code language="json">{outputData.variances}</Code>
          </div>
        }

        {outputData.spend_fractions &&
          <div>
            <h4>Spend fractions</h4>
            <Code language="json">{outputData.spend_fractions}</Code>
          </div>
        }

        {outputData.variance_inflation_factors &&
          <div>
            <h4>Variance inflation factors</h4>
            <Code language="json">{outputData.variance_inflation_factors}</Code>
          </div>
        }
      </ToolMessageToggle>
    </>
  );
}


function RunProphetForecast(props) {
  const { options, id, args, output } = props;
  const setWarning = options?.setWarning || (() => { });

  const [parsedArgs, setParsedArgs] = useState({});
  const [argsError, setArgsError] = useState(null);

  const [parsedOutput, setParsedOutput] = useState("");
  const [outputError, setOutputError] = useState(null);

  useEffect(() => {
    if (args !== null && args.length > 0) {
      try {
        const parsedJson = JSON.parse(args);
        setParsedArgs(parsedJson);
      } catch (e) {
        setArgsError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args]);

  useEffect(() => {
    if (output !== null && output.length > 0) {
      try {
        const parsedJson = JSON.parse(output);
        if (parsedJson["Error"]) {
          setOutputError(parsedJson["Error"]);
        } else {
          setParsedOutput(parsedJson);
        }
      } catch (e) {
        setOutputError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [output]);

  useEffect(() => {
    if (outputError) {
      setWarning(outputError);
    } else if (argsError) {
      setWarning(argsError);
    } else {
      setWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputError, argsError]);

  var file_order = [
    { "name": "forecast.png", "title": "Forecast", "description": "This plot shows forecast plotter over time with confidence intervals.", "visible": true },
    { "name": "forecast_components.png", "title": "Forecast components", "description": "This plot shows the different components in the forecast, e.g. the over all trend and monthly, daily components.", "visible": true },
  ];

  return (
    <>
      <ToolMessageToggle
        title="Running a Facebook prophet forecast model"
        options={options}
        top={
          <>
            <ToolMessageTopMeta
              items={[
                {
                  title: "Date column",
                  data: parsedArgs?.ds_name
                },
                {
                  title: "Target column",
                  data: parsedArgs?.y_name
                },
                {
                  title: "Steps",
                  data: parsedArgs?.forecast_steps
                },
              ]}
            />
            {parsedArgs?.query &&
              <Code language="sql">{parsedArgs.query}</Code>
            }
          </>
        }
      >
        {parsedOutput !== "" &&
          <>
            {file_order.map((file, index) => (
              <div key={index}>
                <h4>{file.title}</h4>

                <div className="ToolMessage__file-description">
                  {file.description}
                </div>

                <ImageFromApi
                  path={["asset", id, file.name]}
                  alt={file.name}
                />
              </div>
            ))}

            <Code>{output}</Code>
          </>
        }
      </ToolMessageToggle>
    </>
  );
}

function ListDatasets(props) {
  const { options, output } = props;
  const setWarning = options?.setWarning || (() => { });

  const [parsedOutput, setParsedOutput] = useState([]);
  const [outputError, setOutputError] = useState(null);

  useEffect(() => {
    if (output !== null && output.length > 0) {
      try {
        const parsedJson = JSON.parse(output);
        setParsedOutput(parsedJson);
      } catch (e) {
        setOutputError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [output]);

  useEffect(() => {
    if (outputError) {
      setWarning(output.error);
    } else {
      setWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputError]);

  return (
    <ToolMessageToggle
      title="Exploring datasets"
      options={props.options}
    >
      {
        Array.isArray(parsedOutput) &&
        <table className="ToolMessage__table">
          <thead>
            <tr>
              <th>Dataset ID</th>
              <th>Description</th>
              <th>Type</th>
              <th>Status</th>
              <th>Provider</th>
              <th>Last synced</th>
            </tr>
          </thead>
          <tbody>
            {parsedOutput.slice(0, 50).map((dataset, index) => (
              <tr key={index}>
                {
                  typeof dataset === "string" ?
                    <td colSpan="6">{dataset}</td>
                    :
                    <>
                      <td>{dataset.dataset_id}</td>
                      <td>{dataset.description}</td>
                      <td>{dataset.type}</td>
                      <td>{dataset.status}</td>
                      <td>{dataset.provider}</td>
                      <td>{dataset.fivetran_succeeded_at}</td>
                    </>
                }
              </tr>
            ))}
          </tbody>
        </table>
      }
    </ToolMessageToggle>
  );
}


function ListTables(props) {
  const { options, args, output } = props;
  const setWarning = options?.setWarning || (() => { });

  const [parsedArgs, setParsedArgs] = useState({});
  const [argsError, setArgsError] = useState(null);
  const [parsedOutput, setParsedOutput] = useState([]);
  const [outputError, setOutputError] = useState(null);

  useEffect(() => {
    if (args !== null && args.length > 0) {
      try {
        const parsedJson = JSON.parse(args);
        setParsedArgs(parsedJson);
      } catch (e) {
        setArgsError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args]);

  useEffect(() => {
    if (output !== null && output.length > 0) {
      try {
        const parsedJson = JSON.parse(output);
        setParsedOutput(parsedJson);
      } catch (e) {
        setOutputError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [output]);

  useEffect(() => {
    if (outputError) {
      setWarning(output.error);
    } else if (argsError) {
      setWarning(argsError);
    } else {
      setWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputError, argsError]);

  return (
    <ToolMessageToggle
      title={"Exploring tables in dataset " + (parsedArgs?.dataset_id ?? parsedArgs?.dataset)}
      options={options}
    >
      {Array.isArray(parsedOutput) &&
        <table className="ToolMessage__table">
          <thead>
            <tr>
              <th>Table name</th>
              <th>Status</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {parsedOutput.slice(0, 50).map((table, index) => (
              <tr key={index}>
                {
                  typeof table === "string" ?
                    <td colSpan="3">{table}</td>
                    :
                    <>
                      <td>{table.table_name}</td>
                      <td>{table.status}</td>
                      <td>{table?.created?.substr(0, 19)}</td>
                    </>
                }
              </tr>
            ))}
          </tbody>
          <tfoot>
            {parsedOutput.length > 50 && <tr><td>... and more</td></tr>}
          </tfoot>
        </table>
      }
    </ToolMessageToggle>
  );
}

function InspectSchema(props) {
  const { options, args, output } = props;
  const setWarning = options?.setWarning || (() => { });

  const [parsedArgs, setParsedArgs] = useState({});
  const [argsError, setArgsError] = useState(null);
  const [parsedOutput, setParsedOutput] = useState({});
  const [outputError, setOutputError] = useState(null);

  useEffect(() => {
    if (args !== null && args.length > 0) {
      try {
        const parsedJson = JSON.parse(args);
        setParsedArgs(parsedJson);
      } catch (e) {
        setArgsError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args]);

  useEffect(() => {
    if (output !== null && output.length > 0) {
      try {
        const parsedJson = JSON.parse(output);
        setParsedOutput(parsedJson);
      } catch (e) {
        setOutputError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [output]);

  useEffect(() => {
    if (outputError) {
      setWarning(output.error);
    } else if (argsError) {
      setWarning(argsError);
    } else {
      setWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputError, argsError]);

  return (
    <ToolMessageToggle
      title={"Inspecting schema of table " + parsedArgs?.dataset_id + "." + parsedArgs?.table_name}
      options={props.options}
    >
      {parsedOutput?.schema?.length > 0 &&
        <table className="ToolMessage__table">
          <thead>
            <tr>
              <th>Column name</th>
              <th>Data type</th>
            </tr>
          </thead>
          <tbody>
            {parsedOutput?.schema?.map((column, index) => (
              <tr key={index}>
                <td>{column.Name}</td>
                <td>{column.Type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      }
    </ToolMessageToggle>
  );
}


function CodeInterpreter(props) {
  var data = props.data;

  return (
    <ToolMessageToggle
      title="Code interpreter"
      top={
        <Code language="python">{data.input}</Code>
      }
      options={props.options}
    >
      {data.outputs?.map((output, index) =>
        <>
          {output.type === "image" &&
            <ImageFromApi
              key={index}
              path={["file", output.image.file_id]}
              alt=""
            />
          }
          {output.type === "logs" &&
            <Code language="json" key={index}>{output.logs}</Code>
          }
          {output.type !== "logs" && output.type !== "image" &&
            <Code language="json" key={index}>{JSON.stringify(output)}</Code>
          }

        </>
      )}
    </ToolMessageToggle>
  );
}

function RunBigQuerySqlQuery(props) {
  const { options, args, output } = props;
  const setWarning = options?.setWarning || (() => { });

  const [parsedArgs, setParsedArgs] = useState({});
  const [argsError, setArgsError] = useState(null);

  const [parsedOutput, setParsedOutput] = useState([]);
  const [outputError, setOutputError] = useState(null);

  useEffect(() => {
    if (args !== null && args.length > 0) {
      try {
        const parsedJson = JSON.parse(args);
        setParsedArgs(parsedJson);
      } catch (e) {
        setArgsError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args]);

  useEffect(() => {
    if (output !== null && output.length > 0) {
      try {
        const parsedJson = JSON.parse(output);
        if (parsedJson["Error"]) {
          setOutputError(parsedJson["Error"]);
        } else {
          setParsedOutput(parsedJson);
        }
      } catch (e) {
        setOutputError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [output]);

  useEffect(() => {
    if (outputError) {
      setWarning(output.error);
    } else if (argsError) {
      setWarning(argsError);
    } else {
      setWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputError, argsError]);

  const queryLink = (project) => {
    let fullLink = "https://lookerstudio.google.com/u/0/reporting/create?c.mode=edit&c.source=BQ_UI&ds.type=CUSTOM_QUERY&ds.connector=BIG_QUERY&ds.sql=";
    fullLink += encodeURIComponent(parsedArgs.query);
    fullLink += "&ds.billingProjectId=" + project + "&ds.projectId=" + project + "ds.sqlType=STANDARD_SQL"
    return fullLink;
  };

  return (
    <>
      <ToolMessageToggle
        title="Running SQL query"
        top={
          <>
            {parsedArgs?.query &&
              <Code
                language="sql"
              >
                {parsedArgs?.query}
              </Code>
            }

            {parsedOutput?.project &&
              <Button
                className="ToolMessage__button"
                type="htmllink"
                href={queryLink(parsedOutput.project)}
                target="_blank"
                rel="noreferrer"
              >
                Open in Looker Studio
              </Button>
            }
          </>
        }
        options={options}
      >
        {
          parsedOutput?.columns?.length > 0 &&
          <table className="ToolMessage__table">
            <thead>
              <tr>
                {parsedOutput?.columns?.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {parsedOutput?.rows?.map((row, index) => (
                <tr key={index}>
                  {row?.map((cell, index) => (
                    <td key={index}>
                      {typeof cell === "string" ?
                        cell
                        :
                        JSON.stringify(cell)
                      }
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        }
      </ToolMessageToggle>
    </>
  );
}

function ToolMessageToggle(props) {
  const { title, top, children, options } = props;
  const status = options?.status;
  const waiting = status === "in_progress";
  if (!options) return <></>;

  const { open, setOpen } = options;

  const warning = typeof options.warning === "string" ? options?.warning : "";

  const shouldOpen = !waiting && open;

  return (
    <div className={
      "ToolMessage__instance"
      + (open ? " ToolMessage__instance--open" : "")
    }
    >
      <div
        className={
          "ToolMessage__top"
          + (open ? " ToolMessage__top--open" : " ToolMessage__top--closed")
          + (warning ? " ToolMessage__top--warning" : "")
          + (waiting ? " ToolMessage__top--waiting" : "")
          + (!children ? " ToolMessage__top--no-bottom" : "")
        }
      >
        <div
          className="ToolMessage__toggle"
          onClick={
            waiting ?
              null
              :
              () => setOpen(!open)
          }
        >
          {
            !waiting &&
            <Icon
              className="ToolMessage__icon"
              icon={open ? "chevronUp" : "chevronDown"}
            />
          }
          <h3 className="ToolMessage__title">
            {warning && <span className="ToolMessage__warning-label">ERROR!</span>}
            {title}
          </h3>

          {waiting && <LoadingDots />}
        </div>
        {open && top}
      </div>
      {
        shouldOpen && warning &&
        <div className="ToolMessage__bottom ToolMessage__bottom--warning">
          {warning}
        </div>
      }
      {
        shouldOpen && !warning && children &&
        <div className="ToolMessage__bottom">
          {children}
        </div>
      }
    </div>
  );
}

function ToolMessage(props) {
  const { message } = props;
  const { step_details } = message;
  const tool_calls = step_details?.tool_calls || [];

  const defaultOptions = { open: false, warning: false, status: null, type: null };
  const [toolStates, setToolStates] = useState(Array(64).fill(defaultOptions));

  useEffect(() => {
    if (message?.status === "expired") {
      setToolStates(toolStates.map(state => ({ ...state, warning: "Processing this step took too long" })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (message?.status) {
      setToolStates(toolStates.map(state => ({ ...state, status: message.status })));
    } else {
      setToolStates(toolStates.map(state => ({ ...state, status: null })));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  if (!message) return null;

  const setToolState = (index, newState) => {
    setToolStates(prevStates => prevStates.map((state, i) => i === index ? { ...state, ...newState } : state));
  };

  const createOptions = (index) => {
    const toolState = toolStates[index];
    if (!toolState) return null;

    return {
      open: toolState.open,
      setOpen: (open) => setToolState(index, { open }),
      warning: toolState.warning,
      setWarning: (warning) => setToolState(index, { warning }),
      status: toolState.status,
      setStatus: (status) => setToolState(index, { status }),
    };
  };

  function ToolMessageTitle(props) {
    const message = props.message;

    let title = "";

    if (message?.type === "retrieval") {
      title = "Retrieving data";
    } else if (message?.type === "code_interpreter") {
      title = "Running code";
    } else if (message?.type === "function") {
      title = "Running function";
    } else if (message?.type === "tool_calls" && message?.step_details?.tool_calls?.length === 0) {
      title = "Planning to use a tool";
    } else if (message?.type === "message_creation" && message?.status !== "completed") {
      title = "Preparing next step";
    }

    if (title.length) return <h3 className="ToolMessage__title">{title}</h3>;
    return <></>;
  }

  if (tool_calls && !tool_calls.length) {
    return (
      <div className="ToolMessage">
        <LoadingDots />
      </div>
    );
  }

  return (
    <div className="ToolMessage">
      <ToolMessageTitle message={message} />
      {tool_calls.map((call, index) => (
        <React.Fragment key={index}>
          {
            call.type === "code_interpreter" ?
              <CodeInterpreter
                data={call.code_interpreter}
                options={createOptions(index)}
              />
              :
              call.type === "function" ?
                <ShowFunction
                  id={call.id}
                  function={call.function}
                  options={createOptions(index)}
                />
                :
                <></>
          }
        </React.Fragment>
      ))}
    </div>
  );
}

export default ToolMessage;