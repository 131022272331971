import './style.scss';
import OutsideBox from 'outside/components/OutsideBox';

const PrivacyContent = () => (
  <div className="Privacy">
    <h1>Privacy Policy</h1>

    <p><strong>Effective Date:</strong> July, 2023</p>

    <p>Welcome to Cogny.com (the "Website"), operated by Cogny AB ("us," "we," or "our"). This Privacy Policy outlines
      how we collect, use, and protect your personal data when you use our services. By using the Website, you agree
      to the practices described in this Privacy Policy. Please take a moment to understand our policies and make
      informed decisions about your data.</p>

    <h2>Information Collection And Use</h2>

    <p>We collect different types of data to enhance and provide the best possible experience for you. This section
      clarifies the types of information we collect.</p>

    <h3>Personal Data</h3>

    <p>When you use our Website, we may request certain personally identifiable information ("Personal Data"),
      including but not limited to:</p>

    <ul>
      <li>Email address</li>
      <li>First name and last name</li>
    </ul>

    <h3>Usage Data</h3>

    <p>We also collect Usage Data that helps us understand how the Website is accessed and used. Usage Data may
      include:</p>

    <ul>
      <li>Internet Protocol (IP) address</li>
      <li>Browser type and version</li>
      <li>Pages visited on our Website</li>
      <li>Date and time of your visit</li>
      <li>Time spent on pages</li>
      <li>Device identifiers</li>
    </ul>

    <h3>Tracking & Cookies Data</h3>

    <p>We use cookies and similar tracking technologies to improve your experience on the Website. These technologies
      gather information to enhance and analyze our services. Examples of cookies we use include:</p>

    <ul>
      <li>Session Cookies for operational purposes</li>
      <li>Preference Cookies to remember your settings</li>
      <li>Security Cookies for added protection</li>
    </ul>

    <p>You have the option to refuse cookies through your browser settings, but note that this may affect certain
      features of our service.</p>

    <h2>Use of Data</h2>

    <p>We use collected data for various purposes:</p>

    <ul>
      <li>To maintain and provide our services</li>
      <li>To inform you about service changes</li>
      <li>To engage you in interactive features</li>
      <li>To offer customer care and support</li>
      <li>To analyze usage patterns and improve our services</li>
      <li>To detect and address technical issues</li>
    </ul>

    <h2>Transfer Of Data</h2>

    <p>Your data, including Personal Data, may be transferred and stored outside your jurisdiction, including in Sweden.
      By using our service and providing your data, you consent to such transfers.</p>

    <p>We take steps to secure and protect your data in line with this Privacy Policy.</p>

    <h2>Disclosure Of Data</h2>

    <p>We may disclose your Personal Data in response to legal obligations, protect our rights and property, prevent
      wrongdoing, safeguard users' safety, or address legal liabilities.</p>

    <h2>Security Of Data</h2>

    <p>While we strive to protect your data, no internet transmission or storage method is completely secure. We take
      commercially reasonable measures to safeguard your Personal Data, but we cannot guarantee its absolute
      security.</p>

    <h2>Service Providers</h2>

    <p>We may engage third-party companies and individuals ("Service Providers") to help us provide and analyze our
      service. Service Providers access Personal Data solely to assist us and are bound to confidentiality.</p>

    <h2>Analytics</h2>

    <p>We may use third-party analytics services, such as Google Analytics, to monitor and analyze service usage. These
      services may collect and share data to enhance their own advertising networks.</p>

    <p>To opt-out of Google Analytics tracking, install the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics opt-out browser add-on</a>.</p>

    <h2>Links To Other Sites</h2>

    <p>Our Website may include links to third-party sites. These links are provided for your convenience, and we advise
      reviewing the privacy policies of those sites. We assume no responsibility for third-party content or
      practices.</p>

    <h2>Children's Privacy</h2>

    <p>We do not knowingly collect Personal Data from individuals under 18 years of age. If you are a parent and
      discover your child has provided us with Personal Data, please contact us to remove the information.</p>

    <h2>Changes To This Privacy Policy</h2>

    <p>We may update this Privacy Policy occasionally. We will notify you of changes through prominent notices on the
      Website or via email prior to the changes taking effect. Please revisit this page periodically to stay
      informed.</p>

    <p>For any questions or concerns about this Privacy Policy, contact us at <a href="mailto:privacy@cogny.com">privacy@cogny.com</a>.</p>

    <p><em>End of Privacy Policy</em></p>
  </div>
);

function Privacy(props) {
  if (props.raw) return <PrivacyContent />;
  return (
    <OutsideBox classBase="Privacy">
      <PrivacyContent />
    </OutsideBox>
  );
}

export default Privacy;
