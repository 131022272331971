import './style.scss';
import { ReactComponent as IconAnswered } from './assets/answered.svg';
import { ReactComponent as IconApprove } from './assets/approve.svg';
import { ReactComponent as IconArrowLeft } from './assets/arrow-left.svg';
import { ReactComponent as IconArrowRight } from './assets/arrow-right.svg';
import { ReactComponent as IconCardMoveFrom } from './assets/card-move-from.svg';
import { ReactComponent as IconClipboardCheck } from './assets/clipboard-check.svg';
import { ReactComponent as IconCoins } from './assets/coins.svg';
import { ReactComponent as IconCross } from './assets/cross.svg';
import { ReactComponent as IconEstimate } from './assets/estimate.svg';
import { ReactComponent as IconMenu } from './assets/menu.svg';
import { ReactComponent as IconPriority } from './assets/priority.svg';
import { ReactComponent as IconRequest } from './assets/request.svg';
import { ReactComponent as IconSettings } from './assets/settings.svg';
import { ReactComponent as IconUser } from './assets/user.svg';
import { ReactComponent as IconPlus } from './assets/plus.svg';
import { ReactComponent as IconHome } from './assets/home.svg';
import { ReactComponent as IconDashboard } from './assets/dashboard.svg';
import { ReactComponent as IconDatasource } from './assets/datasource.svg';
import { ReactComponent as IconTerminal } from './assets/terminal.svg';
import { ReactComponent as IconAttribution } from './assets/attribution.svg';
import { ReactComponent as IconDataVis } from './assets/datavis.svg';
import { ReactComponent as IconMMM } from './assets/mmm.svg';
import { ReactComponent as IconImpact } from './assets/impact.svg';
import { ReactComponent as IconSort } from './assets/sort.svg';
import { ReactComponent as IconServer } from './assets/server.svg';
import { ReactComponent as IconChevronUp } from './assets/chevron-up.svg';
import { ReactComponent as IconChevronRight } from './assets/chevron-right.svg';
import { ReactComponent as IconChevronDown } from './assets/chevron-down.svg';
import { ReactComponent as IconChevronLeft } from './assets/chevron-left.svg';
import { ReactComponent as IconCheck } from './assets/check.svg';
import { ReactComponent as IconEdit } from './assets/edit.svg';
import { ReactComponent as IconStar } from './assets/star.svg';
import { ReactComponent as IconTrashcan } from './assets/trashcan.svg';
import { ReactComponent as IconSend } from './assets/send.svg';
import { ReactComponent as IconExpand } from './assets/expand.svg';
import { ReactComponent as IconCopy } from './assets/copy.svg';

function Icon(props) {
  const { icon = "plus", className = "" } = props;

  const icons = {
    impact: IconImpact,
    mmm: IconMMM,
    datavis: IconDataVis,
    attribution: IconAttribution,
    terminal: IconTerminal,
    datasources: IconDatasource,
    dashboard: IconDashboard,
    home: IconHome,
    plus: IconPlus,
    user: IconUser,
    settings: IconSettings,
    request: IconRequest,
    priority: IconPriority,
    menu: IconMenu,
    estimate: IconEstimate,
    cross: IconCross,
    coins: IconCoins,
    clipboardCheck: IconClipboardCheck,
    cardMoveFrom: IconCardMoveFrom,
    arrowRight: IconArrowRight,
    arrowLeft: IconArrowLeft,
    approve: IconApprove,
    answered: IconAnswered,
    sort: IconSort,
    server: IconServer,
    chevronUp: IconChevronUp,
    chevronRight: IconChevronRight,
    chevronDown: IconChevronDown,
    chevronLeft: IconChevronLeft,
    check: IconCheck,
    edit: IconEdit,
    star: IconStar,
    trashcan: IconTrashcan,
    send: IconSend,
    expand: IconExpand,
    copy: IconCopy,
  }

  const IconComponent = icons[icon];

  return <IconComponent className={"Icon Icon--" + icon + " " + className} />;
}

export default Icon;
