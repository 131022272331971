import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

function PrivateRoute(props) {
  const { hasToken } = useAuth();
  const location = useLocation();

  return hasToken() ? props.children : <Navigate to="/signed-out" state={{ from: location, hasReturnUrl: true }} replace />;
}

export default PrivateRoute;
