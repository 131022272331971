import 'App.scss';
import routes from 'routes';
import { useRoutes } from 'react-router-dom';
import { useAppData } from 'hooks/useAppData';
import ErrorPage from 'pages/ErrorPage';
import Page from 'components/organisms/Page';

function App() {
  const element = useRoutes(routes);
  const { error } = useAppData();

  if (error) {
    return (
      <div className="App">
        <Page
          title="An error has occurred"
          size="small"
          sidebar={false}
          isError
        >
          <ErrorPage
            error={error}
          />
        </Page>
      </div>
    );
  }

  return (
    <div className="App">
      {element}
    </div>
  );
}

export default App;
