import './style.scss';

function WelcomeTitle(props) {

  return (
    <h1 className="WelcomeTitle">Welcome to <span className="WelcomeTitle__logo">Cogny</span></h1>
  );
}

export default WelcomeTitle;
