import PageHeader from 'components/organisms/PageHeader';
import './style.scss';
import DashboardInfo from 'components/organisms/DashboardInfo';
import { useEffect, useState } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import LoadingDots from 'components/atoms/LoadingDots';

const ProjectId = (props) => {
  const { data, isLoaded, error, className = "" } = props;

  const projectId = data?.google_project_name ?? "n/a";

  return (
    <span className={"ProjectId " + className}>
      {
        !isLoaded ?
          <LoadingDots />
          :
          projectId
      }
    </span>
  );
}

function DataVisForm(props) {
  const [warehouse, setWarehouse] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();
  const warehouseId = params.warehouse_id;
  const [cookies] = useCookies(["token"]);

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouse(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setWarehouse(result);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
      setIsLoaded(false);
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, warehouseId])

  /*
  const options = [
    { value: 'growth_funnel', label: 'Growth Funnel', sample: '/images/datavis_funnel.png', description: 'The Growth Funnel report helps you understand the bottlenecks in your user journey.', ds_link: 'https://datastudio.google.com/reporting/0B_U5RNpwhcE6SF85TENURnc4UjA/preview' },
    { value: 'sales', label: 'Sales' },
    { value: 'ads', label: 'Ads' },
    { value: 'churn', label: 'Churn' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'products', label: 'Products' },
    { value: 'seo', label: 'SEO', sample: '/images/datavis_seo.png', description: 'The SEO report helps you keep an eye on your google ranking, important queries etc.', ds_link: 'https://datastudio.google.com/reporting/0B_U5RNpwhcE6QXg4SXFBVGUwMjg/preview' },
  ];
  */

  return (
    <>
      <div className="DataVisForm">
        <PageHeader pretitle="Data visualisation" title="Build dashboards" />
        <DashboardInfo data={{
          title: "How do I build dashboards?",
          youtube: "Lq0cT2u2rW8",
          hidden: false,
        }}>
          <p>We have made a video tutorial to help you get started with creating your own dashboard visualisations. Please start off by watching the video.</p>
          <p>When it's time to add your BigQuery data, your project ID will be: <ProjectId data={warehouse} isLoaded={isLoaded} error={error} /></p>
          <p><a href="https://lookerstudio.google.com/" target="_blank" rel="noreferrer">Go to Looker Studio to continue</a></p>
        </DashboardInfo>
      </div>
    </>
  );
}

export default DataVisForm;
