import './style.scss';
import BookADemo from 'outside/components/BookADemo';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SubpageHero from 'outside/components/SubpageHero';
import useBodyClass from 'hooks/useBodyClass';
import OutsideBox from 'outside/components/OutsideBox';
import BlogPosts from 'outside/components/BlogPosts';
import useBlogPosts from 'hooks/useBlogPosts';
import LoadingDots from 'components/atoms/LoadingDots';
import BlogPost from 'outside/components/BlogPost';
import { useOutsideData } from 'hooks/useOutsideData';

function Blog() {
  const params = useParams();
  const slug = params.slug;
  const navigate = useNavigate();
  const posts = useBlogPosts();
  const { data, isLoaded, error } = posts;
  const { setOutsideBackgroundStyle } = useOutsideData();

  const [currentPost, setCurrentPost] = useState(undefined);

  useBodyClass('outside-wide');

  useEffect(() => {
    if (isLoaded && !error) {
      if (typeof currentPost === "undefined") {
        navigate("/blog/");
      } else if (slug !== currentPost.slug) {
        navigate("/blog/" + currentPost.slug);
      }

      if (currentPost?.image) {
        const backgroundStyles = {
          backgroundImage: "url(/static/" + currentPost.image + ")",
          backgroundSize: "100% 100%",
          opacity: 0.32,
          width: "calc(100% + 20px)",
          left: -10,
          right: -10,
          height: 820,
          filter: "blur(32px)",
          maskImage: "linear-gradient(to bottom, black 0%, transparent 100%)"
        };
        setOutsideBackgroundStyle(backgroundStyles);
      }
    }

    return () => {
      setOutsideBackgroundStyle({});
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPost])

  useEffect(() => {
    if (isLoaded && !error) {
      setCurrentPost(data.find(post => slug === post.slug));
    } else {
      setCurrentPost(undefined);
    }

    return () => {
      setCurrentPost(undefined);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, error, slug])

  if (!isLoaded) return <LoadingDots />;

  if (currentPost) {
    return (
      <div className="Blog">
        <SubpageHero className="" blogpost={currentPost} size="wide" />

        {currentPost.image && (
          <OutsideBox classBase="BlogImage" nobg>
            <div className="BlogImage"><img src={"/static/" + currentPost.image} alt={currentPost.title} /></div>
          </OutsideBox>
        )}

        <OutsideBox classBase="BlogPost">
          <BlogPost post={currentPost} />
        </OutsideBox>

        <BookADemo />

        <BlogPosts current={currentPost} rows={1} order="random" title="There's more to read" description="Keep up with the latest on our RevOps blog" />
      </div>
    )
  }

  return (
    <div className="Blog">
      <SubpageHero title="Blog" tagline="Keep up with the latest on our RevOps blog" size="wide" />
      <BlogPosts />
    </div>
  );
}

export default Blog;
