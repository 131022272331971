import './style.scss';
import { Link } from 'react-router-dom';
import Logo from 'components/atoms/Logo';
import { useAppData } from 'hooks/useAppData';

function HomeLink(props) {
  const { getPrePath } = useAppData();
  return (
    <div className="HomeLink">
      <Link className="HomeLink__link" to={getPrePath()}>
        <Logo className="HomeLink__image" alt="Cogny" />
      </Link>
    </div>
  );
}

export default HomeLink;
