import './style.scss';

function AccessListing(props) {
  const { task } = props;

  return (
    <div className="AccessListing" key={task.id}>
      <div className="AccessListing__main">
        <h3
          className="AccessListing__title"
          style={{
            fontSize: 26,
            lineHeight: 1.14,
            fontWeight: 600,
            margin: 0,
            padding: 0,
          }}
        >
          {task.email}
        </h3>
      </div>
      <div className="AccessListing__meta">
        <h4 className="AccessListing__title">Role: {task.role}</h4>
      </div>
    </div>
  );
}

export default AccessListing;
