import './style.scss';
import { useEffect } from 'react';
import { Route, Navigate, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Terms from 'outside/pages/Terms';
import Security from 'outside/pages/Security';
import Privacy from 'outside/pages/Privacy';
import Cookies from 'outside/pages/Cookies';
import GA4 from 'outside/pages/GA4';
import UseCases from 'outside/pages/UseCases';
import Mission from 'outside/pages/Mission';
import Frontpage from 'outside/pages/Frontpage';

import OutsideHeader from 'outside/components/OutsideHeader';
import OutsideFooter from 'outside/components/OutsideFooter';
import OutsideContent from 'outside/components/OutsideContent';
import OutsideNotFound from 'outside/pages/OutsideNotFound';
import SST from 'outside/pages/SST';
import GDPRMonitor from 'outside/pages/GDPRMonitor';
import Pricing from 'outside/pages/Pricing';
import Blog from 'outside/pages/Blog';
import SignedOut from 'outside/pages/SignedOut';
import { useAuth } from 'hooks/useAuth';

function Outside() {
  const location = useLocation();
  const redirectedUrls = ["/login"];
  const isRedirecting = redirectedUrls.includes(location.pathname);
  const apiHost = process.env.REACT_APP_API_BASE_URL;

  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("return_to");

  function getTitle(name) {
    const separator = " - ";
    const brandName = "Cogny";
    const tagline = "Meet your new data analyst";
    const defaultTitle = brandName + separator + tagline;
    if (!name) return defaultTitle;
    return name + separator + defaultTitle;
  }

  const ExternalRedirect = (props) => {
    const { hasToken } = useAuth();
    const to = hasToken() ? "/app" : props.to;

    useEffect(() => {
      window.location.replace(to);
    }, [to]);

    return null;
  }

  const Page = (props) => {
    const title = props.title;
    return (
      <>
        <Helmet>
          <title>{getTitle(title)}</title>
        </Helmet>
        {props.children}
      </>
    );
  }

  return (
    <div className="Outside">
      <OutsideHeader />

      <Routes>
        <Route
          path="login"
          element={
            <ExternalRedirect to={apiHost + "/api/v1/accounts/login" + (returnUrl ? "?return_to=" + returnUrl : "")} />
          }
        />

        <Route
          path="/signed-out"
          element={
            <Page title={getTitle("You need to sign in to Cogny")}>
              <OutsideContent background="main">
                <SignedOut />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="terms"
          element={
            <Page title={getTitle("Terms")}>
              <OutsideContent>
                <Terms />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="security"
          element={
            <Page title={getTitle("Security")}>
              <OutsideContent>
                <Security />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="privacy"
          element={
            <Page title={getTitle("Privacy policy")}>
              <OutsideContent>
                <Privacy />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="cookies"
          element={
            <Page title={getTitle("Cookie policy")}>
              <OutsideContent>
                <Cookies />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="mission"
          element={
            <Page title={getTitle("Mission")}>
              <OutsideContent>
                <Mission />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="usecases/:slug?"
          element={
            <Page title={getTitle("Use Cases")}>
              <OutsideContent background="faded">
                <UseCases />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="blog/:slug"
          element={
            <Page title={getTitle("Blog Post")}>
              <OutsideContent background="custom">
                <Blog />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="blog"
          element={
            <Page title={getTitle("Blog")}>
              <OutsideContent>
                <Blog />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="sst"
          element={
            <Page title={getTitle("Server-side tracking")}>
              <OutsideContent background="main">
                <SST />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="sv/sst"
          element={
            <Page title={getTitle("Server-side tracking")}>
              <OutsideContent background="main">
                <SST lang="sv" />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="pricing"
          element={
            <Page title={getTitle("Pricing")}>
              <OutsideContent background="alt">
                <Pricing />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="sv/pricing"
          element={
            <Page title={getTitle("Pris")}>
              <OutsideContent background="alt">
                <Pricing lang="sv" />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="gdpr"
          element={
            <Page title={getTitle("GDPR Monitoring")}>
              <OutsideContent background="main">
                <GDPRMonitor />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="sv/gdpr"
          element={
            <Page title={getTitle("GDPR Monitorering")}>
              <OutsideContent background="main">
                <GDPRMonitor lang="sv" />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="ga4"
          element={
            <Page title={getTitle("Google Analytics 4 + BigQuery")}>
              <OutsideContent background="faded">
                <GA4 />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="home"
          element={
            <Navigate to="/" replace />
          }
        />

        <Route
          path="sv"
          element={
            <Page title={getTitle()}>
              <OutsideContent background="main">
                <Frontpage lang="sv" />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          index
          element={
            <Page title={getTitle()}>
              <OutsideContent background="main">
                <Frontpage lang="en" />
              </OutsideContent>
            </Page>
          }
        />

        <Route
          path="*"
          element={
            <Page title={getTitle("Page not found")}>
              <OutsideContent>
                <OutsideNotFound />
              </OutsideContent>
            </Page>
          }
        />
      </Routes>

      {!isRedirecting && <OutsideFooter />}

    </div>
  );
}

export default Outside;
