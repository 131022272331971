import { useState, useEffect } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';

const doubleCheckResults = (result) => {
  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  return getUniqueListBy(result, 'gid');
}

function useDatasources() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [cookies] = useCookies(["token", "project"]);
  const params = useParams();
  const warehouseId = params.warehouse_id;

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getDatasources(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setIsLoaded(true);
            const checkedResult = doubleCheckResults(result);
            setData(checkedResult);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.token])

  const dataObject = { data, error, isLoaded };

  return dataObject;
}

export default useDatasources;