import './style.scss';
import OutsideBox from 'outside/components/OutsideBox';
import allaBolagLogo from './assets/allabolag.svg';
import brisLogo from './assets/bris.svg';
import epicenterLogo from './assets/epicenter.svg';
import ucLogo from './assets/uc.svg';
import dpjLogo from './assets/dpj.svg';
import elexirpharmaLogo from './assets/elexirpharma.svg';

function Customers(props) {
  const lang = props.lang;

  const items = [
    {
      logo: allaBolagLogo,
      name: "Alla bolag",
    },
    {
      logo: brisLogo,
      name: "Bris",
    },
    {
      logo: ucLogo,
      name: "UC",
    },
    {
      logo: epicenterLogo,
      name: "Epicenter",
    },
    {
      logo: dpjLogo,
      name: "DPJ",
    },
    {
      logo: elexirpharmaLogo,
      name: "Elexir Pharma",
    },
  ];

  items.length = props.count === "all" ? 6 : 4;

  return (
    <OutsideBox classBase="Customers" nobg>
      <div className="Customers">
        <h2 className="Customers__title">
          {lang === "sv" ?
            "Våra kunder"
            :
            "Trusted by leading companies"
          }
        </h2>
        <div className="Customers__cards">
          {items.map(item => (
            <div className="Customers__card" key={item.name}>
              <img src={item.logo} alt={item.name} className="Customers__logo" />
            </div>
          ))}
        </div>

      </div>
    </OutsideBox>
  );
}

export default Customers;
