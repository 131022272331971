import './style.scss';
import questions_gdpr_en from './questions_gdpr';
import questions_sst_en from './questions_sst';
import questions_sst_sv from './questions_sst.sv';
import questions_front_en from './questions_front';
import questions_pricing_en from './questions_pricing';
import camelcase from 'camelcase';
import ReactMarkdown from 'react-markdown';
import { useState } from 'react';
import Icon from 'components/atoms/Icon';

export const faqData = {
  gdpr: {
    en: questions_gdpr_en
  },
  sst: {
    en: questions_sst_en,
    sv: questions_sst_sv
  },
  front: {
    en: questions_front_en
  },
  pricing: {
    en: questions_pricing_en
  }
};

function FAQ(props) {
  const lang = props.lang;
  const title = props.title;
  const intro = props.intro;
  const questions = props.faq;

  if (!questions || !Array.isArray(questions) || questions.length === 0) {
    console.log("Error: No questions data provided to FAQ");
    return "";
  }

  return (
    <div className="FAQ" itemScope itemType="https://schema.org/FAQPage">
      <div className="FAQ__header">
        <h2 className="FAQ__title">{title ?? "FAQ"}</h2>
        <div className="FAQ__intro">
          {
            !intro && (
              lang === "sv" ?
                <p>Om du inte hittar svar på din fråga kan du <a href="mailto:contact@cogny.com">kontakta oss</a> så svarar vi så snart vi kan.</p>
                :
                <p>If you can't find what you're looking for, please <a href="mailto:contact@cogny.com">contact us</a> and we'll get back to you as soon as possible.</p>
            )
          }
          {intro}
        </div>
      </div>

      <div className="FAQ__questions">
        {
          questions && questions.map(item => {
            return (
              <Question
                question={item.question}
                answer={item.answer}
                key={camelcase(item.question)}
              />
            );
          })
        }

      </div>
    </div>
  );
}

function Question(props) {
  const [open, setOpen] = useState(false);
  const { question, answer } = props;

  return (
    <div className={"Question" + (open ? " Question--open" : "")} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <div className="Question__box">
        <Icon className="Question__icon" icon={open ? "chevronUp" : "chevronDown"} />
        <div className="Question__toggle" onClick={() => setOpen(!open)}>
          <h3 className="Question__question" itemProp="name">{question}</h3>
        </div>

        <div className="Question__answer" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <ReactMarkdown>{answer}</ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ;
