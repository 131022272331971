import './style.scss';
import OutsideBox from '../OutsideBox';
import { ReactComponent as Arrow } from './arrow.svg';

function BookADemo(props) {
  return (
    <OutsideBox classBase="BookADemo">
      <div className="BookADemo">
        <div className="BookADemo__content">
          <h2 className="BookADemo__title">
            {props.title ?? "Are you ready?"}
          </h2>
          <p className="BookADemo__text">
            {props.text ?? "Revolutionize Your Revenue Operations, Get Started with Cogny RevOps Now."}
          </p>
        </div>
        <a href={props.linkHref ?? "https://meetings-eu1.hubspot.com/tom-stroem"} className="BookADemo__button">
          <span className="BookADemo__buttonLabel">
            {props.buttonLabel ?? "Book a demo"}
          </span>
          <Arrow className="BookADemo__buttonArrow" />
        </a>
      </div>
    </OutsideBox>
  );
}

export default BookADemo;
