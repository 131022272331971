import './style.scss';
import { useParams } from 'react-router-dom';
import LoadingDots from 'components/atoms/LoadingDots';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import SignOutLink from 'components/atoms/SignOutLink';
import Icon from 'components/atoms/Icon';
import CognyAPI from 'components/_classes/CognyAPI';
import MenuItem from 'components/atoms/MenuItem';
import Button from 'components/atoms/Button';
import { useAppData } from 'hooks/useAppData';

function AccountMenu(props) {
  const params = useParams();
  const warehouse_id = params.warehouse_id;

  const [toggled, setToggled] = useState(false);

  const [warehouses, setWarehouses] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [currentAccount, setCurrentAccount] = useState(false);

  const [cookies] = useCookies(["token"]);

  const { getPath, getPrePath, getCustomPath } = useAppData();

  useEffect(() => {
    const matchedWarehouse = warehouses.find(item => item.warehouse_id === warehouse_id);

    if (typeof matchedWarehouse === "undefined" || typeof matchedWarehouse.name === "undefined") return;

    setCurrentAccount(matchedWarehouse.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouses, warehouse_id])

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouses()
        .then(
          (result) => {
            if (!mounted) return;
            setIsLoaded(true);
            setWarehouses(result);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
  }, [cookies.token, warehouse_id])

  const menuItemsData = [
    {
      label: "Settings",
      href: getPath("settings"),
      icon: "settings",
      //altHref: "/datasource",
    }
  ];

  const menuItems = menuItemsData.map((item, index) => {
    const altHrefClass = item.altHref && warehouse_id.indexOf(item.altHref) === 0 ? " AccountMenu__link--active" : "";

    return <MenuItem className={"AccountMenu__link" + altHrefClass} href={item.href} key={index}><Icon className="AccountMenu__icon MenuItem__Icon" icon={item.icon} /><span className="AccountMenu__text">{item.label}</span></MenuItem>
  });

  if (error) {
    console.log(error);
    return (
      <div className={"AccountMenu AccountMenu--open"}>
        <div className="AccountMenu__items">
          <div className="AccountMenu__error">
            <div className="AccountMenu__label">An error has occured</div>
          </div>
          <div className="AccountMenu__content">
            <div>
              <div className="AccountMenu__links">
                <SignOutLink className="AccountMenu__link"><Icon icon="cardMoveFrom" className="AccountMenu__icon" /><span className="AccountMenu__text">Sign Out</span></SignOutLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!isLoaded) {
    return <LoadingDots className="AccountMenu__loading" alt="" />;
  }

  return (
    <div className={"AccountMenu" + (toggled ? " AccountMenu--open" : "")}>
      <div className="AccountMenu__items">
        <button className="AccountMenu__current" onClick={() => setToggled(!toggled)}>
          <div className="AccountMenu__label">Current account</div>
          <div className="AccountMenu__name">{currentAccount}</div>
          <Icon icon={toggled ? "chevronDown" : "chevronUp"} className="AccountMenu__toggle" />
        </button>
        <div className="AccountMenu__content">
          <div>
            <div className="AccountMenu__links">
              {menuItems}

              <SignOutLink className="AccountMenu__link"><Icon icon="cardMoveFrom" className="AccountMenu__icon" /><span className="AccountMenu__text">Sign out</span></SignOutLink>
            </div>

            <div className={"AccountMenu__switcher" + (warehouses.length > 4 ? " AccountMenu__switcher--large" : "")}>
              <div className="AccountMenu__label AccountMenu__midlabel">Select an account</div>
              <div className="AccountMenu__accounts">
                {warehouses.map(w => {
                  if (w.warehouse_id === warehouse_id) return "";

                  return (
                    <MenuItem key={w.warehouse_id} className="AccountMenu__link" href={getCustomPath(w.warehouse_id, "dashboard")}><span className="AccountMenu__text">{w.name}</span></MenuItem>
                  );
                })}
              </div>
            </div>

            <div className="AccountMenu__add">
              <Button type="link" href={getPrePath("new_account")} className="AccountMenu__addButton" icon="plus">Add account</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountMenu;