import Icon from 'components/atoms/Icon';
import './style.scss';
import { Link } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

function SignOutLink(props) {
  const { removeToken } = useAuth();
  const { className = "", children = "Sign out", dark, label } = props;

  const handleSignOut = (e) => {
    removeToken();
  }

  if (dark) {
    return (
      <Link to="/" className={"SignOutLink SignOutLink--dark " + className} onClick={handleSignOut}>
        <Icon icon="cardMoveFrom" className="SignOutLink__icon" /><span className="SignOutLink__label">{label || children || "Sign out"}</span>
      </Link>
    );
  }

  return (
    <Link to="/" className={"SignOutLink " + className} onClick={handleSignOut}>{label || children}</Link>
  );
}

export default SignOutLink;
