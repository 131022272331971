import React, { useEffect } from 'react';
import './style.scss';
import { usePageSidebar } from 'hooks/usePageSidebar';
import { useParams } from 'react-router-dom';

const mockupData = [
  {
    "id": "123",
    "name": "Item 1",
    "created_at": "2024-05-10T22:04:30Z",
  },
  {
    "id": "234",
    "name": "Item 2",
    "created_at": "2024-05-10T22:01:41Z",
  },
  {
    "id": "345",
    "name": "Item 3",
    "created_at": "2024-05-10T10:17:47Z",
  },
  {
    "id": "456",
    "name": "Item 4",
    "created_at": "2024-05-10T10:04:55Z",
  },
];

const convertDateObjectToDatestring = (date) => {
  const pad = (number) => String(number).padStart(2, '0');
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are 0-indexed
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const dateString = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
  return dateString;
}

function Testing(props) {
  const params = useParams();
  const itemId = params.id;
  const {
    setItems: setSidebarItems,
    setIsLoaded: setSidebarIsLoaded,
    // getIsLoaded: getSidebarIsLoaded,
    // setError: setSidebarError,
    // getError: getSidebarError,
    setLinkPath,
    setButtonLabel,
    setButtonTarget,
    setEmptyMessage,
    setHasFavorites,
    setTitle,
    setSorting,
  } = usePageSidebar();

  const reformatISOTime = (isoTime) => {
    const date = new Date(isoTime);
    return convertDateObjectToDatestring(date);
  }

  const setupSidebar = () => {
    setLinkPath("/testing");
    setButtonTarget("/testing/new");
    setButtonLabel("New chat");
    setEmptyMessage("You don't have any test items");
    setHasFavorites(false);
    setTitle("Test items");
    setSorting([
      {
        key: 'recent',
        label: 'Recent',
        sortFunction: (list) => [...list],
        meta: "created_at",
        metaFormatter: (string) => reformatISOTime(string),
      },
      {
        key: 'alphabetical',
        label: 'A-Z',
        sortFunction: (list) => list.slice().sort((a, b) => {
          // Sort alphabetically by name, but put items without a name at the end
          const aNameMissing = !a.name || a.name === '';
          const bNameMissing = !b.name || b.name === '';

          if (aNameMissing && bNameMissing) return 0;
          if (aNameMissing) return 1;
          if (bNameMissing) return -1;
          return a.name.localeCompare(b.name);
        }),
        meta: "created_at",
        metaFormatter: (string) => reformatISOTime(string),
      },
    ]);
  }

  useEffect(() => {
    setSidebarItems(mockupData);
    setupSidebar();
    setSidebarIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentItem = mockupData.find(item => itemId === item.id);

  return (
    <div className="Testing" style={{ padding: 32 }}>
      <h1>
        {
          currentItem ? currentItem.name : 'Testing'
        }
      </h1>

      {
        currentItem &&
        <>
          <div>Created: {reformatISOTime(currentItem.created_at)}</div>
          <div>ID: {currentItem.id}</div>
        </>
      }

      {props.testing &&
        <h2>
          Additional data: {props.testing}
        </h2>
      }
    </div>
  );
}

export default Testing;
