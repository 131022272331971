import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { useCookies } from 'react-cookie';
import CognyAPI from 'components/_classes/CognyAPI';
import Button from 'components/atoms/Button';
import Form from 'components/atoms/Form';
import Field from 'components/molecules/Field';
import LoadingDots from 'components/atoms/LoadingDots';
import Markdown from 'react-markdown'
import ErrorBox from 'components/molecules/ErrorBox';
const debug = false;

function CopilotPreviewForm(props) {
  const { messages, setMessages, hasOngoingRun, setHasOngoingRun, setScrollToBottomAfterNextUpdate, chatRef, lang } = props;
  const ref = useRef(null);
  const [inputRef, setInputRef] = useState(null);
  const [initialFocus, setInitialFocus] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [cookies, setCookie] = useCookies(["chatId"]);

  const isLastMessageFromUser = messages.length > 0 && messages[messages.length - 1].role === "user";
  const disabledForm = hasOngoingRun || isLastMessageFromUser;

  useEffect(() => {
    if (inputRef && !initialFocus) {
      setInitialFocus(true);

      const inputBottom = inputRef.getBoundingClientRect().bottom + 24;
      const viewportBottom = window.innerHeight;
      const isInputVisible = inputBottom < viewportBottom;

      if (!isInputVisible) return;
      inputRef.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  const handleFocus = (e) => {
    if (!chatRef) return;
    if (window.innerWidth > 766) return;
    if (!navigator.userAgent.match(/(iPod|iPhone|iPad)/) && !navigator.userAgent.match(/(Safari)/)) return;

    e.target.style.opacity = 0;

    setTimeout(() => {
      e.target.style.opacity = 1;
      const chatTop = chatRef.getBoundingClientRect().top;

      window.scrollTo({
        top: window.scrollY + chatTop - 30,
        behavior: 'smooth'
      });
    }, 100);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (disabledForm || userMessage === "") return;

    const api = new CognyAPI("anonymous");

    //generate a random id for the message
    function generateTempId(length) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      for (let i = 0; i < 24; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }

      return result;
    }

    const message = {
      role: "user",
      message: userMessage,
      content: [
        {
          "type": "text",
          "text": {
            "value": userMessage,
            "annotations": []
          }
        }
      ]
    };

    const optimisticMessage = {
      ...message,
      status: "optimistic",
      created_at: "now",
      id: `msg_${generateTempId()}`,
    };

    const answerMessage = {
      role: "assistant",
      content: [],
    };

    const optimisticAnswerMessage = {
      ...answerMessage,
      status: "optimistic",
      created_at: "now",
      id: `msg_${generateTempId()}`,
    };

    const lastUserMessage = userMessage;
    setUserMessage("");

    // first add message optimistically
    setScrollToBottomAfterNextUpdate(true);
    setMessages(prevMessages => [...prevMessages, optimisticMessage, optimisticAnswerMessage]);
    setHasOngoingRun(true);
    if (!cookies.chatId) {
      if (window.dataLayer) {
        window.dataLayer.push({ event: "start_chat" });
      }
      api.addCopilotPreview(message)
        .then(
          (chat) => {
            const daysInTheFutureCookieExpires = 180;
            setCookie("chatId", chat?.thread_id, {
              path: "/",
              sameSite: "strict",
              secure: true,
              expires: new Date(Date.now() + daysInTheFutureCookieExpires * 24 * 60 * 60 * 1000)
            });
          },
          (error) => {
            setUserMessage(lastUserMessage);
            console.log(error);
          }
        )
    } else {
      setHasOngoingRun(true);
      if (window.dataLayer) {
        window.dataLayer.push({ event: "chat_message" });
      }
      api.sendCopilotPreviewMessage(cookies.chatId, message).then(
        () => {
          setMessages(messages.concat(message));
        },
        (error) => {
          console.log(error);
        }
      )
    }
  }

  return (
    <div className="CopilotPreviewForm" ref={ref}>
      <Form onSubmit={handleSubmit}>
        <div className="FieldRow">
          <Field
            type="dynamictextarea"
            placeholder={lang === "sv" ? "Vi är ett..." : "We are a..."}
            onChange={(e) => { setUserMessage(e.target.value) }}
            value={userMessage}
            key="editor1"
            submit={handleSubmit}
            rows={[1, 5]}
            onFocus={handleFocus}
            setRef={setInputRef}
          />

          <Button icon={hasOngoingRun ? false : "send"} disabled={disabledForm} type="submit" iconSide="right">
            {hasOngoingRun && <div><LoadingDots /></div>}
            {!hasOngoingRun && <div>{lang === "sv" ? "Skicka" : "Send"}</div>}
          </Button>
        </div>
      </Form>
    </div>
  );
}

const CopilotPreviewMessage = (props) => {
  const { role, content } = props.data;

  const convertLineBreaks = (str) => (
    str.split('\n').map((line, index, array) => (
      index === array.length - 1 ? line : <React.Fragment key={index}>{line}<br /></React.Fragment>
    ))
  )

  return (
    <>
      <div className={"CopilotPreviewMessage " + (role === "user" ? "CopilotPreviewMessage--you" : "CopilotPreviewMessage--copilot")}>
        <div className="CopilotPreviewMessage__header">
          <div className="CopilotPreviewMessage__sentby">
            <div className="CopilotPreviewMessage__avatar"></div>
            <div className="CopilotPreviewMessage__name">{role === "user" ? "You" : "Kathy"}</div>
          </div>
        </div>
        <div className="CopilotPreviewMessage__content">
          {
            content.length > 0 ?
              content?.map((item, index) => {
                if (role === "user" && item?.type === "text") {
                  return <React.Fragment key={index}>{convertLineBreaks(item?.text?.value)}</React.Fragment>
                } else if (role === "assistant" && item.type === "text") {
                  return <Markdown key={index}>{item?.text?.value}</Markdown>
                } else if (role === "tool") {
                  return <div class="PreviewToolMessage" key={index}>{item?.text?.value}</div>
                } else {
                  return null;
                }
              })
              :
              role === "assistant" ?
                <div style={{ display: "flex" }}><LoadingDots /></div>
                :
                <span className="CopilotPreviewMessage__message--empty" style={{ opacity: 0.5 }}>Empty message</span>
          }
        </div>
      </div>
    </>
  );
}

const CopilotPreviewLog = (props) => {
  const { style, messages, setRef, lang } = props;
  const ref = useRef(null);
  const [scrollState, setScrollState] = useState('');
  const [cookies] = useCookies(["chatId"]);

  useEffect(() => {
    if (ref.current) setRef(ref.current);
  }, [setRef, ref]);

  useEffect(() => {
    const handleScroll = () => {
      const div = ref.current;
      const atTop = div.scrollTop > 0;
      const atBottom = div.scrollTop + div.clientHeight < div.scrollHeight;

      if (atTop && atBottom) {
        setScrollState('CopilotPreviewLog--scrolledBoth');
      } else if (atTop) {
        setScrollState('CopilotPreviewLog--scrolledTop');
      } else if (atBottom) {
        setScrollState('CopilotPreviewLog--scrolledBottom');
      } else {
        setScrollState('');
      }
    };

    const logDiv = ref.current;
    if (logDiv) {
      handleScroll();

      logDiv.addEventListener('scroll', handleScroll);

      return () => {
        logDiv.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const welcomeMessage = {
    "id": "welcome",
    "role": "assistant",
    "content": [
      {
        "type": "text",
        "text": {
          "value": (
            lang === "sv"
              ?
              "Hej, jag är Kathy från Cogny.\n\nJag är en AI assistent som är här för att hjälpa dig komma igång med Cogny.\n\nBerätta gärna lite om vad ni gör?"
              :
              "Hi! I'm Kathy from Cogny.\n\nI'm an AI assistant here to help you get started with Cogny.\n\nPlease tell me a little bit about your business?"
          ),
          "annotations": [],
        }
      }
    ]
  };

  const isLastMessageFromUser = messages.length > 0 && messages[messages.length - 1].role === "user";

  return (
    <div className={"CopilotPreviewLog " + (scrollState)} style={{ ...style }} ref={ref}>
      {
        cookies.chatId && messages.length === 0 ?
          <div className="CopilotPreviewLog__loading">
            <span>Resuming chat</span>
            <LoadingDots />
          </div>
          :
          <>
            <CopilotPreviewMessage key={welcomeMessage.id} data={welcomeMessage} />

            {messages?.map(item => {
              return <CopilotPreviewMessage key={item.id} data={item} />;
            })}

            {(isLastMessageFromUser) &&
              <CopilotPreviewMessage key="typing" data={{ role: "assistant", content: [] }} />
            }
          </>
      }
    </div>
  );
}

function CopilotPreviewChatDebugger(props) {
  const { data, clearHistory } = props;
  const [cookies, setCookie, deleteCookie] = useCookies(["chatId"]);
  const [size, setSize] = useState(0);

  function handleSizeToggle() {
    setSize((size + 1) % 3);
  }

  function handleDeleteCookie() {
    deleteCookie("chatId");
  }

  function handleClearHistory() {
    clearHistory();
  }

  return (
    <div style={{
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 100,
      padding: 8,
      paddingTop: 4,
      background: "rgba(0,0,0,0.9)",
      borderRadius: 4,
      overflow: "auto",
      height: size === 0 ? 200 : size === 1 ? 100 : 32,
      width: 300,
      fontSize: 12,
      fontFamily: "Inter, sans-serif",
      lineHeight: 1,
    }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "stretch",
          marginBottom: 0,
          height: 20,
          gap: 6,
        }}
      >
        <button
          onClick={handleSizeToggle}
          style={{
            backgroundColor: 'transparent',
            color: 'rgba(255,255,255,.7)',
            border: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 12,
            lineHeight: 1,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Change size
        </button>
        <button
          onClick={handleClearHistory}
          style={{
            backgroundColor: 'transparent',
            color: 'rgba(255,255,255,.7)',
            border: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 12,
            lineHeight: 1,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Clear history
        </button>
        {cookies.chatId &&
          <button
            onClick={handleDeleteCookie}
            style={{
              backgroundColor: 'transparent',
              color: 'rgba(255,255,255,.7)',
              border: 0,
              padding: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
              lineHeight: 1,
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            Remove chatId
          </button>
        }
      </div>

      <div
        style={{
          color: "rgba(255,255,255,.5)",
          borderLeft: 0,
          display: "flex",
          alignItems: "center",
          gap: 4,
          overflow: "hidden",
          textOverflow: "ellipsis",
          paddingBottom: 4,
          borderBottom: "1px solid rgba(255,255,255,.3)",
          marginBottom: 4,
        }}
      >
        chatId: {cookies.chatId ?? "no cookie"}
      </div>

      {data.map((item, index) => {
        return (
          <details key={index} style={{ marginBottom: 8 }}>
            <summary style={{ cursor: "pointer" }}>
              {item?.timestamp ? new Date(item?.timestamp).toLocaleString() : "empty"}
            </summary>
            <pre style={{ background: "rgba(255,255,255,.1)", padding: 2, overflow: "auto" }}>
              <div style={{ marginBottom: 4 }}>length: {item.messages.length}</div>
              {item.messages.length > 0 ?
                item.messages.map((msg, index) => {
                  return (
                    <details key={index} style={{ marginBottom: 4 }}>
                      <summary style={{ cursor: "pointer", paddingLeft: 8 }}>{msg.id} {msg.status ? "- " + msg.status : ""}</summary>
                      <pre>{JSON.stringify(msg, null, 2)}</pre>
                    </details>
                  );
                })
                :
                "[]"
              }
            </pre>
          </details>
        );
      })}
    </div>
  );
}

function CopilotPreviewChat(props) {
  const lang = props.lang;
  const [messages, setMessages] = useState([]);
  const [messagesHistory, setMessagesHistory] = useState([]);
  const messagesRef = useRef(messages);
  const [hasOngoingRun, setHasOngoingRun] = useState(false);
  const [chatIsLoading, setChatIsLoading] = useState(false);
  const [chatError, setChatError] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [scrollToBottomAfterNextUpdate, setScrollToBottomAfterNextUpdate] = useState(false);
  const mountedRef = useRef(false);
  const [cookies] = useCookies(["chatId"]);
  const [chatRef, setChatRef] = useState(null);

  const clearHistory = () => {
    setMessagesHistory([]);
  }

  function isScrolledToBottom() {
    if (!chatRef) return false;
    return chatRef.scrollHeight - chatRef.scrollTop <= chatRef.clientHeight + 50;
  }

  function scrollToBottom(chatRef) {
    if (!chatRef) return false;

    //animate scroll to bottom
    chatRef.scrollTo({
      top: chatRef.scrollHeight,
      behavior: 'smooth'
    });
  }

  function hasMessageUpdates(currentMessages, newMessages) {
    const currentMessageMap = new Map(currentMessages.map(msg => [msg.id, msg]));
    const newMessageMap = new Map(newMessages.map(msg => [msg.id, msg]));

    if (newMessageMap.size !== currentMessageMap.size) {
      return true;  // Different number of messages
    }

    for (let [id, newMsg] of newMessageMap.entries()) {
      const currentMsg = currentMessageMap.get(id);
      if (!currentMsg) {
        return true;  // New message found
      }

      // Compare the content arrays if the current message is found
      if (!contentsAreEqual(currentMsg.content, newMsg.content)) {
        return true;  // Content has changed
      }
    }

    return false;  // No new or updated messages
  }

  // Helper function to compare the contents of messages
  function contentsAreEqual(currentContent, newContent) {
    if (currentContent.length !== newContent.length) {
      return false;
    }
    for (let i = 0; i < currentContent.length; i++) {
      const curr = currentContent[i];
      const neww = newContent[i];
      if (curr.type !== neww.type || JSON.stringify(curr.text) !== JSON.stringify(neww.text)) {
        return false;
      }
    }
    return true;
  }

  function getChat() {
    if (!cookies.chatId) return;
    if (!messagesRef.current) return;

    setChatIsLoading(true);
    mountedRef.current = true;

    const api = new CognyAPI("anonymous");
    api.getCopilotPreview(cookies.chatId)
      .then(
        (info) => {
          if (!mountedRef.current) return;
          var has_ongoing_run = false;
          var msg_list = info.data ?? [];
          if (info.run_steps != null) {
            for (var i = 0; i < info.run_steps?.length; i++) {
              var run_steps = info.run_steps[i];
              if (run_steps.Run.status === "in_progress" || run_steps.Run.status === "queued" || run_steps.Run.status === "requires_action") {
                has_ongoing_run = true;
              }
              if (run_steps.Steps == null) {
                continue;
              }
              for (var j = 0; j < run_steps.Steps.length; j++) {
                // timestamp in seconds
                var created_at = new Date().getSeconds();
                if (run_steps.Steps[j].created_at != null) {
                  created_at = run_steps.Steps[j].created_at;
                }

                if (run_steps.Steps[j].type === "message_creation" && run_steps.Steps[j].status === "completed") {
                  continue
                }

                msg_list.push({ "role": "tool", "message": run_steps.Steps[j], "created_at": created_at, "msg_id": run_steps.Steps[j].id });
              }
            }
          }

          msg_list = msg_list.sort((a, b) => (a.created_at > b.created_at) ? 1 : -1);

          if (hasMessageUpdates(messagesRef.current, msg_list) && mountedRef.current) {
            let shouldScrollToBottom = isScrolledToBottom();
            if (isInitialLoad) shouldScrollToBottom = true;
            setScrollToBottomAfterNextUpdate(shouldScrollToBottom);

            setMessages(msg_list);
            setHasOngoingRun(has_ongoing_run);
          }
          setIsInitialLoad(false);
          setChatIsLoading(false);
        },
        (error) => {
          if (!mountedRef.current) return;
          setChatError(error);
          setChatIsLoading(false);
          setHasOngoingRun(false);
          setIsInitialLoad(true);
        }
      );
  }

  // every time messages is updated, push the full messages array to a timestamped entry in the history object
  useEffect(() => {
    if (!props.debug) return;
    setMessagesHistory(
      currentState => {
        return [
          ...currentState,
          {
            timestamp: new Date().toISOString(),
            messages: messages
          }
        ];
      }
    );
  }, [messages, props.debug]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (scrollToBottomAfterNextUpdate) {
      // scrolling chat to bottom
      scrollToBottom(chatRef);
    } else {
      // not scrolling chat to bottom
    }

    setScrollToBottomAfterNextUpdate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  useEffect(() => {
    // mounting getChat useEffect
    let polling;

    if (cookies.chatId) {
      // running first getChat
      getChat();

      polling = setInterval(() => {
        // polling getChat
        getChat();
      }, 5000);
    } else {
      mountedRef.current = false;
      setChatError(null);
      setChatIsLoading(false);
      setMessages([]);
      clearInterval(polling);
    }

    return () => {
      // unmounting getChat useEffect
      mountedRef.current = false;
      setChatError(null);
      setChatIsLoading(false);
      // setMessages([]);
      clearInterval(polling);
      //setIsInitialLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, chatRef])

  const isNewChat = typeof cookies.chatId === "undefined";

  if (chatError) {
    return <ErrorBox error={chatError} />;
  }

  return (
    <div className="CopilotPreviewChat">
      {props.debug && <CopilotPreviewChatDebugger data={messagesHistory} clearHistory={clearHistory} />}
      <CopilotPreviewLog
        isNewChat={isNewChat}
        hasOngoingRun={hasOngoingRun}
        messages={messages}
        setRef={setChatRef}
        lang={lang}
      />
      <CopilotPreviewForm
        messages={messages}
        setMessages={setMessages}
        setScrollToBottomAfterNextUpdate={setScrollToBottomAfterNextUpdate}
        hasOngoingRun={hasOngoingRun}
        setHasOngoingRun={setHasOngoingRun}
        chatRef={chatRef}
        lang={lang}
      />
    </div>
  );
}

function CopilotPreview(props) {
  var lang = props.lang;
  return (
    <div className="CopilotPreview">
      {
        props.title && props.intro &&
        <div className="CopilotPreview__header">
          <h2 className="CopilotPreview__title">{props.title}</h2>
          <div className="CopilotPreview__intro">{props.intro}</div>
        </div>
      }

      <div className="CopilotPreview__chat">
        <CopilotPreviewChat debug={debug} lang={lang} />
      </div>
    </div>
  );
}

export default CopilotPreview;
