import './style.scss';

function LegacyPageSidebar(props) {
  return (
    <aside className="PageSidebar">
      {props.children ? props.children : "This sidebar has no items"}
    </aside>
  );
}

export default LegacyPageSidebar;