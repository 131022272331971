import PageHeader from 'components/organisms/PageHeader';
import './style.scss';
import CognyAPI from 'components/_classes/CognyAPI';
import { useEffect, useState } from 'react';
import LoadingDots from 'components/atoms/LoadingDots';
import { useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function Billing(props) {
  const [warehouse, setWarehouse] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [billingData, setBillingData] = useState({});
  const [billingError, setBillingError] = useState(null);
  const [billingIsLoaded, setBillingIsLoaded] = useState(false);

  const params = useParams();
  const warehouseId = params.warehouse_id;
  const navigate = useNavigate();
  const [cookies] = useCookies(["token", "project"]);

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouse(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            if (result.status === 'ReadyForSetup') {
              navigate('/' + warehouseId + '/setup');
            } else {
              if (result.plan === 'Small') {
                result.included_credits = 900;
              } else if (result.plan === 'Medium') {
                result.included_credits = 2990;
              } else if (result.plan === 'Large') {
                result.included_credits = 6655;
              }
              setWarehouse(result);
              setIsLoaded(true);
            }
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
      setIsLoaded(false);
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, warehouseId])

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getBilling(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setBillingData(result);
            setBillingIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setBillingIsLoaded(true);
            setBillingError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  if (billingError) {
    console.log(billingError);
    return <div>Error: {billingError.message}</div>;
  }

  if (!billingIsLoaded) return <LoadingDots />;

  return (
    <div className="Billing">
      <PageHeader pretitle="New account" title="Billing" />

      <div className="Billing__options">
        {warehouse.status === 'New' ?
          <stripe-pricing-table
            pricing-table-id={billingData.p_table}
            publishable-key={billingData.skey}
            client-reference-id={warehouseId}>
          </stripe-pricing-table>
          :
          <>You have already set up your billing. Go to Account settings for more settings.</>
        }
      </div>
    </div>
  );
}

export default Billing;
