import React from 'react';
import Button from 'components/atoms/Button';
import config from './config';
import './style.scss';
import { useGoogleLogin } from '@react-oauth/google';

function FieldRow({ children }) {
  return <div className="FieldRow">{children}</div>;
}

const LoginButton = ({ onLoginSuccess, onLogout, isLoggedIn, user }) => {
  const login = useGoogleLogin({
    onSuccess: onLoginSuccess,
    onError: () => console.log('Login Failed'),
    scope: config.apiScopes,
    clientId: config.googleClientId,
  });

  return (
    <>
      {isLoggedIn ? (
        <div className="LoggedInInfo">
          <span className="LoggedInInfo__text">Logged in as:</span>
          <img src={user.picture} alt="Avatar" className="LoggedInInfo__avatar" />
          <span className="LoggedInInfo__email">{user.email}</span>
          <Button className="SSGTMGuide__google" icon="arrowRight" iconSide="right" onClick={onLogout}>Logout</Button>
        </div>
      ) : (
        <div className="SignInInfo">
          <span className="LoggedInInfo__text">Sign in with your Google Tag Manager admin account:</span>
          <Button className="SSGTMGuide__google" icon="arrowRight" iconSide="right" onClick={() => login()}>
            Sign in with Google
          </Button>
        </div>
      )}
    </>
  );
};

export default LoginButton;
