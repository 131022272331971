import SyntaxHighlighter from 'react-syntax-highlighter';
import './style.scss';
import { agate } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Button from 'components/atoms/Button';
import { useEffect, useState } from 'react';

function Code(props) {
  const { language, className, style, children } = props;
  const [showMessage, setShowMessage] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(children);
    setShowMessage(true);
  }

  const hideMessage = () => {
    setShowMessage(false);
  }

  useEffect(() => {
    let timer;
    if (showMessage) {
      timer = setTimeout(hideMessage, 2000);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMessage]);

  return (
    <div className="Code">
      {
        showMessage ?
          <Button
            className="Code__copy Code__copy--success"
            label="Code copied!"
            icon="check"
          />
          :
          <Button
            className="Code__copy"
            onClick={handleCopy}
            icon="copy"
            label="Copy"
          />
      }
      <div className="Code__content">
        <SyntaxHighlighter
          className={className}
          language={language}
          style={style ?? agate}
        >
          {children}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}

export default Code;