import "./style.scss";
import ErrorBox from "components/molecules/ErrorBox";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Button from "components/atoms/Button";
import { useAppData } from "hooks/useAppData";

function NotFound(props) {
  const [reportError, setReportError] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const { getPrePath } = useAppData();

  if (reportError) {
    return (
      <div className="NotFound">
        <ErrorBox
          pretitle="Page not found"
          title="Report an error"
          description="Please provide us with more information about how you reached this page."
          buttonText="Report"
        />
      </div>
    );
  }

  return (
    <div className="NotFound">
      <h1 className="NotFound__title">Page not found</h1>
      <div className="NotFound__content">
        <div className="NotFound__intro">
          You tried to access <span className="NotFound__path">{path}</span> which doesn't exist
        </div>

        <div className="NotFound__option">
          <div>If you have found a link that leads here, please report it</div>
          <Button onClick={() => setReportError(true)}>Open error report</Button>
        </div>

        <div className="NotFound__option">
          <div>Or if you just want to keep going</div>
          <Button type="link" href={getPrePath()}>Go back to the beginning</Button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;