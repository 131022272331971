import React, { createContext, useState, useContext, useEffect } from 'react';

const OutsideContext = createContext();

export function OutsideProvider({ children }) {
  const [outsideData, setOutsideData] = useState({});

  const getOutsideBackgroundImage = () => {
    return outsideData.backgroundImage
  }

  const setOutsideBackgroundImage = (url) => {
    const urlStr = typeof url === "string" ? url : "";

    setOutsideData(currentState => ({
      ...currentState,
      backgroundImage: urlStr
    }))
  }

  const getOutsideBackgroundStyle = () => {
    return outsideData.backgroundStyle
  }

  const setOutsideBackgroundStyle = (styles) => {
    const stylesObject = typeof styles === "object" ? styles : {};

    setOutsideData(currentState => ({
      ...currentState,
      backgroundStyle: stylesObject
    }))
  }

  const options = {
    outsideData,
    getOutsideBackgroundImage,
    setOutsideBackgroundImage,
    getOutsideBackgroundStyle,
    setOutsideBackgroundStyle
  };

  return (
    <OutsideContext.Provider value={options}>
      {children}
    </OutsideContext.Provider>
  );
}

export function useOutsideData() {
  const context = useContext(OutsideContext);
  if (!context) {
    throw new Error("useOutsideData must be used within an OutsideProvider");
  }
  return context;
}
