import './style.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import { useParams } from 'react-router-dom';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import SubmitStatus from 'components/molecules/SubmitStatus';
import LoadingDots from 'components/atoms/LoadingDots';
import BackLink from 'components/molecules/BackLink';
import Header from 'components/molecules/Header';
import Field from 'components/molecules/Field';
import { useCookies } from 'react-cookie';
import { useAppData } from 'hooks/useAppData';

function FieldRow(props) {
  return (
    <div className="FieldRow">
      {props.children}
    </div>
  );
}

const DeleteButton = (props) => {
  const { isLoaded, error } = props;
  return (
    <>
      <Button type="submit" className="SSGTMDetails__delete SSGTMEditor__delete" disabled={!isLoaded}>Delete server-side tracker</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="SSGTMDetails" successMessage="" />
    </>
  )
}

const SubmitButton = (props) => {
  const { isLoaded, error } = props;
  return (
    <>
      <Button type="submit" className="SSGTMDetails__submit" icon="arrowRight" iconSide="right" disabled={!isLoaded}>Update server-side tracker</Button>
      <SubmitStatus isLoaded={isLoaded} error={error} parentClass="SSGTMDetails" successMessage="" />
    </>
  )
}

function SSGTMDetails(props) {
  const [task, setTask] = useState({});
  const [, setSize] = useState('small');
  const [, setAnonymizeIp] = useState('no');

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [, setValidation] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [cookies] = useCookies(["token", "project"]);
  const nameValidation = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
  const gtmCodeValidation = /^.+$/;
  const warehouseId = params.warehouse_id;
  const id = params.id;
  const { getPath } = useAppData();

  const validateTask = (task) => {
    return nameValidation.test(task.host) &&
      nameValidation.test(task.preview_host) &&
      gtmCodeValidation.test(task.gtm_code);
  }

  const handleDelete = (e) => {
    e.preventDefault();

    setValidation(true);
    setIsLoaded(false);

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      if (window.dataLayer) {
        window.dataLayer.push({ event: "delete_ssgtm", token: cookies.token });
      }
      api.deleteSSGTM(warehouseId, task.id)
        .then(
          (taskResult) => {
            setIsLoaded(true);
            navigate(getPath("server-side-gtm"));
          },
          (error) => {
            setIsLoaded(true);
            console.log(error);
            setError(error);
          }
        )
    }
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    setValidation(true);

    if (!validateTask(task)) {
      return;
    }

    setIsLoaded(false);

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      if (window.dataLayer) {
        window.dataLayer.push({ event: "update_ssgtm", token: cookies.token });
      }
      if (task.anonymize_ip === "yes") {
        task.anonymize_ip = true;
      } else {
        task.anonymize_ip = false;
      }
      if (task.schema_data === "") {
        task.schema_data = [];
      }

      api.updateSSGTM(warehouseId, task)
        .then(
          (taskResult) => {
            setIsLoaded(true);
            setTask(taskState => ({
              ...taskState,
              ...taskResult
            }));

            navigate(getPath("server-side-gtm"));
          },
          (error) => {
            setIsLoaded(true);
            console.log(error);
            setError(error);
          }
        )
    }
  }

  const handleChange = (e) => {
    var idmap = {
      "SSGTMDetails__host": "host",
      "SSGTMDetails__preview_host": "preview_host",
      "SSGTMDetails__gtm_code": "gtm_code",
      "SSGTMDetails__size": "size",
      "SSGTMDetails__anon": "anonymize_ip",
    };

    if (e.target && idmap[e.target.id]) {
      setTask(taskState => {
        var narr = { ...taskState };
        narr[idmap[e.target.id]] = e.target.value;
        return narr;
      });
    } else if (e.description === "Anonymize IP") {
      setTask(taskState => {
        var narr = { ...taskState };
        narr['anonymize_ip'] = e.value;
        return narr;
      });
    } else {
      setTask(taskState => {
        var narr = { ...taskState };
        narr['size'] = e.value;
        return narr;
      });
      setSize(e.value);
    }
  };

  const Validation = (props) => {
    if (props.id === 'name') {
      if (!nameValidation.test(task.name)) {
        return "Name must only containg lower case letters (a-z) and/or _ or numbers";
      }
      if (task.type === 'google_sheets' && task.name === '') {
        return "Table name is required for Google Sheets data sources";
      }
    }
    return "";
  };

  var sizes = [
    { id: 'micro', name: 'Micro', description: 'Up to 400 000 monthly events', price: "200" },
    { id: 'small', name: 'Small', description: 'Up to 4 000 000 monthly events', price: "900" },
    { id: 'medium', name: 'Medium', description: 'Up to 40 000 000 monthly events', price: "2 990" },
    { id: 'large', name: 'Large', description: 'More than 500 000 000 monthly events', price: "6 655" },
    { id: 'xlarge', name: 'XLarge', description: 'Contact us tom@cogny.com', price: "custom" },
  ];

  const options = sizes.map(c => {
    return { value: c.id, label: c.name, description: c.description };
  });

  var yesno = [
    { id: 'yes', name: 'Yes', description: "Anonymize IP" },
    { id: 'no', name: 'No', description: "Anonymize IP" },
  ];

  const options_yes_no = yesno.map(c => {
    return { value: c.id, label: c.name, description: c.description };
  });

  useEffect(() => {
    let mounted = true;
    const api = new CognyAPI(cookies.token);
    api.getSSGTM(warehouseId, id)
      .then(
        (result) => {
          if (!mounted) return;
          if (result.schema_data === "" || result.schema_data === null || typeof result.schema_data === "undefined") {
            result.schema_data = [];
          }

          if (result.anonymize_ip) {
            result.anonymize_ip = 'yes';
          } else {
            result.anonymize_ip = 'no';
          }
          setAnonymizeIp(result.anonymize_ip);
          setSize(result.size);
          setTask(result);
          setIsLoaded(true);
        },
        (error) => {
          if (!mounted) return;
          setError(error);
          setIsLoaded(true);
        }
      );
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, cookies.project, cookies.token]);

  var showAddCustom = false;

  const addStandardHashing = (e) => {
    var randomString = function (length) {
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    }

    task.schema_data.push({ name: "cid", type: "param", action: "hash", salt: randomString(32) });
    task.schema_data.push({ name: "FPID", type: "cookie", action: "hash", salt: randomString(32) });
    task.schema_data.push({ name: "FPLC", type: "cookie", action: "hash", salt: randomString(32) });
    task.schema_data.push({ name: "_ga", type: "cookie", action: "hash", salt: randomString(32) });

    setTask(taskState => ({
      ...taskState,
      ...task
    }));
  };


  return (
    <>
      <Header>
        <BackLink to={getPath("server-side-gtm")} />
      </Header>

      <div className="SSGTMDetails">
        <h1 className="SSGTMDetails__title">{task.host}</h1>
        <Form className="SSGTMDetails__form" onSubmit={handleSubmit}>
          <FieldRow>
            <Field
              type="text"
              id="SSGTMDetails__host"
              className="SSGTMDetails__host"
              name="Host"
              value={task.host ? task.host : ''}
              disabled={true}
              onChange={handleChange}
              validation={<Validation id="host" />}
              placeholder="gtm.yourdomain.com"
            />
          </FieldRow>

          <FieldRow>
            <Field
              type="text"
              id="SSGTMDetails__gtm_code"
              className="SSGTMDetails__gtm_code"
              name="Container configuration"
              value={task.gtm_code ? task.gtm_code : ''}
              onChange={handleChange}
              disabled={true}
              validation={<Validation id="gtm_code" />}
              placeholder="aWXXXXYYYYYZZZZZZZZZZ.."
            />
          </FieldRow>

          <table>
            <thead>
              <tr>
                <th>Size</th>
                <th>Recommended for sites</th>
                <th>Cogny credits used monthly</th>
              </tr>
            </thead>
            <tbody>
              {sizes.map(c => {
                return (
                  <tr key={c.id}>
                    <td>{c.name}</td>
                    <td>{c.description}</td>
                    <td>{c.price}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <FieldRow>
            <Field
              type="select"
              id="SSGTMDetails__size"
              className="SSGTMDetails__size"
              name="Size"
              options={options}
              value={options.filter(item => item.value === task.size)[0] ? options.filter(item => item.value === task.size)[0] : options[0]}
              onChange={
                (value) => {
                  setTask(currentState => ({
                    ...currentState,
                    size: value.value
                  }));
                }
              }
              classNamePrefix="Select"
              placeholder="Select size..."
            />
          </FieldRow>

          <FieldRow>
            <Field
              type="select"
              id="SSGTMDetails__anon"
              className="SSGTMDetails__anon"
              name="Anonymize IP"
              options={options_yes_no}
              value={options_yes_no.filter(item => item.value === task.anonymize_ip)[0] ? options_yes_no.filter(item => item.value === task.anonymize_ip)[0] : options_yes_no[0]}
              onChange={
                (value) => {
                  setTask(currentState => ({
                    ...currentState,
                    anonymize_ip: value.value
                  }));
                }
              }
              classNamePrefix="Select"
              placeholder="Anonomize users IP address prior to sending to GTM?"
            />
          </FieldRow>

          { /*
          type FieldConfig struct {
	Name   string `json:"name"`
	Type   string `json:"type"` // cookie or params
	Action string `json:"action"` // drop or hash
	Salt   string `json:"salt"` // only used for hashing
          */ }

          <Button
            className="SSGTMDetails__submit"
            onClick={addStandardHashing}
          >
            Add standard hashing rules
          </Button>
          { /*
          <Button
            className="SSGTMDetails__submit"
            disabled={task.name === '' || task.size === ''}
            onClick={function() {showAddCustom = true}}
          >
            Add custom hashing rules
        </Button> */ }

          {showAddCustom ?
            <>
              <h3>Add custom hasing rule</h3>
              <FieldRow>
                <Field
                  type="text"
                  id="SSGTMDetails__name"
                  className="SSGTMDetails__name"
                  name="Name"
                  value={task.name ? task.name : ''}
                  onChange={handleChange}
                  validation={<Validation id="name" />}
                  placeholder="Name"
                />
              </FieldRow>
              <FieldRow>
                <Field
                  type="select"
                  id="SSGTMDetails__type"
                  className="SSGTMDetails__type"
                  name="Type"
                  options={options}
                  onChange={handleChange}
                  classNamePrefix="Select"
                  placeholder="Select type..."
                />
              </FieldRow>
              <FieldRow>
                <Field
                  type="select"
                  id="SSGTMDetails__action"
                  className="SSGTMDetails__action"
                  name="Action"
                  options={options}
                  onChange={handleChange}
                  classNamePrefix="Select"
                  placeholder="Select action..."
                />
              </FieldRow>
              <FieldRow>
                <Field
                  type="text"
                  id="SSGTMDetails__salt"
                  className="SSGTMDetails__salt"
                  name="Salt"
                  value={task.salt ? task.salt : ''}
                  onChange={handleChange}
                  validation={<Validation id="salt" />}
                  placeholder="Salt"
                />
              </FieldRow>

              <Button
                type="submit"
                className="SSGTMDetails__submit"
                disabled={!task.host || !task.gtm_code || !task.size || !task.anon}
              >
                Add Rule
              </Button>
            </>
            : false
          }

          <h3>Hashing rules</h3>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Action</th>
                <th>Salt</th>
              </tr>
            </thead>
            <tbody>
              {task && task.schema_data ? task.schema_data.map(c => {
                return (
                  <tr key={c.name}>
                    <td>{c.name}</td>
                    <td>{c.type}</td>
                    <td>{c.action}</td>
                    <td>{c.salt}</td>
                  </tr>
                );
              }) : false}
            </tbody>
          </table>

          <SubmitButton isLoaded={isLoaded} error={error} />
        </Form>
        <Form className="SSGTMDetails__form" onSubmit={handleDelete}>
          <DeleteButton isLoaded={isLoaded} error={error} />
        </Form>
        {isLoaded ? "" : <LoadingDots />}
        {error ? <div>{error.message}</div> : ""}
      </div>
    </>
  )
}

export default SSGTMDetails;
