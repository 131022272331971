import { useAppData } from 'hooks/useAppData';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import validateAccountId from 'utils/validateAccountId';

const ValidateWarehouseId = (props) => {
  const { children, failed } = props;
  const { warehouse_id } = useParams();
  const { getPath, setPath, resetPath } = useAppData();
  const warehousePath = warehouse_id + "/";

  const validateWarehouseId = validateAccountId(warehouse_id);

  useEffect(() => {
    if (validateWarehouseId) {
      setPath(warehousePath);
    } else {
      resetPath();
    }

    return () => {
      resetPath();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateWarehouseId, warehousePath]);

  if (!validateWarehouseId) return failed;
  if (getPath() === null) {
    return "";
  } else {
    return children;
  }
};

export default ValidateWarehouseId;