const questions = [
  {
    question: "Vad är server-side taggning?",
    answer: `
Server-side taggning innebär att samla in och bearbeta data på en server, snarare än direkt i användarens webbläsare (client-sidan). Denna metod flyttar mycket av databearbetningen från clienten till en servermiljö, vilket erbjuder större kontroll över datahantering, integritet och webbplatsens hastighet.
`,
  },
  {
    question: "Hur sätter jag upp server-side taggning?",
    answer: `
För att ställa in server-side taggning behöver du en server-side taggningsbehållare (som Google Tag Managers server-side version). Processen innefattar generellt:

1. Sätt upp en server med hjälp av cogny.com
2. Skapa en server-side behållare i ditt tagghanteringssystem (GTM)
3. I **Behållarinställningar**, namnge din nya behållare och välj Server. Klicka på Skapa. När behållaren har skapats kommer du att se inställningar.
4. Klicka på Tillhandahåll taggningsservern manuellt
5. Kopiera behållarkonfiguration
6. Klistra in behållarkonfigurationen i Cognys installationsguide för Server-side spårning
7. Följ installationsinstruktionerna för anpassad subdomän i Cogny
8. Lägg till följande DNS-poster till din domän
    * Som en A-post pekande till 65.21.216.240
    * Klicka på verifiera
    * Klart
9. Konfigurera dataströmmar för att skicka data till denna behållare
10. Implementera taggar inom server-side miljön för att hantera den inkommande datan
`,
  },
  {
    question: "Är server-side taggning GDPR-kompatibel?",
    answer: `
Server-side taggning kan förbättra GDPR-kompatibilitet genom att erbjuda mer kontroll över datainsamling och bearbetning. Det tillåter anonymisering och kontroll av användardata innan den skickas till tredjepartstjänster, vilket potentiellt minskar risken för integritetsbrott. Dock beror GDPR-kompatibilitet också på andra faktorer som datamedgivande och hur data hanteras och lagras.
`,
  },
  {
    question: "Varför använda server-side spårning?",
    answer: `
Server-side spårning erbjuder flera fördelar:
* Förbättrad datasekretess och säkerhet
* Minskad belastning på webbläsaren, vilket leder till bättre webbplats-, SEO- och konverteringsprestanda
* Förbättrad datakorrekthet, eftersom den är mindre känslig för adblockers
* Större kontroll över databehandling och hantering
`,
  },
  {
    question: "Är server-side taggning säkrare?",
    answer: `
Ja, server-side taggning är generellt säkrare än client-side taggning. Den erbjuder bättre kontroll över data, vilket tillåter dig att anonymisera och säkra data innan den når tredjepartstjänster.
`,
  },
  {
    question: "Vad är skillnaden mellan server-side och client-side taggning?",
    answer: `
Client-side taggning innebär att samla in data direkt i användarens webbläsare och skicka den till olika analys- och marknadsföringsplattformar. Server-side taggning, å andra sidan, skickar denna data till en server först, där den kan bearbetas, filtreras eller modifieras innan den skickas till tredjepartstjänster.
`,
  },
  {
    question: "Kan en sida ha endast en server-side tagg?",
    answer: `
Tekniskt sett kan en sida ha bara en server-side tagg, men detta begränsar flexibiliteten och kapaciteten i din spårningsuppsättning. Flera taggar kan användas för olika syften, som att spåra olika användarinteraktioner eller skicka data till olika analysplattformar.
`,
  },
  {
    question: "Vilka är nackdelarna med client-side taggning?",
    answer: `
Client-side taggning har vissa nackdelar, exempelvis:
* Ökad risk att bli blockad av adblockers
* Potentiellt långsammare sidladdningstider på grund av många tredjepartsskript
* Högre risk för databrott eller läckage
* Mindre kontroll över databehandling och insamling
`,
  },
  {
    question: "Vad är Google Tag Manager server-side?",
    answer: `
Google Tag Manager (GTM) server-side är en version av GTM som tillåter dig att flytta mycket av din datainsamling och bearbetning från client-sidan (användarens webbläsare) till en server. Den ger bättre kontroll över datahantering och kan förbättra webbplatsens prestanda och datasekretess.
`,
  },
  {
    question: "Är GTM server-side gratis?",
    answer: `
Google Tag Managers standardversion är gratis, inklusive dess server-side taggningsfunktioner. Dock kostar det att hosta server-side GTM. Google erbjuder Google cloud platform som standard men det är mer kostnadseffektivt att använda tex cogny.com som är ca 25% billigare och är enklare att sätta upp och drifta.
`,
  },
  {
    question: "Hur mycket kostar Google Tag Manager server-side?",
    answer: `
Även om GTM server-side i sig är gratis, kommer kostnaden från servern du använder för att köra din server-side behållare. Om du använder Google Cloud, till exempel, beror kostnaderna på de resurser du använder, som datorkraft och lagring av data. En "liten" Google Cloud-server kostar ungefär 140 USD per månad medan motsvarigheten på cogny.com endast kostar 99 EUR per månad, cirka 25% billigare. Cogny sköter all installation och underhåll, Cogny erbjuder också anonymisering av IP och Cookie-ID gratis.
`,
  },
  {
    question: "Bör jag använda server-side taggning?",
    answer: `
Server-side taggning är värt att överväga om:
* Du vill ha mer kontroll och säkerhet över din datainsamling och bearbetning
* Din webbplats hanterar känslig användardata och du strävar efter högre efterlevnadsstandarder som GDPR
* Du vill förbättra webbplatsens prestanda genom att minska client-sidans belastning
* Du behöver mer sofistikerade databehandlingsfunktioner
* Dock bör du utvärdera dina specifika behov, resurser och tekniska förmågor innan du bestämmer dig. Server-side taggning kan vara lite mer komplex att ställa in och hantera än client-side taggning.
`,
  },
];

export default questions;
