import './style.scss';
import Button from 'components/atoms/Button';
import CTAHero from 'outside/components/CTAHero';
import { useLocation } from 'react-router-dom';

function SignedOut() {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/app" } };
  const { hasReturnUrl } = location.state || { hasReturnUrl: false };

  const ignoreReturnUrls = ["/app", "/app/home"];
  const pathname = from.pathname.replace(/\/$/, "");
  const returnToParam = ignoreReturnUrls.includes(pathname) ? "" : `?return_to=${from.pathname}`;
  const SignedOutUrl = `/login` + returnToParam;

  return (
    <div className="SignedOut">
      <CTAHero
        title="You're signed out"
        tagline={
          <>
            You're not signed in yet.<br />
            Let's take care of that right now.
          </>
        }
        button={
          <>
            <Button
              type="link"
              href={SignedOutUrl}
              className="SignedOut__button"
            >
              Sign in to Cogny
            </Button>
            {hasReturnUrl && returnToParam !== "" &&
              <div
                style={{
                  marginTop: 40,
                  padding: "24px 32px",
                  backgroundColor: "rgba(0,0,0,.2)",
                  border: "1px solid rgba(255,255,255,.12)",
                  color: "rgba(255,255,255,.8)",
                  borderRadius: 8,
                }}
              >
                <div>After signing in again you will be redirected to:</div>
                <div style={{ fontFamily: "monospace" }}>{from.pathname}</div>
              </div>
            }
          </>
        }
      />
    </div>
  );
}

export default SignedOut;
