import './style.scss';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Select from 'react-select';
import Textarea from 'components/atoms/Textarea';

const FieldType = (props) => {
  const { type, className, classNamePrefix, id, value, disabled, onChange, onFocus, options, placeholder, defaultValue, components, rows, submit, setRef } = props;

  if (type === "text" || type === "email") {
    return (
      <Input
        className={className}
        name={id}
        id={id}
        type={type}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={onFocus}
      />
    );
  } else if (type === "textarea") {
    return (
      <Textarea
        className={className}
        name={id}
        id={id}
        rows={rows}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={onFocus}
      >{value}</Textarea>
    );
  } else if (type === "dynamictextarea") {
    return (
      <Textarea
        className={className}
        name={id}
        id={id}
        rows={rows}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        dynamic
        submit={submit}
        setRef={setRef}
      >{value}</Textarea>
    );
  } else if (type === "select") {
    if (!options) return false;
    return (
      <Select
        id={id}
        className={"Select " + className}
        classNamePrefix={classNamePrefix}
        options={options}
        placeholder={placeholder}
        isSearchable
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        components={components}
        menuPosition="fixed"
      />
    );
  } else if (type === "multiselect") {
    if (!options) return false;
    return (
      <Select
        id={id}
        className={"Select " + className}
        classNamePrefix={classNamePrefix}
        options={options}
        placeholder={placeholder}
        isMulti
        isClearable
        isSearchable
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        components={components}
        menuPosition="fixed"
      />
    );
  } else {
    return false;
  }
}

function Field(props) {
  const { className = "", classNamePrefix = "Field" } = props;

  return (
    <div className={"Field " + className}>
      <Label
        className={classNamePrefix + "__label"}
        htmlFor={props.id}
        label={props.name}
      />
      <FieldType
        id={props.id}
        className={classNamePrefix + "__field"}
        classNamePrefix={classNamePrefix}
        name={props.name}
        type={props.type}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
        onFocus={props.onFocus}
        options={props.options}
        placeholder={props.placeholder}
        components={props.components}
        defaultValue={props.defaultValue}
        rows={props.rows}
        submit={props.submit}
        setRef={props.setRef}
      />
      {props.validation}
    </div>);
}

export default Field;