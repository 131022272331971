import './style.scss';
import ReactMarkdown from 'react-markdown';

const BlogPost = (props) => {
  const { post } = props;

  return (
    <div className="BlogPost">
      {post.description && (
        <div className="BlogPost__description">{post.description}</div>
      )}

      {post.body && (
        <div className="BlogPost__body">
          <ReactMarkdown>{post.body}</ReactMarkdown>
        </div>
      )}
    </div>
  );
}

export default BlogPost;
