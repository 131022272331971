import './style.scss';
import Icon from 'components/atoms/Icon';
import { Link } from 'react-router-dom';

function Button(props) {
  const {
    icon,
    iconSide = "left",
    iconLeft,
    iconRight,
    type = "button",
    href = "#",
    className = "",
    onClick,
    label,
    children,
    disabled,
    variant = "default",
    title = "",
    target,
    rel,
    noLabel,
    theme,
  } = props;
  const text = label || children;

  let buttonClass = "Button ";
  buttonClass = buttonClass + className;
  //buttonClass = buttonClass + (iconSide ? " Button--icon-" + iconSide : "");
  buttonClass = buttonClass + (noLabel ? " Button--noLabel" : "");
  buttonClass = buttonClass + (theme === "dark" ? " Button--dark" : "");

  const ButtonLabel = (props) => {
    if (noLabel) return "";
    const { icon, iconSide, iconLeft, iconRight } = props.icon;

    return (
      <>
        {icon && iconSide === 'left' && (<Icon icon={icon} />)}
        {iconLeft && (<Icon icon={iconLeft} />)}
        <span className="Button__label">{text}</span>
        {icon && iconSide === 'right' && (<Icon icon={icon} />)}
        {iconRight && (<Icon icon={iconRight} />)}
      </>
    );
  }

  if (type === 'htmllink') {
    return (
      <a
        href={href}
        className={buttonClass}
        onClick={onClick}
        rel={rel}
        target={target}
      >
        <ButtonLabel icon={{ icon, iconSide, iconLeft, iconRight }} />
      </a>
    );
  }

  if (type === 'link') {
    return (
      <Link
        to={href}
        className={buttonClass}
        onClick={onClick}
      >
        <ButtonLabel icon={{ icon, iconSide, iconLeft, iconRight }} />
      </Link>
    );
  }

  buttonClass = buttonClass + (variant === "danger" ? " Button--danger" : "");

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      type={type}
      disabled={disabled}
      title={title}
    >
      <ButtonLabel icon={{ icon, iconSide, iconLeft, iconRight }} />
    </button>
  );
}

export default Button;
