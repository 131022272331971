/**
 * Function to validate an account id
 * 
 * @param {string} accountId 
 * @returns {boolean} isValidId
 */

const validateAccountId = (accountId) => {
  const numericPattern = /^\d+$/;
  const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  const tests = [
    numericPattern,
    uuidPattern
  ];

  const isValidId = tests.some((pattern) => pattern.test(accountId));

  return isValidId;
}

export default validateAccountId;