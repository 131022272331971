import './style.scss';
import { useEffect, useState } from 'react';
import Button from 'components/atoms/Button';
import Form from 'components/atoms/Form';
import Field from 'components/molecules/Field';
import LoadingDots from 'components/atoms/LoadingDots';
import CognyAPI from 'components/_classes/CognyAPI';
import { useCookies } from 'react-cookie';
import { useAppData } from 'hooks/useAppData';

function sanitizeInput(input) {
  const map = {
    '<': '&lt;',
    '>': '&gt;',
    '&': '&amp;',
    '"': '&quot;',
    "'": '&#x27;',
    "/": '&#x2F;',
  };
  // eslint-disable-next-line no-useless-escape
  const reg = /[<>&"'\/]/ig;
  return input.replace(reg, (match) => (map[match]));
}

function FieldRow(props) {
  return (
    <div className="FieldRow">
      {props.children}
    </div>
  );
}

const ErrorTitle = (props) => {
  const { pretitle, title } = props;
  return (
    <div className="ErrorTitle">
      {pretitle &&
        <div className="ErrorTitle__pretitle">{pretitle}</div>
      }

      {title &&
        <h2 className="ErrorTitle__title">{title}</h2>
      }
    </div>
  );
}

const ErrorRow = (props) => {
  const { name, value } = props;

  return (
    <div className="ErrorRow">
      <div className="ErrorRow__name">{name}:</div>
      <div className="ErrorRow__value">{value}</div>
    </div>
  );
}

const ErrorForm = (props) => {
  const { done, data, modal } = props;
  const { message, url, timestamp } = data;

  const [form, setForm] = useState({ title: false, body: false });
  const updateForm = (newState) => {
    setForm(currentState => ({ ...currentState, ...newState }));
  }
  const [formError, setFormError] = useState(null);
  const [formIsLoaded, setFormIsLoaded] = useState(true);

  // const modalState = props.state ? props.state.modalState : undefined;
  const updateModalState = props.state ? props.state.updateModalState : undefined;

  const [cookies] = useCookies(["token", "project"]);

  const validateForm = () => {
    let valid = true;
    if (!form.title) {
      updateForm({ title: "" })
      valid = false;
    }

    if (!form.body) {
      updateForm({ body: "" })
      valid = false;
    }

    return valid;
  }

  useEffect(() => {
    if (!props.autoTitle) return;

    if (message && timestamp) {
      updateForm({ title: `"${message}" - ${timestamp}` })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (e, formData) => {
    e?.preventDefault();

    if (!validateForm()) return;

    setFormIsLoaded(false);

    const formattedBody = `Error message:
${message}

Timestamp:
${timestamp}

URL:
${url}

User agent:
${navigator?.userAgent}

Report title:
${sanitizeInput(form.title)}

Report message:
${sanitizeInput(form.body)}
`;

    const sanitizedInputs = {
      title: sanitizeInput(form.title),
      body: formattedBody,
    };

    //send form data to api
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.reportBug(sanitizedInputs)
        .then(
          (result) => {
            done(true);
            setFormIsLoaded(true);
            setForm({ title: false, body: false });
          },
          (error) => {
            console.log(error);
            setFormError(error);
            done(false);
            setFormIsLoaded(true);
          }
        )
    }
  }

  useEffect(() => {
    if (updateModalState) {
      const primary = {
        label: "Submit",
        onClick: (event) => handleSubmit(event, form),
      }
      updateModalState({ primary })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  useEffect(() => {
    if (updateModalState) {
      const formData = {
        data: form,
        isLoaded: formIsLoaded,
        error: formError,
      };
      updateModalState({ form: formData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formIsLoaded, formError])

  const Validation = (props) => {
    const { className, rules, message, subject } = props;

    if (!subject && subject !== "") return;

    if (form !== "" && rules) {
      return <div className={"Validation " + className}>{message}</div>;
    }

    return "";
  };

  return (
    <Form onSubmit={(event) => handleSubmit(event, form)} className="ErrorBox__form">
      <FieldRow>
        <Field
          type="text"
          id="ErrorBox__reportTitle"
          className="ErrorBox__reportTitle"
          name="Title"
          value={form.title ? form.title : ""}
          onChange={(e) => {
            setForm(currentState => ({
              ...currentState,
              title: e.target.value,
            }));
          }}
          validation={<Validation subject={form.title} rules={!form.title.length} message="You need to enter a title" />}
          placeholder="Enter title here"
        />
      </FieldRow>

      <FieldRow>
        <Field
          type="textarea"
          id="ErrorBox__reportBody"
          className="ErrorBox__reportBody"
          name="Message"
          value={form.body ? form.body : ""}
          onChange={(e) => {
            setForm(currentState => ({
              ...currentState,
              body: e.target.value,
            }));
          }}
          validation={<Validation subject={form.body} rules={!form.body.length} message="You need to enter a message" />}
          placeholder="Enter message here"
        />
      </FieldRow>

      {!modal &&
        <FieldRow>
          {formIsLoaded ?
            <Button
              type="submit"
              className="ErrorBox__submit"
              icon="arrowRight"
              iconSide="right"
              disabled={
                form.title === "" || form.body === ""
              }
            >
              Send report
            </Button>
            :
            <Button type="submit" className="ErrorBox__submit" disabled>
              <LoadingDots />
            </Button>
          }
        </FieldRow>
      }
    </Form>
  );
};

function ErrorBox(props) {
  const modalState = props.state ? props.state.modalState : undefined;
  const updateModalState = props.state ? props.state.updateModalState : undefined;

  // Local state as fallback
  const [localSent, setLocalSent] = useState(false);

  // Determine which state to use (context state or local state)
  const sent = modalState?.sent !== undefined ? modalState.sent : localSent;
  const setSent = updateModalState ? (value) => updateModalState({ sent: value }) : setLocalSent;

  const { error, noDetails, modal } = props;
  const [showForm, setShowForm] = useState(false);
  const [errorData, setErrorData] = useState({ message: "", timestamp: "", url: "" });

  const { getPath } = useAppData();

  const handleClick = (e) => {
    e.preventDefault();

    setShowForm(currentState => !currentState);
  }

  useEffect(() => {
    const errorMessage = error?.message ? error.message : "No error message";

    setErrorData(currentState => ({
      ...currentState,
      message: errorMessage
    }))

    const currentTimestamp = new Date();
    const isoString = currentTimestamp.toISOString();
    setErrorData(currentState => ({
      ...currentState,
      timestamp: isoString
    }))

    const currentUrl = window.location.href;
    setErrorData(currentState => ({
      ...currentState,
      url: currentUrl
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const ErrorRows = () => {
    return (
      <div className="ErrorBox__rows">
        {errorData.message && <ErrorRow name="Message" value={errorData.message} />}
        {errorData.timestamp && <ErrorRow name="Date and time" value={errorData.timestamp} />}
        {errorData.url && <ErrorRow name="URL" value={errorData.url} />}
      </div>
    );
  }

  const ErrorText = (props) => {
    return (
      <div className="ErrorBox__text">
        {props.children}
      </div>
    );
  }

  if (modal) {
    if (sent) {
      return (
        <div className="ErrorBox">
          <ErrorText>We appreciate your help in making our app better. Our team will look into this issue.</ErrorText>
        </div>
      );
    }

    return (
      <div className="ErrorBox">
        <ErrorText>Your feedback is valuable to us. Please help us by reporting any issues you find in this form.</ErrorText>
        <ErrorForm data={errorData} done={setSent} state={{ modalState, updateModalState }} modal />
      </div>
    );
  }

  if (sent) {
    return (
      <div className="ErrorBox">
        {!modal && <ErrorTitle pretitle="Report an error" title="Your report has been sent" />}
        <ErrorText>We appreciate your help in making our app better. Our team will look into this issue.</ErrorText>
        <Button type="link" href={getPath("dashboard")} className="ErrorBox__back" icon="arrowRight" iconSide="right">Go to Dashboard</Button>
      </div>
    );
  }

  if (noDetails) {
    return (
      <div className="ErrorBox">
        <ErrorTitle pretitle={"Report an issue"} title="Write a report" />
        <ErrorText>Your feedback is valuable to us. Please help us by reporting any issues you find in this form.</ErrorText>
        <ErrorForm data={errorData} done={setSent} />
      </div>
    );
  }

  if (showForm) {
    return (
      <div className="ErrorBox">
        <ErrorTitle pretitle="An error occurred" title="Please try again in a moment" />
        <ErrorRows />
        <ErrorForm data={errorData} done={setSent} autoTitle />
      </div>
    );
  }

  if (props.pretitle && props.title && props.description) {
    return (
      <div className="ErrorBox">
        <ErrorTitle pretitle={props.pretitle} title={props.title} />

        <ErrorText>{props.description}</ErrorText>
        <ErrorRows />
        <ErrorText>Your feedback is valuable to us. Please help improve our service by reporting this issue with additional information.</ErrorText>

        <Button type="button" className="ErrorBox__report" icon="arrowRight" iconSide="right" onClick={handleClick}>{props.buttonText}</Button>
      </div>
    );
  }

  return (
    <div className="ErrorBox">
      <ErrorTitle pretitle="An error occurred" title="Please try again in a moment" />

      <ErrorText>We're sorry, but an error occurred while loading the page. See details below.</ErrorText>
      <ErrorRows />
      <ErrorText>Your feedback is valuable to us. Please help improve our service by reporting this issue with additional information.</ErrorText>

      <Button type="button" className="ErrorBox__report" icon="arrowRight" iconSide="right" onClick={handleClick}>Report this error</Button>
    </div>
  );
}

export default ErrorBox;