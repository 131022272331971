import './style.scss';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';

function Notification(props) {
  if (!props.message) return "";

  const { className = "" } = props;

  return (
    <div className={"Notification " + (props.hasDismiss ? "Notification--hasDismiss" : "") + className}>
      <div className="Notification__content">
        <Icon icon="answered" />
        <span className="Notification__text">{props.message}</span>
      </div>
      <div className="Notification__actions">
        <Button
          onClick={props.onClick}
          className={"Notification__action" + (props.id ? " Notification__action--" + props.id : "")}
          icon="arrowRight"
          iconSide="right"
          label={props.action}
          type={props.buttonType}
          href={props.buttonHref}
        />
        {props.hasDismiss &&
          <Button
            onClick={props.onDismissClick}
            className="Notification__action Notification__action--dismiss"
            icon="cross"
            iconSide="right"
            noLabel
          />
        }
      </div>
    </div>
  );
}

export default Notification;