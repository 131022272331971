import './style.scss';
import TimeSpent from 'outside/components/TimeSpent';
import CTAHero from 'outside/components/CTAHero';
import Customers from 'outside/components/Customers';
import CopilotPreview from 'outside/components/CopilotPreview';
import FAQ, { faqData } from 'outside/components/FAQ';
import Features, { FeaturesIcons } from 'outside/components/Features';
import BookADemo from 'outside/components/BookADemo';
import useBodyClass from 'hooks/useBodyClass';

function Frontpage(props) {
  const lang = props.lang;
  useBodyClass('page-frontpage');

  return (
    <div className="Frontpage">
      <CTAHero
        lang={lang}
        title={
          lang === "sv" ?
            <>Säg hej till din nya dataanalytiker</>
            :
            <>Meet your new data analyst</>
        }
        tagline={
          lang === "sv" ?
            "Kombinera dina datakällor och generera kraftfull analys i tankens hastighet."
            :
            "Combine your data sources and generate powerful analysis at the speed of thought."
        }
      />

      <CopilotPreview
        lang={lang}
      />

      <Customers lang={lang} count="all" />

      <Features
        items={
          lang === "sv" ?
            [
              {
                preTitle: <>En fråga bort</>,
                icon: <FeaturesIcons.Answers className="Features__icon" />,
                title: <>Ingen stel instrumentpanel, bara fråga och få svar</>,
              },
              {
                preTitle: <>Alltid redo</>,
                icon: <FeaturesIcons.Analysis className="Features__icon" />,
                title: <>Din egen dataanalytiker tillgänglig 24/7</>,
              },
              {
                preTitle: <>Framtiden är här</>,
                icon: <FeaturesIcons.AI className="Features__icon" />,
                title: <>Drivs av den senaste AI-teknologin</>,
              }

            ]
            :
            [
              {
                preTitle: <>Right here, right now</>,
                icon: <FeaturesIcons.Answers className="Features__icon" />,
                title: <>No stiff dashboard, just ask and get answers</>,
              },
              {
                preTitle: <>Always at your service</>,
                icon: <FeaturesIcons.Analysis className="Features__icon" />,
                title: <>Your own 24/7 available data analyst</>,
              },
              {
                preTitle: <>It's alive!</>,
                icon: <FeaturesIcons.AI className="Features__icon" />,
                title: <>Powered by latest AI technology</>,
              }
            ]
        }
      />

      <TimeSpent
        title={
          lang === "sv" ?
            "Din nya AI-assistent"
            :
            "Your new AI assistant"
        }
        intro={
          lang === "sv" ?
            <>
              <p>Cogny Copilot är en expert dataanalytiker, dataingenjör och dataforskare i ett. Den hjälper dig att förstå dina data, hitta insikter och fatta bättre beslut snabbare än någonsin.</p>
              <p>Den är alltid tillgänglig och tar aldrig paus. Den är den perfekta assistenten för ditt datarbete.</p>
            </>
            :
            <>
              <p>Cogny Copilot is an expert data analyst, data engineer and data scientist all in one. It helps you to understand your data, find insights and make better decisions faster than ever.</p>
              <p>It is always available and never takes a break. It is the perfect assistant for your data work.</p>
            </>
        }
      />

      <BookADemo
        title={
          lang === "sv" ?
            "Låt oss visa dig"
            :
            "Sounds complicated?"
        }
        text={
          lang === "sv" ?
            "Få hjälp av en av våra tekniska marknadsförare."
            :
            "Get help from one of our technical marketers."
        }
        buttonLabel={
          lang === "sv" ?
            "Få hjälp"
            :
            "Get help"
        }
      />

      <FAQ lang={lang} faq={faqData.front.en} />
    </div>
  );
}

export default Frontpage;
