import React, { createContext, useContext } from 'react';
import { useCookies } from 'react-cookie';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const daysInTheFutureCookieExpires = 180;

  const cookieOptions = {
    path: "/",
    sameSite: "strict",
    secure: true,
  }

  const hasToken = () => !!cookies.token;

  const setToken = (token) => {
    setCookie('token', token, {
      ...cookieOptions,
      expires: new Date(Date.now() + daysInTheFutureCookieExpires * 24 * 60 * 60 * 1000)
    });
  };

  const getToken = () => cookies.token;

  const removeToken = () => {
    removeCookie('token', cookieOptions);
  };

  const contextValue = {
    hasToken,
    setToken,
    getToken,
    removeToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
