import './style.scss';
import BookADemo from 'outside/components/BookADemo';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SubpageHero from 'outside/components/SubpageHero';
import MediaBox from 'outside/components/MediaBox';
import camelcase from 'camelcase';
import useBodyClass from 'hooks/useBodyClass';
import OutsideBox from 'outside/components/OutsideBox';
import Masonry from 'react-masonry-css';

const pages = [
  {
    slug: "ga4",
    title: "Google Analytics 4",
    short_title: "Google Analytics 4",
    subtitle: "Imported into BigQuery using Cogny's data management platform that anyone can run.",
    sections: [
      {
        title: "Get started with BigQuery in just a few clicks",
        subtitle: "Using Cogny's data management platform that anyone can run.",
        video: "1gPiPCnfPak"
      },
      {
        title: "Use your data in Looker Studio",
        subtitle: "Using Cogny's data management platform that anyone can run.",
        video: "Lq0cT2u2rW8"
      },
      {
        title: "Connect your Google Universal Analytics property",
        subtitle: "This way you can build reports comparing with historic data from before you implemented Google Analytics 4, give that you had Google Universal Analytics installed.",
        video: "UyIg5QAMxBQ"
      },
      {
        title: "Avoid these common errors in looker studio",
        subtitle: "BigQuery does not suffer from the reporting quota limits",
        markdown: " * Exhausted concurrent requests quota. Please send fewer requests concurrently."
          + "\n * This property has issued too many requests in the last day."
          + "\n * Too many requests using this Google Analytics property have encountered errors in the last hour."
          + "\n * This property has issued too many requests in the last hour."
          + "\n * This property is denied access to Google Analytics."
          + "\n * This property has issued too many potentially thresholded requests in the last hour."
          + "\n * This project/property has issued too many requests in the last hour."
      }
    ]
  },
  {
    slug: "agency-benefits",
    title: "Benefits for Agencies using Cogny",
    short_title: "Agency Benefits",
    subtitle: "Empower your agency with Cogny's data management platform for streamlined reporting and analysis.",
    sections: [
      {
        title: "Easily collect data in BigQuery",
        subtitle: "Centralize data from Google Analytics 4, Universal Analytics, Google Ads, and Facebook Ads for efficient reporting.",
        markdown: "With Cogny, agencies can effortlessly gather and manage data from various sources, making it simpler to create comprehensive reports for clients."
      },
      {
        title: "Fulfill reporting needs using Looker Studio",
        subtitle: "Create insightful reports for your clients with ease.",
        video: "Lq0cT2u2rW8"
      },
      {
        title: "Access standardized analysis tools",
        subtitle: "Leverage Cogny's Media Mix Modelling and Impact Analysis tools for in-depth insights.",
        markdown: "Cogny's advanced tools enable agencies to have a deeper understanding of campaign performance, helping them make better decisions for their clients."
      },
      {
        title: "Cogny Partner Program for Agencies",
        subtitle: "Join our partner program and enjoy a revenue share for new customers.",
        markdown: "Collaborate more closely with Cogny and benefit from our revenue sharing program when you bring new customers on board. Grow your agency and our partnership together."
      },
      {
        title: "Quickly get started with new clients",
        subtitle: "Onboard new clients seamlessly and kickstart their data journey.",
        video: "1gPiPCnfPak"
      }
    ]
  },
  {
    slug: "gdpr-compliance",
    title: "Achieve GDPR Compliance with Cogny",
    short_title: "GDPR Compliance",
    subtitle: "Centralize and manage your data in a GDPR-compliant manner with hosting in the EU.",
    sections: [
      {
        title: "Data Hosting in the European Union",
        subtitle: "Keep your data secure and compliant by hosting it within EU borders.",
        markdown: "* Benefit from the high level of data protection offered by the European Union.\n* Ensure your data is stored according to GDPR regulations.\n* Minimize the risk of cross-border data transfer issues."
      },
      {
        title: "Privacy by Design",
        subtitle: "Cogny's platform is built with GDPR compliance in mind.",
        markdown: "* Data minimization: we only collect and process necessary data.\n* Encrypted data transfers: secure communication between our platform and third-party services.\n* Pseudonymization and anonymization: protecting personal data from unauthorized access."
      },
      {
        title: "Data Subject Rights Management",
        subtitle: "Easily manage and fulfill data subject rights requests.",
        markdown: "* Access, rectify, and erase data efficiently in response to user requests.\n* Export user data in a machine-readable format for data portability.\n* Maintain records of data processing activities for compliance purposes."
      },
      {
        title: "Data Breach Management",
        subtitle: "Implement effective data breach management protocols.",
        markdown: "* Timely detection and notification of data breaches.\n* Assessment and mitigation of data breach risks.\n* Regular review and improvement of data breach response procedures."
      },
      {
        title: "Partner with GDPR-Compliant Vendors",
        subtitle: "Ensure that your entire data ecosystem is GDPR compliant.",
        markdown: "* Vet and work only with GDPR-compliant third-party providers.\n* Establish data processing agreements with vendors to ensure data protection.\n* Regularly review vendor compliance to maintain a secure data ecosystem."
      }
    ]
  },
  {
    slug: "email-marketing",
    title: "Email Marketing with Cogny",
    short_title: "Email Marketing",
    subtitle: "Leverage centralized data and machine learning to supercharge your email marketing campaigns.",
    sections: [
      {
        title: "360-Degree Customer Profiles",
        subtitle: "Create comprehensive customer profiles by centralizing data from multiple sources.",
        markdown: "With Cogny, you can easily collect and centralize data from various platforms, helping you build a complete understanding of your customers' behaviors, preferences, and interactions with your brand."
      },
      {
        title: "Seamless Integration with Email Marketing Automation Systems",
        subtitle: "Export your customer data to your preferred email marketing platform for a personalized experience.",
        markdown: "Cogny's platform integrates seamlessly with popular email marketing automation systems, allowing you to import your 360-degree customer profiles and create highly targeted email campaigns."
      },
      {
        title: "Machine Learning for Purchase Propensity",
        subtitle: "Predict the likelihood of customer purchases and tailor your email content accordingly.",
        markdown: "Cogny utilizes machine learning models to analyze your centralized customer data, predicting purchase propensity, and helping you make personalized recommendations in your email marketing campaigns."
      },
      {
        title: "Personalized Recommendations",
        subtitle: "Drive higher engagement and conversions by recommending the right products to the right customers.",
        markdown: "By leveraging the insights from the 360-degree customer profiles and purchase propensity predictions, you can offer personalized product recommendations in your email marketing campaigns, leading to increased customer engagement and conversions."
      },
      {
        title: "Unlock the Full Potential of Email Marketing with Cogny",
        subtitle: "Experience the benefits of centralized data, seamless integrations, and AI-driven insights.",
        markdown: "Ready to take your email marketing to the next level? Get started with Cogny today and discover the power of data-driven, personalized email marketing campaigns."
      }
    ]
  },
  {
    slug: "first-party-data-audiences",
    title: "First Party Data Audiences",
    short_title: "First Party Data",
    subtitle: "Maximize your marketing efficiency with Cogny's first party data audience management",
    sections: [
      {
        title: "Import data from internal databases",
        subtitle: "Easily integrate your internal data with Cogny to create highly-targeted audiences",
        markdown: "Leverage your company's internal databases to create custom segments, allowing for more accurate audience targeting and efficient advertising campaigns."
      },
      {
        title: "Machine learning-powered audience creation",
        subtitle: "Use Cogny's advanced ML algorithms to build your ideal audience",
        markdown: "Combine your first party data with Cogny's powerful machine learning models to identify the most relevant customer segments and optimize your marketing strategies."
      },
      {
        title: "Target specific audiences with precision",
        subtitle: "Deliver the right message to the right people at the right time",
        markdown: "With Cogny's first party data audience management, you can create highly-specific audiences and target them with tailored messaging, ensuring that your advertisements reach the most relevant customers."
      },
      {
        title: "Improve advertising efficiency",
        subtitle: "Maximize your marketing budget with data-driven audience targeting",
        markdown: "By utilizing first party data audiences built using Cogny, you can increase the return on your marketing investment, reducing wasted ad spend and driving higher conversions."
      },
      {
        title: "Maintain data privacy and compliance",
        subtitle: "Keep your customer data secure and adhere to privacy regulations",
        markdown: "Cogny's platform ensures that your first party data is handled securely and in compliance with data privacy regulations, making it a safe and reliable solution for managing your marketing audiences."
      }
    ]
  },
  {
    slug: "no-sql-cogny",
    title: "No SQL? No Problem!",
    short_title: "No SQL? No Problem!",
    subtitle: "With Cogny, query and transform your data using plain English and GPT-4",
    sections: [
      {
        title: "Natural Language Processing",
        subtitle: "Use plain English to navigate your data effortlessly",
        markdown: "Cogny's integration with GPT-4 enables you to analyze and manipulate your data without the need to learn SQL. Simply type in your questions or commands, and Cogny will do the rest."
      },
      {
        title: "User-friendly Interface",
        subtitle: "Effortlessly access your data warehouse",
        markdown: "Cogny connects your data warehouse seamlessly, allowing you to focus on what matters most: gaining insights and driving action from your data. Spend less time on technicalities and more time on innovation."
      },
      {
        title: "Data Analysis Made Easy",
        subtitle: "Leverage GPT-4's power for actionable insights",
        markdown: "Cogny's integration with GPT-4 enables you to ask complex questions and receive clear, concise answers in seconds. From trend analysis to data-driven decision making, Cogny makes it incredibly easy for you to understand your data."
      },
      {
        title: "Security & Privacy",
        subtitle: "Keep your data safe and secure",
        markdown: "Cogny is committed to ensuring your data is protected at all times. We use industry-standard encryption and robust security measures to guarantee the privacy and safety of your data."
      },
      {
        title: "Get Started Today",
        subtitle: "Unlock the potential of your data without SQL",
        markdown: "Experience the power of Cogny and GPT-4 for yourself. Sign up today and start transforming your data analysis capabilities with just a few clicks."
      }
    ]
  },
  {
    slug: "transform-data-for-looker",
    title: "Transform Data for Looker Studio",
    short_title: "Transform Data",
    subtitle: "Prepare and customize data using Cogny's GPT-4 powered data terminal and BigQuery",
    sections: [
      {
        title: "Easily Prepare Data for Looker Studio",
        subtitle: "Eliminate the need for data blending in Looker Studio or Google Sheets.",
        markdown: "Cogny's GPT-4 powered data terminal allows you to seamlessly prepare your data for use in Looker Studio. With custom transformations in BigQuery, you can avoid time-consuming data blending tasks in Looker Studio and Google Sheets."
      },
      {
        title: "Custom Transformations in BigQuery",
        subtitle: "Tailor your data to your specific needs.",
        markdown: "Leverage the power of Cogny's platform to create custom transformations in BigQuery. This allows you to adapt your data to your unique requirements and ensures a smooth integration with Looker Studio."
      },
      {
        title: "Streamline Your Data Analysis Workflow",
        subtitle: "Simplify your data preparation process.",
        markdown: "Cogny's data management platform enables you to effortlessly prepare and transform your data for Looker Studio. By automating data preparation tasks, you can save valuable time and resources, enabling you to focus on data-driven decision making."
      },
      {
        title: "Integrate with Other Third-Party Platforms",
        subtitle: "Extend the capabilities of your data analysis.",
        markdown: "In addition to Looker Studio, Cogny's platform supports integration with various third-party platforms such as Google Ads, Facebook Ads, and more. This allows you to centralize, analyze, and take action on data from multiple sources, enhancing your overall data strategy."
      }
    ]
  }
];

const scrollToTop = () => {
  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }
};

const UseCasesNav = (props) => {
  const currentPage = props.page;

  const handleLinkClick = (e) => {
    e.preventDefault();
    //scrollToTop();
    const targetSlug = e.target?.pathname.replace("/usecases/", "");
    props.onNavClick(targetSlug);
  }

  return (
    <div className="UseCasesNav">
      {pages.map((page) => {
        return (
          <a
            type="link"
            className={"UseCasesNav__link" + (page.slug === currentPage.slug ? " UseCasesNav__current" : "")}
            key={page.slug}
            href={page.slug}
            onClick={page.slug !== currentPage.slug ? handleLinkClick : e => e.preventDefault()}
          >
            {page.short_title}
          </a>
        )
      })}
    </div>
  );
}

const UseCase = (props) => {
  if (!props?.page?.sections) return;
  const { page } = props;

  const masonryBreakpoints = {
    default: 2,
    767: 1
  }

  return (
    <div className="UseCase">
      <OutsideBox classBase="UseCaseHeader" nobg>
        <div className="UseCaseHeader">
          {page.title && (
            <h2 className="UseCaseHeader__title">{page.title}</h2>
          )}

          {page.subtitle && (
            <div className="UseCaseHeader__subtitle">{page.subtitle}</div>
          )}

          {page.desc && (
            <div className="UseCaseHeader__desc">{page.desc}</div>
          )}
        </div>
      </OutsideBox>

      <Masonry
        breakpointCols={masonryBreakpoints}
        className="UseCase__sections"
        columnClassName="UseCase__column"
      >
        {page.sections.map((section) => {
          return (
            <MediaBox
              key={camelcase(section.title)}
              title={section.title}
              text={section.subtitle}
              markdown={section.markdown}
              video={section.video}
              link={section.link}
              linkText={section.linkText}
              linkOnClick={scrollToTop}
              mediaFirst
              titleLevel={3}
            />
          )
        })}
      </Masonry>
    </div>
  );
}

function UseCases() {
  const defaultPage = pages[0];
  const params = useParams();
  const slug = params.slug;
  const navigate = useNavigate();
  const findPage = pages.find(page => slug === page.slug);
  const currentMaybe = typeof findPage !== "undefined" ? findPage : defaultPage;
  const [currentPage, setCurrentPage] = useState(currentMaybe);
  useBodyClass('outside-wide');

  useEffect(() => {
    if (slug !== currentPage.slug) {
      navigate("/usecases/" + currentPage.slug);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const handleNavClick = (click) => {
    const findNewPage = pages.find(page => click === page.slug);
    const newCurrentMaybe = typeof findNewPage !== "undefined" ? findNewPage : defaultPage;
    setCurrentPage(newCurrentMaybe);
  }

  if (typeof currentPage === "undefined") return <>Error</>;

  return (
    <div className="UseCases">
      <SubpageHero title="Use cases" tagline={<>Cogny can do great things for agencies and enterprises.<br />Here are some of our greatest use cases.</>} size="wide" />

      <OutsideBox classBase="UseCasesBox">
        <div className="UseCasesBox">
          <UseCasesNav page={currentPage} onNavClick={handleNavClick} />
          <UseCase page={currentPage} />
        </div>
      </OutsideBox>

      <BookADemo />
    </div>
  );
}

export default UseCases;
