import { useEffect } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import App from 'App';
import Outside from 'outside';
import { useAuth } from 'hooks/useAuth';
import PrivateRoute from 'components/organisms/PrivateRoute';
import { OutsideProvider } from 'hooks/useOutsideData';
import { DependencyProvider } from 'hooks/useDependecies';
import { AppDataProvider } from 'hooks/useAppData';

function AppRouter() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setToken } = useAuth();

  useEffect(() => {
    if (searchParams.get("auth") !== null) {
      const token = searchParams.get("auth");
      setToken(token);

      const f = searchParams.get("f");
      if (f !== null && window.dataLayer) {
        const eventName = f === "true" ? "first_login" : "login";
        window.dataLayer.push({ event: eventName, token: token });
      }

      const returnUrl = searchParams.get("return_to");
      if (returnUrl && returnUrl.startsWith("/app/")) {
        navigate(returnUrl, { replace: true });
      } else {
        navigate("/app", { replace: true });
      }
    }
  }, [searchParams]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Routes>
      <Route path="/*" element={
        <OutsideProvider>
          <Outside />
        </OutsideProvider>
      } />
      <Route path="/app/*" element={
        <PrivateRoute>
          <DependencyProvider>
            <AppDataProvider>
              <App />
            </AppDataProvider>
          </DependencyProvider>
        </PrivateRoute>
      } />
    </Routes>
  );
}

export default AppRouter;
