const questions = [
  {
    question: "What is GDPR monitoring?",
    answer: `
GDPR monitoring is the ongoing process of scanning websites and online properties for potential vulnerabilities related to the General Data Protection Regulation (GDPR). This regulation sets strict standards for how companies in the EU (and those dealing with EU residents) handle personal data.
`,
  },
  {
    question: "Why is GDPR monitoring important?",
    answer: `
Non-compliance with the GDPR can lead to hefty fines, reputational damage, and even legal action. GDPR monitoring helps you:

- Identify and address vulnerabilities before they become major issues.
- Maintain compliance with GDPR regulations.
- Protect user privacy and build trust with your audience.
- Demonstrate due diligence in data handling practices.

`,
  },
  {
    question: "How does Cogny's GDPR monitoring work?",
    answer: `
Cogny's GDPR monitoring service scans your website URLs for potential issues and compares them to prior rulings in european courts such as:

- **Electronic Communications Act - Chapter 6, Section 18**

   Electronic Communications Act (LEK) addresses the storage and retrieval of information from a subscriber's or user's terminal equipment. This is only permitted with the express consent of the user and the user's right to access the stored information.

- **EUROPEAN DATA PROTECTION BOARD (EDPB) - Decision 2020/01**
   Decision 2020/01 from the European Data Protection Board (EDPB) is a legal text providing guidance on GDPR in the EU, including guidelines for data protection, transfer of personal data to third countries, and data protection impact assessments.

- **DATA PROTECTION AUTHORITY (IMY) - IMY 2023-06-30**

   IMY issued sanctions against four companies for their use of Google Analytics following complaints related to the Schrems II ruling. The affected companies are CDON, Coop, Dagens Industri, and Tele2. This sets a precedent for the transfer of personal data to third countries.

- **LANDGERICHT MÜNCHEN (Munich Regional Court) - LG MÜNCHEN - 3 O 17493/20**

   The Munich Regional Court ruled that the transfer of IP addresses to Google LLC via Google Fonts violated the GDPR and the user's right to self-determination. Consequently, the court issued an injunction under §823.1 BGB in conjunction with §1004 BGB (analogously) and awarded €100 in damages to the affected user.
`,
  },
  {
    question: "What does Cogny do after finding vulnerabilities?",
    answer: `
Our service provides a detailed report outlining the identified issues, their severity, and potential solutions. You can then use this information to address the vulnerabilities and ensure GDPR compliance.
    `
  },
  {
    question: "Who needs GDPR monitoring?",
    answer: `
Any website or online service that collects or processes personal data from EU residents needs GDPR monitoring.
`
  },
  {
    question: "How often should I monitor for GDPR compliance?",
    answer: `
Regular monitoring is crucial. Data privacy regulations are constantly evolving, and your website may change over time. Cogny offers flexible monitoring plans to suit your needs.
`
  },
  {
    question: "Is Cogny's GDPR monitoring easy to use?",
    answer: `
Absolutely! Our service is user-friendly and designed to be accessible for businesses of all sizes. You simply provide your website URLs, and we take care of the rest.`
  }
];

export default questions;
