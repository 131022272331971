import './style.scss';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Tags from 'components/molecules/Tags';
import Button from 'components/atoms/Button';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import { BarChart } from 'recharts';
import { Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { useAppData } from 'hooks/useAppData';

function SSGTMCard(props) {
  const { task } = props;
  const [isLoaded, setIsLoaded] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState([]);
  const [statsIsLoaded, setStatsIsLoaded] = useState(false);
  const [, setStatsError] = useState(null);
  const [cookies] = useCookies(["token", "project"]);
  const [verificationStatus, setVerificationStatus] = useState(task.status === 'new' ? '(pending)' : '(done)');
  const params = useParams();
  const warehouseId = params.warehouse_id;
  const { getPath } = useAppData();

  var tags = [
    {
      "gid": "status"
      , "name": "Status: " + props.task.status
    },
    {
      "gid": "gtm_code"
      , "name": "GTM Code: " + props.task.gtm_code,
    },
    {
      "gid": "size"
      , "name": "Size: " + props.task.size,
    },
  ];

  useEffect(() => {

    function fetchStats() {
      if (cookies.token) {
        const api = new CognyAPI(cookies.token);
        api.getSSGTMStats(warehouseId, task.id)
          .then(
            (result) => {
              result = result.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1).map((item) => {
                item.avg_request_time = r3(item.processing_time / item.requests);
                return item;
              });

              var res = {};
              for (var i = 0; i < result.length; i++) {
                var min_ago = ft(result[i].timestamp);
                if (min_ago in res) {
                  res[min_ago].bytes += result[i].bytes;
                  res[min_ago].requests += result[i].requests;
                  res[min_ago].processing_time += result[i].processing_time;
                  res[min_ago].avg_request_time = r3(res[min_ago].processing_time / res[min_ago].requests);
                } else {
                  res[min_ago] = result[i];
                  res[min_ago].minutes_ago = min_ago;
                  delete res[min_ago].path;
                }
              }

              var result_last_30_min = [];
              for (var j = 30; j > -1; j--) {
                if (j in res) {
                  result_last_30_min.push(res[j]);
                } else {
                  result_last_30_min.push({
                    "minutes_ago": j,
                    "requests": 0,
                    "bytes": 0,
                    "processing_time": 0,
                    "avg_request_time": 0,
                  });
                }
              }

              setStats(result_last_30_min);
              setStatsIsLoaded(true);
            },
            (error) => {
              setStatsError(error);
            }
          );
      }
    }

    fetchStats();
    const interval = setInterval(fetchStats, 10000);

    return () => {
      clearInterval(interval);
    }
  }, [cookies.token, warehouseId, task.id]);


  function handleVerify(e) {
    e.preventDefault();

    setIsLoaded(false);

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.verifySSGTM(warehouseId, task.id)
        .then(
          (result) => {
            setIsLoaded(true);
            setVerificationStatus(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }

  var gtm_proxy_ip = "65.21.216.240";

  function ft(unixTime) {
    var t = new Date();
    t.setSeconds(0, 0);
    var deltaMinutes = (t.getTime() - unixTime * 1000) / 1000 / 60;
    return deltaMinutes.toFixed(0) + ""
  }

  function r3(n) {
    return n.toFixed(3);
  }

  return (
    <div className="SSGTMCard" key={task.gid}>
      <div className="SSGTMCard__main">
        <Link className="SSGTMCard__link" to={getPath("server-side-gtm", task.id)}>
          <h3 className="SSGTMCard__title">{task.host}</h3>
        </Link>
        <Tags tags={tags} />
      </div>
      <div className="SSGTMCard__meta">
        {task.status === 'new' &&
          <div>
            <h3>Setup instructions</h3>
            <p><b>1. Add the following DNS records to your domain: {verificationStatus.overall}</b></p>
            <ul>
              <li>{task.host} as an A record pointing to {gtm_proxy_ip} - {verificationStatus.host}</li>
            </ul>
            <Button onClick={handleVerify} className="SSGTMCard__submit" icon="arrowRight" iconSide="right" disabled={!isLoaded}>Verify</Button>
            <p>2. We'll deploy your server-side GTM servers.</p>
            <p>3. Add your GTM server to your client-side GTM config for the GA4 tag.</p>
          </div>
        }
        {task.status === 'configured' &&
          <div>
            <h3>Setup instructions</h3>
            <p>1. Add the following DNS records to your domain (done)</p>
            <p><b>2. We'll deploy your server-side GTM servers. (in progress)</b></p>
            <p>3. Add your GTM server to your client-side GTM config for the GA4 tag.</p>
          </div>
        }
        {task.status === 'active' &&
          <div>
            <BarChart
              width={800}
              height={200}
              data={stats}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="minutes_ago"
                name="Time"
              />
              <YAxis yAxisId="left" orientation='left' stroke="#8884d8" />
              <YAxis yAxisId="right" orientation='right' stroke="#82ca9d" />
              <Tooltip contentStyle={{ 'background': '#444' }} labelFormatter={(l) => { return l + " minutes ago" }} />
              <Legend />
              <Bar dataKey="requests" fill="#8884d8" yAxisId="left" isAnimationActive={false} />
              <Bar dataKey="avg_request_time" fill="#82ca9d" yAxisId="right" isAnimationActive={false} />
            </BarChart>

            {statsIsLoaded && stats.length === 0 &&
              <div>
                <h3>Setup instructions</h3>
                <p>1. Add the following DNS records to your domain (done)</p>
                <p>2. We'll deploy your server-side GTM servers. (done)</p>
                <p><b>3. Add your GTM server to your client-side GTM config for the GA4 tag.</b></p>
              </div>
            }

          </div>
        }
      </div>
      {error && <div>Error: {error.message}</div>}
    </div>
  );
}

export default SSGTMCard;
