import Tags from 'components/molecules/Tags';
import './style.scss';

function PageHeader(props) {
  const altClass = props.alt ? " PageHeader--alt" : "";
  return (
    <div className={"PageHeader" + altClass}>
      <div className="PageHeader__titles">
        {props.pretitle && <div className="PageHeader__pretitle">{props.pretitle}</div>}
        <h1 className="PageHeader__title">{props.title}</h1>
        {props.tags && <Tags tags={props.tags} className="PageHeader__tags" />}
        {props.description && <div className="PageHeader__description">{props.description}</div>}
      </div>
      {props.button && <div className="PageHeader__button">{props.button}</div>}
    </div>
  );
}

export default PageHeader;
