const post = {
  title: "Schrems I, II, and the Looming Schrems III: A Saga of Data Privacy and Transatlantic Tensions",
  slug: "schrems-i-ii-and-the-looming-schrems-iii-a-saga-of-data-privacy-and-transatlantic-tensions",
  date: "2024-06-10",
  author: "Tom Ström",
  description: "The saga of Maximilian Schrems and his legal battles against Facebook, now Meta, has become a landmark series in data privacy law, particularly regarding the transfer of personal data between the EU and the United States.  Schrems' relentless pursuit of stricter data protection has shaken up the way companies handle user information across borders. Let's delve into the story so far:",
  image: "",
  body: `
## Schrems I: Safe Harbor Struck Down (2015)
In 2015, Schrems, an Austrian privacy advocate, challenged the "Safe Harbor" agreement, a mechanism allowing data transfer between the EU and US companies who self-certified their adherence to data protection principles.  Schrems argued US surveillance laws, like PRISM and UPSTREAM, compromised user privacy, rendering the Safe Harbor agreement inadequate. The Court of Justice of the European Union (CJEU) agreed, invalidating the agreement and setting a higher bar for data transfers.

## Schrems II: Standard Contractual Clauses Scrutinized (2020)
Following the Safe Harbor invalidation, Standard Contractual Clauses (SCCs) emerged as a popular data transfer mechanism.  These are pre-drafted contracts signed by data controllers (those who collect data) and processors (those who handle the data) to ensure adequate data protection.  However, Schrems wasn't done. He challenged the adequacy of SCCs in light of US surveillance practices.  In 2020, the CJEU, in Schrems II, issued a landmark ruling. While upholding the validity of SCCs, the court placed the onus on companies to assess the laws of the receiving country (in this case, the US) and implement supplementary measures to ensure a level of data protection "essentially equivalent" to that guaranteed in the EU. This meant companies had to consider the specific circumstances of data transfer, including the access of US intelligence agencies, and implement additional safeguards if necessary.

## Schrems III: The Next Chapter (Unfolding)
The story doesn't end there.  Schrems has expressed concerns that the current SCCs don't provide sufficient safeguards and has hinted at a potential "Schrems III" legal challenge. Experts anticipate this new case could focus on the continued validity of SCCs themselves, or delve deeper into the practicality of implementing additional safeguards given the complexities of US surveillance laws.

## The Impact: A Call for Reform and a Cloud of Uncertainty
The Schrems rulings have had a significant impact on businesses that transfer data between the EU and US. Companies now face a complex and sometimes murky legal landscape when it comes to data transfers.  The potential for Schrems III adds another layer of uncertainty.  This has spurred calls for a new and robust data transfer agreement between the EU and US that addresses concerns about US surveillance practices.

## Looking Ahead: The Future of Transatlantic Data Flows
The Schrems saga highlights the ongoing tension between the EU's strong data protection principles and the US's national security interests.  Whether Schrems III materializes and what form it takes remains to be seen.  However, one thing is clear: the future of transatlantic data flows remains uncertain, and companies operating in this space need to stay informed and prepared to adapt their data transfer practices as the legal landscape continues to evolve.
`
};

export default post;
