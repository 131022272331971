import './style.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import CognyAPI from 'components/_classes/CognyAPI';
import LoadingDots from 'components/atoms/LoadingDots';
import Button from 'components/atoms/Button';
import Form from 'components/atoms/Form';
import YouTube from 'react-youtube';
import PageHeader from 'components/organisms/PageHeader';
import useDatasources from 'hooks/useDatasources';
import { Line, LineChart } from 'recharts';
import { useAppData } from 'hooks/useAppData';
//import DatasourceCard from 'components/atoms/DatasourceCard';

function r2(i) {
  return Math.round(i * 10000) / 100;
}

const DatasourceTables = (props) => {
  const [sort, setSort] = useState(0);
  const sorts = ['usage', 'name'];
  const { data, error, isLoaded, provider } = props;

  const nextSort = () => setSort(currentSort => (currentSort + 1) % sorts.length);

  if (error) return <>Error!</>;
  if (!isLoaded) return <LoadingDots />;

  if (isLoaded && !data.length) return (
    <div className="DatasourceDetails__tables DatasourceTables DatasourceTables--empty">
      <div className="DatasourceTables__header">
        <h2 className="DatasourceTables__title">You need to connect this data source to activate it.</h2>
      </div>
    </div>
  );

  return (
    <div className="DatasourceDetails__tables DatasourceTables">
      <div className="DatasourceTables__header">
        <h2 className="DatasourceTables__title">Tables in this data source</h2>
        <Button className="DatasourceList__sort" icon="sort" iconSide="left" onClick={nextSort} disabled>{sorts[sort]}</Button>
      </div>
      <div className="DatasourceTables__columns">
        <div className="DatasourceTables__name">Name</div>
        {provider === "fivetran" &&
          <>
            <div className="DatasourceTables__line">6 month usage</div>
            <div className="DatasourceTables__usage">Total usage</div>
          </>
        }
      </div>
      {isLoaded && !!data.length && data.sort((a, b) => (b.usage_stats_total > a.usage_stats_total) ? 1 : -1).map(table => <DatasourceTable provider={provider} key={table.id} table={table} />)}
    </div>
  );
}

const DatasourceTable = (props) => {
  const { table, provider } = props;
  const tableName = table.id.split('.')[2];

  if (!tableName) return false;

  return (
    <>
      <div className="DatasourceTable">
        <div className="DatasourceTable__inner">
          <div className="DatasourceTable__name"><h3>{tableName}</h3></div>
          {
            /*
              <div className="DatasourceTable__bar">
                {table.usage_stats ?? [].length > 0 ?
                  <BarChart width={100} height={20} data={table.usage_stats} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                    <Bar dataKey="usage" fill="#8884d8" />
                  </BarChart>
                  : false}
              </div>
            */
          }
          {provider === "fivetran" &&
            <>
              <div className="DatasourceTable__line">
                {table.usage_stats ?? [].length > 0 ?
                  <LineChart width={160} height={24} data={table.usage_stats} margin={{ top: 4, right: 2, bottom: 4, left: 2 }}>
                    <Line type="monotone" dataKey="usage" stroke="#BEA0FF" strokeWidth={4} strokeLinecap="round" dot={false} />
                  </LineChart>
                  :
                  <div className="DatasourceTable__lineMissing">Inactive</div>
                }
              </div>
              <div className="DatasourceTable__usage">{r2(table.usage_stats_total)}%</div>
            </>
          }
        </div>
      </div>
    </>
  );
}

function DatasourceDetails(props) {
  const datasourceData = useDatasources();
  const detailError = datasourceData.error;
  const detailIsLoaded = datasourceData.isLoaded;
  const detailDatasources = datasourceData.data;
  const [datasource, setDatasource] = useState({});
  const [apiHost, setApiHost] = useState("");
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const params = useParams();
  const [cookies] = useCookies(["token", "project"]);
  const navigate = useNavigate();
  const gid = params.id;
  const warehouseId = params.warehouse_id;
  const [currentTables, setCurrentTables] = useState(false);
  const [tags, setTags] = useState([]);

  const { getPath } = useAppData();

  useEffect(() => {
    let mounted = true;
    const api = new CognyAPI(cookies.token);
    setApiHost(api.getApiHost());
    api.getDatasource(warehouseId, gid)
      .then(
        (result) => {
          if (!mounted) return;
          setDatasource(result);
          setIsLoaded(true);
        },
        (error) => {
          if (!mounted) return;
          setError(error);
          setIsLoaded(true);
        }
      );
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gid, cookies.project, cookies.token]);

  const handleDelete = (e) => {
    e.preventDefault();

    setIsLoaded(false);

    const api = new CognyAPI(cookies.token);
    api.deleteDatasource(warehouseId, gid)
      .then(
        (taskResult) => {
          setIsLoaded(true);

          navigate(getPath("datasources"));
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  useEffect(() => {
    if (detailDatasources.length) {
      const matchedDatasource = detailDatasources.filter(someDatasource => someDatasource.gid === datasource.gid);
      if (matchedDatasource.length > 0 && typeof matchedDatasource[0].tables !== "undefined") {
        setCurrentTables(matchedDatasource[0].tables);

        const source = matchedDatasource[0];

        let formatTags = [];
        if (!!source.status.length) {
          formatTags.push({
            "gid": "status",
            "name": matchedDatasource[0].status
          })
        }

        if (!!source.usage_stats_total) {
          formatTags.push({
            "gid": "usage",
            "name": "Usage: " + r2(matchedDatasource[0].usage_stats_total) + "%",
          })
        }

        if (!!source.fivetran_succeeded_at.length) {
          formatTags.push({
            "gid": "last_sync",
            "name": "Last sync: " + matchedDatasource[0].fivetran_succeeded_at
          })
        }

        setTags(formatTags);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasource, detailDatasources])

  if (error) return <div>Error: {error.message}</div>;
  if (!isLoaded) return <LoadingDots />;

  return (
    <div className="DatasourceDetails">
      <PageHeader pretitle="Data sources" title={datasource.name} tags={tags} />

      <DatasourceTables provider={datasource.provider} data={currentTables} error={detailError} isLoaded={detailIsLoaded} />

      {
        datasource.type === 'google_analytics_4' &&
        datasource.provider === 'Google' &&
        <div className="DatasourceDetails__ga4info">
          <p>The Google Analytics data is imported for free into BigQuery as long as you have less than 1 million events daily.</p>
          <p>If this level of events is exceede, you might need to purchase the Google Analytics 4 360 suite or consider importing the GA4 data directly into BigQuery.</p>
        </div>
      }

      {
        datasource.status === 'Ready for connection' &&
        datasource.type === 'google_analytics_4' &&
        datasource.provider === 'Google' &&
        <div className="DatasourceDetails__ga4info">
          <p>To setup GA4, link to BigQuery project in Google Analytics interface:</p>
          <YouTube videoId="dd7E1iLcd7Q" />
          <p>After you have completed the steps in the video, it can take up to 48 hours before Google Analytics populates your project with data.</p>
        </div>
      }

      <div className="DatasourceDetails__tools">
        <div className="DatasourceDetails__toolbar">
          {
            datasource.status === 'Ready for connection' &&
            datasource.provider === 'fivetran' &&
            <Button
              type="htmllink"
              className="DatasourceDetails__submit"
              icon="arrowRight"
              iconSide="right"
              href={apiHost + "/connect/" + warehouseId + "/" + datasource.gid}
              target="_blank"
              rel="noreferrer"
            >
              Connect data source
            </Button>
          }

          <Form className="DatasourceDetails__delete" onSubmit={handleDelete}>
            <Button type="submit" className="DatasourceDetails__submit" variant="danger">
              Delete data source
            </Button>
          </Form>

        </div>
      </div>
    </div>
  );
}

export default DatasourceDetails;
