import './style.scss';
import { ReactComponent as CookiesIcon } from './assets/cookies.svg';
import { ReactComponent as GDPRIcon } from './assets/gdpr.svg';
import { ReactComponent as PagespeedIcon } from './assets/pagespeed.svg';
import { ReactComponent as AIIcon } from './assets/ai.svg';
import { ReactComponent as AnalysisIcon } from './assets/analysis.svg';
import { ReactComponent as AnswersIcon } from './assets/answers.svg';
import { ReactComponent as FastIcon } from './assets/fast.svg';

export const FeaturesIcons = {
  Cookies: CookiesIcon,
  GDPR: GDPRIcon,
  Pagespeed: PagespeedIcon,
  AI: AIIcon,
  Analysis: AnalysisIcon,
  Answers: AnswersIcon,
  Fast: FastIcon
};

function Features(props) {
  const items = props.items;

  return (
    <div className="Features">
      <div className="Features__items">
        {items.map((item, index) => {
          return (
            <div className="Features__item" key={index}>
              <div className="Features__pretitle">{item.preTitle}</div>
              {item.icon}
              <div className="Features__title">{item.title}</div>
            </div>
          )
        })}

      </div>
    </div>
  );
}

export default Features;
