import './style.scss';
import OutsideBox from 'outside/components/OutsideBox';
import logoGA from './assets/logo-ga.svg';
import logoGA4 from './assets/logo-ga4.svg';
import logoGTM from './assets/logo-gtm.svg';
import logoLinkedIn from './assets/logo-linkedin.svg';
import logoMeta from './assets/logo-meta.svg';

function Integrations(props) {
  const lang = props.lang;

  const items = [
    {
      id: "gtm",
      logo: logoGTM,
      name: <>Google<br /> Tag Manager</>,
    },
    {
      id: "ga4",
      logo: logoGA4,
      name: <>Google<br /> Analytics 4</>,
    },
    {
      id: "ga",
      logo: logoGA,
      name: <>Google Ads<br /> Conversion API</>,
    },
    {
      id: "meta",
      logo: logoMeta,
      name: <>Meta<br /> Conversion API</>,
    },
    {
      id: "li",
      logo: logoLinkedIn,
      name: <>LinkedIn<br /> Conversion API</>,
    },
  ];

  return (
    <OutsideBox classBase="Integrations" nobg>
      <div className="Integrations">
        <div className="Integrations__inner">
          <div className="Integrations__content">
            <h2 className="Integrations__title">
              {
                lang === "sv" ?
                  "Funkar ihop med alla dina favoritverktyg"
                  :
                  "Integrated with all your favorite tools"
              }
            </h2>
            <div className="Integrations__text">
              {
                lang === "sv" ?
                  "När du använder server-side tracking med Cogny så har du tillgång till alla dina favoritverktyg från första eventet."
                  :
                  "When using server-side tracking with Cogny, all your favorite tracking tools are available from the first event."
              }
            </div>
          </div>
          <div className="Integrations__items">
            {items.map(item => (
              <div className="Integrations__item" key={item.id}>
                <img src={item.logo} alt={item.name} className="Integrations__logo" />
                <div className="Integrations__name">{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </OutsideBox>
  );
}

export default Integrations;
