import './style.scss';
import useBlogPosts from 'hooks/useBlogPosts';
import { Link } from 'react-router-dom';
import LoadingDots from 'components/atoms/LoadingDots';
import Icon from 'components/atoms/Icon';
import missingImage from './missing.svg';
import { formatDate } from 'outside/components/SubpageHero';

const scrollToTop = () => {
  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }
};

function BlogPosts(props) {
  const posts = useBlogPosts();
  const filteredData = props.filter ? posts.data.filter((_, index) => props.filter.indexOf(index) !== -1) : undefined;
  const data = filteredData ?? posts.data;
  const isLoaded = posts.isLoaded;
  const error = posts.error;

  const { order, rows, cols } = props;
  let limit;

  if (rows && !limit) {
    limit = rows * 4;
  }

  const current = props.current ? props.current : {};

  let postOrder = data;

  if (Array.isArray(data)) {
    postOrder = [...data].reverse();
  }

  const isNumber = value => typeof value === 'number' && !isNaN(value);

  const handleLinkClick = () => {
    scrollToTop();
  }

  if (current) {
    postOrder = postOrder.filter(item => item !== current);
  }

  if (order === "random") {
    function shuffleArray(arr) {
      var len = arr.length,
        result = new Array(len),
        taken = new Array(len);

      while (len--) {
        var x = Math.floor(Math.random() * (len + 1));
        result[len] = arr[x in taken ? taken[x] : x];
        taken[x] = len in taken ? taken[len] : len;
      }
      return result;
    }
    postOrder = shuffleArray(postOrder);
  }

  if (limit && isNumber(limit)) {
    postOrder = postOrder.slice(0, limit);
  }

  if (!isLoaded) return <LoadingDots />;

  if (error) return "Error";

  return (
    <div className="BlogPosts" data-limit={limit} data-rows={rows} data-cols={cols}>
      {props.title && props.description &&
        <div className="BlogPosts__header">
          <div className="BlogPosts__title">{props.title}</div>
          <div className="BlogPosts__description">{props.description}</div>
        </div>
      }
      <div className="BlogPosts__posts">

        {postOrder.map(item => {
          const { title, slug, image, date } = item;

          return (
            <Link
              key={slug}
              to={"/blog/" + slug}
              type="link"
              className={"BlogPosts__post" + (slug === current.slug ? " BlogPosts__post--current" : "")}
              onClick={handleLinkClick}
            >
              <div className="BlogPosts__image">
                {image ?
                  <img src={"/static/" + image} alt={title} />
                  :
                  <img src={missingImage} alt={title} className="BlogPosts__imageMissing" />
                }
              </div>

              {date &&
                <div className="BlogPosts__date">
                  {formatDate(date)}
                </div>
              }

              {title &&
                <div className="BlogPosts__posttitle">
                  {title} <span><Icon icon="arrowRight" /></span>
                </div>
              }
            </Link>
          );
        })}

      </div>
    </div >
  );
}

export default BlogPosts;
