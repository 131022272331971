import './style.scss';
import Button from 'components/atoms/Button';
import SignOutLink from 'components/atoms/SignOutLink';
import WelcomeTitle from 'components/atoms/WelcomeTitle';
import AccountPicker from 'components/organisms/AccountPicker';

function HomeView(props) {

  return (
    <div className="HomeView">
      <div className="HomeView__welcome">
        <WelcomeTitle />

        <SignOutLink dark />
      </div>
      <div>
        <AccountPicker />
      </div>
    </div>
  );
}

export default HomeView;
