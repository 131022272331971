import { useAppData } from 'hooks/useAppData';
import { createContext, useState, useContext } from 'react';

const PageSidebarContext = createContext();

export function PageSidebarProvider(props) {
  const { children, scope } = props;
  const { getPath } = useAppData();
  const warehousePath = getPath();

  if (!scope) {
    throw new Error("PageSidebarProvider requires a scope prop");
  }

  const basePath = warehousePath + scope + "/";

  const defaultState = {
    items: [],
    isLoaded: false,
    error: null,
    linkPath: false,
    buttonLabel: false,
    buttonTarget: false,
    emptyMessage: false,
    hasFavorites: false,
    title: false,
    sorting: false
  };

  const [pageSidebarData, setPageSidebarData] = useState({ default: defaultState });

  const getBasePath = () => basePath;

  const getData = () => {
    return pageSidebarData[scope] ?? pageSidebarData.default;
  }

  const setItems = (items) => {
    if (!Array.isArray(items)) return;

    setPageSidebarData(currentState => ({
      ...currentState,
      [scope]: {
        items: items
      }
    }));
  }

  const setIsLoaded = (isLoaded) => {
    setPageSidebarData(currentState => ({
      ...currentState,
      [scope]: {
        ...currentState[scope],
        isLoaded: !!isLoaded
      }
    }));
  }

  const getIsLoaded = () => {
    return pageSidebarData[scope]?.isLoaded ?? pageSidebarData.default.isLoaded;
  }

  const setError = (error) => {
    setPageSidebarData(currentState => ({
      ...currentState,
      [scope]: {
        ...currentState[scope],
        error: error
      }
    }));
  }

  const getError = () => {
    return pageSidebarData[scope]?.error !== null ? pageSidebarData[scope]?.error : pageSidebarData.default.error;
  }

  const setLinkPath = (linkPath) => {
    setPageSidebarData(currentState => ({
      ...currentState,
      [scope]: {
        ...currentState[scope],
        linkPath
      }
    }));
  }

  const setButtonLabel = (buttonLabel) => {
    setPageSidebarData(currentState => ({
      ...currentState,
      [scope]: {
        ...currentState[scope],
        buttonLabel
      }
    }));
  }

  const setButtonTarget = (buttonTarget) => {
    setPageSidebarData(currentState => ({
      ...currentState,
      [scope]: {
        ...currentState[scope],
        buttonTarget
      }
    }));
  }

  const setEmptyMessage = (emptyMessage) => {
    setPageSidebarData(currentState => ({
      ...currentState,
      [scope]: {
        ...currentState[scope],
        emptyMessage
      }
    }));
  }

  const setHasFavorites = (hasFavorites) => {
    setPageSidebarData(currentState => ({
      ...currentState,
      [scope]: {
        ...currentState[scope],
        hasFavorites: !!hasFavorites
      }
    }));
  }

  const setTitle = (title) => {
    setPageSidebarData(currentState => ({
      ...currentState,
      [scope]: {
        ...currentState[scope],
        title
      }
    }));
  }

  const setSorting = (sorting) => {
    setPageSidebarData(currentState => ({
      ...currentState,
      [scope]: {
        ...currentState[scope],
        sorting
      }
    }));
  }

  const options = {
    getBasePath,
    getData,
    setItems,
    setIsLoaded,
    getIsLoaded,
    setError,
    getError,
    setLinkPath,
    setButtonLabel,
    setButtonTarget,
    setEmptyMessage,
    setHasFavorites,
    setTitle,
    setSorting
  };

  return (
    <PageSidebarContext.Provider value={options}>
      {children}
    </PageSidebarContext.Provider>
  );
}

export function usePageSidebar() {
  const context = useContext(PageSidebarContext);
  if (!context) {
    throw new Error("usePageSidebar must be used within a PageSidebarProvider");
  }
  return context;
}
