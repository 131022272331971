import "./style.scss";
import HomeLink from "components/molecules/HomeLink";

function Legal(props) {
  return (
    <div className="Legal">
      <div className="Legal__header">
        <HomeLink />
      </div>
      <div className="Legal__content">
        {props.children}
      </div>
    </div>
  );
}

export default Legal;