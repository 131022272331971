import './style.scss';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import LoadingDots from 'components/atoms/LoadingDots';
import { useCookies } from 'react-cookie';
import PageHeader from 'components/organisms/PageHeader';
import DashboardCharts from 'components/organisms/DashboardCharts';
import OnboardingChecklist from 'components/organisms/OnboardingChecklist';
import DashboardInfo from 'components/organisms/DashboardInfo';
import { useDependencies } from 'hooks/useDependecies';
import ErrorBox from 'components/molecules/ErrorBox';
const defaultOnboardingFlags = { account: true, billing: null, sst: null, warehouse: null, datasources: null, copilot: null, impact: null, datavis: null };

function DashboardMain(props) {
  const [onboardingFlags, setOnboardingFlags] = useState(defaultOnboardingFlags);
  const [onboardingFlagsIsLoaded, setOnboardingFlagsIsLoaded] = useState(false);

  const [showChecklist, setShowChecklist] = useState(true);
  const [warehouse, setWarehouse] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [processing, setProcessing] = useState([]);
  const [processingError, setProcessingError] = useState(null);
  const [processingIsLoaded, setProcessingIsLoaded] = useState(false);

  const [credits, setCredits] = useState([]);
  const [creditsError, setCreditsError] = useState(null);
  const [creditsIsLoaded, setCreditsIsLoaded] = useState(false);

  const [dashboardInfo, setDashboardInfo] = useState({});
  const [dashboardInfoIsLoaded, setDashboardInfoIsLoaded] = useState(false);

  const params = useParams();
  const [cookies] = useCookies(["token", "project"]);
  const warehouseId = params.warehouse_id;

  const { checkOnboarding, updateWarehouseId } = useDependencies();

  useEffect(() => {
    updateWarehouseId(warehouseId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId])

  useEffect(() => {
    let mounted = true;

    if (cookies.token) {
      if (!mounted) return;

      const fakeBlurbFetch = {
        title: "Get started with Cogny",
        body: "We are thrilled to see you on the Cogny data platform. We have recorded a few short tutorials to get you started. Please watch them if you need to - it takes only a few minutes. \n \ \n If you need support or have questions don't hesitate to [contact us](mailto:contact@cogny.com).",
        youtube: "1gPiPCnfPak",
        hidden: false,
      }
      setDashboardInfo(fakeBlurbFetch);
      setDashboardInfoIsLoaded(true);
    }

    return () => {
      mounted = false;
      setDashboardInfo({});
      setDashboardInfoIsLoaded(false);
    }
  }, [cookies, warehouseId]);

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouse(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;

            const included_credits = {
              small: 900,
              medium: 2990,
              large: 6655
            };

            const currentPlan = result.plan;
            const currentPlanId = typeof currentPlan === "string" ? currentPlan.toLowerCase() : undefined;

            if (currentPlanId) {
              if (currentPlanId === "small") {
                result.included_credits = included_credits.small;
              } else if (currentPlanId === "medium") {
                result.included_credits = included_credits.medium;
              } else if (currentPlanId === "large") {
                result.included_credits = included_credits.large;
              }
            } else {
              result.included_credits = included_credits.large;
            }

            setWarehouse(result);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
      setIsLoaded(false);
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, warehouseId])


  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouseCreditsStats(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;

            const included_credits = {
              small: 900,
              medium: 2990,
              large: 6655
            };

            const currentPlan = result.plan;
            const currentPlanId = typeof currentPlan === "string" ? currentPlan.toLowerCase() : undefined;

            if (currentPlanId) {
              if (currentPlanId === "small") {
                result.included_credits = included_credits.small;
              } else if (currentPlanId === "medium") {
                result.included_credits = included_credits.medium;
              } else if (currentPlanId === "large") {
                result.included_credits = included_credits.large;
              }
            } else {
              result.included_credits = included_credits.large;
            }

            setCredits(result);
            setCreditsIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setCreditsIsLoaded(true);
            setCreditsError(error);
          }
        );
    }
    return () => {
      mounted = false;
      setCreditsIsLoaded(false);
      setCreditsError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, warehouseId])

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouseProcessingStats(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setProcessing(result);
            setProcessingIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setProcessingIsLoaded(true);
            setProcessingError(error);
          }
        );
    }
    return () => {
      mounted = false;
      setProcessingIsLoaded(false);
      setProcessingError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, warehouseId])

  useEffect(() => {
    const checkAllAccess = async () => {
      let result;
      try {
        result = await checkOnboarding(warehouseId);
      } catch (error) {
        console.log('error in checkOnboardingStatus', error);
      }

      setOnboardingFlags(result);
      setOnboardingFlagsIsLoaded(true);
    };

    checkAllAccess();

    return () => {
      setOnboardingFlags(defaultOnboardingFlags);
      setOnboardingFlagsIsLoaded(false);
    }
  }, [checkOnboarding, warehouseId]);

  useEffect(() => {
    //nothing
    return () => {
      setShowChecklist(true);
    }
  }, [warehouseId])

  const handleHideMessage = () => {
    setDashboardInfo(currentState => ({
      ...currentState,
      hidden: true,
    }))
  }

  const handleHideChecklist = () => {
    setShowChecklist(false);
  }

  if (error) {
    console.log(error);
    return <ErrorBox error={error} />;
  }

  if (processingError) {
    console.log('processing error', processingError);
  }

  if (!isLoaded || !processingIsLoaded) return <LoadingDots />;

  return (
    <div className="DashboardMain">

      <PageHeader alt pretitle="Welcome" title={isLoaded ? warehouse.name : <></>} />

      <div className="DashboardMain__content">

        {dashboardInfoIsLoaded && !dashboardInfo.hidden && <DashboardInfo data={dashboardInfo} onClick={handleHideMessage} />}

        {onboardingFlagsIsLoaded && showChecklist && <OnboardingChecklist warehouseId={warehouseId} hidden={showChecklist} flags={onboardingFlags} onClick={handleHideChecklist} />}

        {warehouse.status === 'Active' && <DashboardCharts warehouse={{ warehouse, error, isLoaded }} processing={{ processing, processingError, processingIsLoaded }} data={{ warehouse, processing, credits }} />}
      </div>
    </div>
  );
}

export default DashboardMain;
