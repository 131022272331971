import './style.scss';
import OutsideBox from 'outside/components/OutsideBox';

export function formatDate(inputDateStr) {
  // Verify the input format with a regular expression
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  if (!datePattern.test(inputDateStr)) {
    return inputDateStr;
  }

  // Create a Date object from the input string
  const dateObj = new Date(inputDateStr);

  // Define month names
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Extract the month, day, and year from the date object
  const month = monthNames[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  // Construct the desired output format
  return `${month} ${day}, ${year}`;
}

function SubpageHero(props) {
  const { className = "" } = props;
  return (
    <OutsideBox classBase={"SubpageHero " + className} nobg>
      <div className={"SubpageHero " + className}>
        <div className={"SubpageHero__inner" + (props.align === "center" ? " SubpageHero__inner--center" : "")}>

          {props.blogpost && (
            <div className="SubpageHero__blogmeta">
              <div className="SubpageHero__pretitle">Article</div>
              {props.blogpost.title && (<h1 className="SubpageHero__title">{props.blogpost.title}</h1>)}
              {props.blogpost.author && (<div className="SubpageHero__author">by {props.blogpost.author}</div>)}
              {props.blogpost.date && (<div className="SubpageHero__date">{formatDate(props.blogpost.date)}</div>)}
            </div>
          )}

          {props.title && (
            <h1 className="SubpageHero__title">{props.title}</h1>
          )}

          {props.bigtagline && (
            <div className="SubpageHero__bigtagline">{props.bigtagline}</div>
          )}

          {props.tagline && (
            <div className="SubpageHero__tagline">{props.tagline}</div>
          )}

          {props.list && (
            <ul className="SubpageHero__list">
              {props.list.map((item, index) => { return <li key={index}>{item}</li> })}
            </ul>
          )}

          {props.text && (
            <div className="SubpageHero__text">{props.text}</div>
          )}

          {props.button}
        </div>
      </div>
    </OutsideBox>
  );
}

export default SubpageHero;