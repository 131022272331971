import './style.scss';
import React, { useState, useEffect } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import LoadingDots from 'components/atoms/LoadingDots';
import { useCookies } from 'react-cookie';
import { Link, Navigate } from 'react-router-dom';
import Button from 'components/atoms/Button';
import { useAppData } from 'hooks/useAppData';

function AccountPicker(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [cookies] = useCookies(["token"]);
  const { getPrePath, getCustomPath } = useAppData();

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouses()
        .then(
          (result) => {
            if (!mounted) return;
            setIsLoaded(true);

            //if result is more than 5, sort by status and then by name
            if (result.length > 5) {
              result.sort((a, b) => {
                if (a.status === b.status) {
                  return a.name.localeCompare(b.name);
                }
                return a.status.localeCompare(b.status);
              });
            }

            setAccounts(result);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
  }, [cookies])

  if (error) return <div>Error: {error.message}</div>;

  if (!isLoaded) return <LoadingDots />;

  return (
    <div className="AccountPicker">
      {
        accounts.length ?
          <div>
            <h2 className="AccountPicker__title">Select an account</h2>
            <div className="AccountPicker__accounts">
              {accounts.map(account => {
                return (
                  <div key={account.name} className="AccountPicker__account">
                    <Link className="AccountPicker__link" to={getCustomPath(account.warehouse_id, "dashboard")}>
                      <span className="AccountPicker__name">{account.name}</span>
                      <span className="AccountPicker__meta">{account.status}</span>
                    </Link>
                  </div>
                );
              })}
              <Button type="link" href={getPrePath("new_account")} className="AccountPicker__add" icon="plus" theme="dark">New account</Button>
            </div>
          </div>
          :
          <>
            <h2>You need to create a new account to continue</h2>
            <Navigate to={getPrePath("new_account")} replace />
          </>
      }
    </div>
  );
}

export default AccountPicker;
