import './style.scss';

function Home(props) {
  return (
    <div className="Home">
      {props.children}
    </div>
  );
}

export default Home;
