import ErrorBox from 'components/molecules/ErrorBox';
import './style.scss';

function Report(props) {
  return (
    <div className="Report">
      <ErrorBox noToggle noDetails />
    </div>
  );
}

export default Report;
