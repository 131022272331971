import { useState, useRef, useEffect } from 'react';
import './style.scss';

const DynamicTextarea = (props) => {
  const textareaRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const setRef = props.setRef;

  const minRows = props.rows[0];
  const maxRows = props.rows[1];
  const fontSize = 16; // Font size of the textarea
  const lineHeightMultiplier = 1.75; // Line height multiplier
  const verticalPadding = 12; // Padding top + padding bottom

  useEffect(() => {
    setRef(textareaRef.current);  // Setting the ref in the parent's state when the component mounts
  }, [setRef]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      props.submit(e);
    }
  };

  const handleChange = (event) => {
    adjustHeight();
    props.onChange(event);
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height to recalculate
      let newHeight = textarea.scrollHeight;

      if (newHeight > maxHeight) {
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.overflowY = 'hidden';
      }
      textarea.style.height = `${Math.max(newHeight, maxHeight / maxRows)}px`;
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      const heightPerLine = fontSize * lineHeightMultiplier;
      const calculatedMaxHeight = (heightPerLine * maxRows) + (verticalPadding * 2);
      setMaxHeight(calculatedMaxHeight);

      adjustHeight();
    }
  }, [minRows, maxRows]);

  useEffect(() => {
    adjustHeight();
  }, [props.children]);

  return (
    <textarea
      className={"DynamicTextarea " + props.className}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      ref={textareaRef}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      rows={minRows}
      style={{
        overflowY: 'hidden',
        resize: 'none',
        maxHeight: `${maxHeight}px`,
      }}
      value={props.children}
    />
  );
};

function Textarea(props) {
  if (props.dynamic) return <DynamicTextarea {...props} />;
  return (
    <textarea
      className={"Textarea " + props.className}
      type={props.type}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    >
      {props.children}
    </textarea>
  );
}

export default Textarea;
