import './style.scss';
import { ReactComponent as AnonymousIcon } from './assets/anonymous.svg';
import { ReactComponent as FastIcon } from './assets/fast.svg';
import { ReactComponent as VikingIcon } from './assets/viking.svg';
import { ReactComponent as No1Icon } from './assets/no1.svg';
import { ReactComponent as No2Icon } from './assets/no2.svg';
import { ReactComponent as No3Icon } from './assets/no3.svg';

export const USPsIcons = {
  Anonymous: AnonymousIcon,
  Fast: FastIcon,
  Viking: VikingIcon,
  No1: No1Icon,
  No2: No2Icon,
  No3: No3Icon,
};

function USPs(props) {
  const items = props.items.slice(0, 3);
  const size = ["small", "medium", "large"].includes(props.size) ? props.size : "medium";

  return (
    <div className={"USPs USPs--" + size}>
      {items.map((item, index) => (
        <div className="USPs__item" key={index}>
          {item.icon}
          <div className="USPs__text">{item.text}</div>
        </div>
      ))}
    </div>
  );
}

export default USPs;