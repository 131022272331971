import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { usePageSidebar } from 'hooks/usePageSidebar';
import { useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import CognyAPI from 'components/_classes/CognyAPI';
import Button from 'components/atoms/Button';
import Form from 'components/atoms/Form';
import Field from 'components/molecules/Field';
import useWindowSize from 'hooks/useWindowSize';
import LoadingDots from 'components/atoms/LoadingDots';
import MarkdownBlock from 'components/molecules/MarkdownBlock';
import ErrorBox from 'components/molecules/ErrorBox';
import { useAppData } from 'hooks/useAppData';
import ToolMessage from 'components/organisms/ToolMessage';
const debug = false;

function generateMsgId(length) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 24; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return "msg_" + result;
}

function CopilotForm(props) {
  const { setFormHeight, size, messages, setMessages, chatId, hasOngoingRun, updateCopilotSidebar, setHasOngoingRun } = props;
  const ref = useRef(null);
  const [inputRef, setInputRef] = useState(null);
  const [userMessage, setUserMessage] = useState("");
  const [cookies] = useCookies(["token"]);
  const params = useParams();
  const warehouseId = params.warehouse_id;
  const navigate = useNavigate();
  const { getPath } = useAppData();

  const isLastMessageFromUser = messages.length > 0 && messages[messages.length - 1].role === "user";
  const disabledForm = hasOngoingRun || isLastMessageFromUser;

  useEffect(() => {
    if (ref.current) {
      setFormHeight(ref.current.offsetHeight);
    }
  }, [setFormHeight, size]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (disabledForm || userMessage === "") return;

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);

      const message = {
        role: "user",
        message: userMessage
      };

      const optimisticMessage = {
        ...message,
        status: "optimistic",
        created_at: "now",
        msg_id: generateMsgId(),
      };

      const answerMessage = {
        role: "assistant",
        message: "",
      };

      const optimisticAnswerMessage = {
        ...answerMessage,
        status: "optimistic",
        created_at: "now",
        id: generateMsgId(),
      };

      const lastUserMessage = userMessage;
      setUserMessage("");

      // first add message optimistically
      setMessages([...messages, optimisticMessage, optimisticAnswerMessage]);
      setHasOngoingRun(true);
      if (typeof chatId === "undefined") {
        if (window.dataLayer) {
          window.dataLayer.push({ event: "copilot_session", token: cookies.token });
        }
        api.addCopilotSession(warehouseId, message)
          .then(
            (chat) => {
              props.updateLastChatId("new");
              navigate(getPath("copilot", chat.id));
              updateCopilotSidebar();
            },
            (error) => {
              setUserMessage(lastUserMessage);
              console.log(error);
            }
          )
      } else {
        setHasOngoingRun(true);
        if (window.dataLayer) {
          window.dataLayer.push({ event: "copilot_message", token: cookies.token });
        }
        api.sendCopilotSessionMessage(warehouseId, chatId, message).then(
          () => {
            setMessages(messages.concat(message));
          },
          (error) => {
            setUserMessage(lastUserMessage);
            console.log(error);
          }
        )
      }
    }
  }

  return (
    <div className="CopilotForm" ref={ref}>
      <div className="CopilotForm__inner">
        <Form onSubmit={handleSubmit}>
          <div className="FieldRow">
            <Field
              type="dynamictextarea"
              placeholder="Dear Copilot..."
              onChange={(e) => { setUserMessage(e.target.value) }}
              value={userMessage}
              key="editor1"
              submit={handleSubmit}
              rows={[1, 5]}
              setRef={setInputRef}
            />

            <Button icon={hasOngoingRun ? false : "send"} disabled={hasOngoingRun} type="submit" iconSide="right">
              {hasOngoingRun && <div><LoadingDots /></div>}
              {!hasOngoingRun && <div>Send</div>}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

const CopilotHeader = (props) => {
  const { setHeaderHeight, size, messages, updateCopilotSidebar } = props;
  const ref = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const warehouseId = params.warehouse_id;
  const chatId = params.id;
  const [cookies] = useCookies(["token"]);
  const { getPath } = useAppData();
  const isNewChat = typeof chatId === "undefined";

  useEffect(() => {
    if (ref.current) {
      setHeaderHeight(ref.current.offsetHeight);
    }
  }, [setHeaderHeight, size]);

  let title = "New chat";
  if (!isNewChat) {
    title = messages?.length > 0 ? messages[0].message : "";
  }

  return (
    <div className="CopilotHeader" ref={ref}>
      <div className="CopilotHeader__inner">
        <h1 className="CopilotHeader__name">
          {isNewChat ?
            "New chat"
            :
            title
          }
        </h1>
        {false && !isNewChat &&
          <div className="CopilotHeader__actions">
            <Button className="CopilotHeader__action" iconSide="left" icon="edit">Rename...</Button>
            <Button className="CopilotHeader__action" iconSide="left" icon="star">Add to favorites</Button>
            <Button className="CopilotHeader__action" iconSide="left" icon="trashcan">Discard</Button>
          </div>
        }
        {!isNewChat &&
          <div className="CopilotHeader__actions">
            <Button className="CopilotHeader__action" iconSide="left" icon="trashcan"
              onClick={() => {
                if (cookies.token) {
                  const api = new CognyAPI(cookies.token);
                  api.deleteCopilotSession(warehouseId, chatId)
                    .then(
                      () => {
                        navigate(getPath("copilot"));
                        updateCopilotSidebar();
                      },
                      (error) => {
                        console.log(error);
                      }
                    );
                }
              }}
            >
              Discard
            </Button>
          </div>
        }
      </div>
    </div>
  );
}

const convertTimestampToDateObject = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert to milliseconds
  return date;
}

const convertDateObjectToDatestring = (date) => {
  const pad = (number) => String(number).padStart(2, '0');
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are 0-indexed
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const dateString = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
  return dateString;
}

const convertTimestampToDatestring = (timestamp) => {
  const date = convertTimestampToDateObject(timestamp);
  return convertDateObjectToDatestring(date);
}

const CopilotMessageRawData = (props) => {
  const [open, setOpen] = useState(false);
  const parent = props.parent.current;

  const { data } = props;
  const { role, message, created_at } = data;

  const formatMessage = (message) => {
    if (typeof message === "string") {
      return <>{message}</>;
    } else {
      return JSON.stringify(message, null, 2);
    }
  }

  useEffect(() => {
    if (open) {
      if (parent) {
        parent.style.boxShadow = "0 0 0 2px rgba(255,255,255,.5)";
      }
    } else {
      if (parent) {
        parent.style.boxShadow = "none";
      }
    }
  }, [open, parent])

  return (
    <>
      <button
        style={{
          backgroundColor: 'rgba(0,0,0,.8)',
          color: 'rgba(255,255,255,.7)',
          border: 0,
          padding: 6,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 12,
          lineHeight: 1,
          cursor: 'pointer',
          textDecoration: 'underline',
          position: "absolute",
          top: 0,
          right: 0,
          height: 32,
          zIndex: 2,
        }}
        onClick={() => setOpen(!open)}
      >
        {open ? "Hide message data" : "Show message data"}
      </button>

      <div
        style={{
          display: open ? "block" : "none",
          position: "absolute",
          top: 42,
          right: 0,
          zIndex: 100,
          background: "rgba(0,0,0,0.9)",
          padding: 8,
          paddingTop: 4,
          borderRadius: 4,
          overflow: "auto",
          width: 300,
          fontSize: 12,
          fontFamily: "Inter, sans-serif",
        }}
      >
        <h3
          style={{
            color: "rgba(255,255,255,.5)",
            fontSize: 14,
            margin: 0,
            marginTop: 8,
            marginBottom: 4,
          }}
        >
          Message data
        </h3>
        <div>
          {role} at {created_at}
        </div>

        <pre
          style={{
            background: "rgba(255,255,255,.1)",
            padding: 2,
            overflow: "auto",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            maxHeight: 400,
          }}
        >
          {formatMessage(message)}
        </pre>
      </div>
    </>
  );
}

const CopilotMessage = (props) => {
  const { data, hideHeader, typing } = props;
  const { created_at, role, message } = data;
  const messageRef = useRef(null);

  let dateString = "";
  if (created_at) {
    dateString = convertTimestampToDatestring(created_at);
  }

  if (role === "assistant" && typeof message === "string" && message === "") return <></>;

  return (
    <div
      className={
        "CopilotMessage"
        + (role === "user" ? " CopilotMessage--you" : " CopilotMessage--copilot")
        + (role === "tool" ? " CopilotMessage--tool" : "")
        + (hideHeader ? " CopilotMessage--grouped" : "")
      }
      ref={messageRef}
    >
      {debug && <CopilotMessageRawData data={data} parent={messageRef} />}
      <div
        className={
          "CopilotMessage__header"
          + (hideHeader ? " CopilotMessage__header--hidden" : "")
        }
      >
        <div className="CopilotMessage__sentby">
          <div className="CopilotMessage__avatar"></div>
          <div className="CopilotMessage__name">{role === "user" ? "You" : "Copilot"}</div>
        </div>
        <div className="CopilotMessage__time">{dateString}</div>
      </div>
      <div
        className={
          "CopilotMessage__content"
          + (role === "user" ? " CopilotMessage__content--you" : " CopilotMessage__content--copilot")
          + (role === "tool" ? " CopilotMessage__content--tool" : "")
          + (role === "assistant" ? " CopilotMessage__content--assistant" : "")
        }
      >
        {role === "user" && typeof message === "string" && <MarkdownBlock>{message}</MarkdownBlock>}
        {role === "assistant" && typeof message === "string" && <MarkdownBlock>{message}</MarkdownBlock>}
        {role === "tool" && <ToolMessage message={message}></ToolMessage>}
        {typing && <div className="CopilotMessage__typing"><LoadingDots /></div>}
      </div>
    </div>
  );
}

const CopilotLog = (props) => {
  const { style, messages, setRef, chatId } = props;
  const ref = useRef(null);
  const [scrollState, setScrollState] = useState('');
  const welcomeMessage = {
    "msg_id": "welcome",
    "role": "assistant",
    "message": "Hi! I'm Copilot, a digital assistant here to help make sense of your analytics data.\n\nYou can ask me anything you like, but I'm especially good at answering questions like “Which pages are driving the most leads\" or “How many visitors do we get per week\".\n\nPlease go ahead and ask something."
  };

  useEffect(() => {
    if (ref.current) setRef(ref.current);
  }, [setRef, ref]);

  useEffect(() => {
    const handleScroll = () => {
      const div = ref.current;
      const atTop = div.scrollTop > 0;
      const atBottom = div.scrollTop + div.clientHeight < div.scrollHeight;

      if (atTop && atBottom) {
        setScrollState('CopilotLog--scrolledBoth');
      } else if (atTop) {
        setScrollState('CopilotLog--scrolledTop');
      } else if (atBottom) {
        setScrollState('CopilotLog--scrolledBottom');
      } else {
        setScrollState('');
      }
    };

    const logDiv = ref.current;
    if (logDiv) {
      handleScroll();

      logDiv.addEventListener('scroll', handleScroll);

      return () => {
        logDiv.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const isLastMessageFromUser = messages.length > 0 && messages[messages.length - 1].role === "user";

  return (
    <div className={"CopilotLog " + (scrollState)} style={{ ...style }} ref={ref}>
      {
        chatId && messages.length === 0 ?
          <div className="CopilotLog__loading">
            <LoadingDots />
          </div>
          :
          <>
            <CopilotMessage data={welcomeMessage} />

            {messages?.map((item, index) => {
              const checkGroup = (role) => role === "user" ? "user" : "non-user";
              const currentGroup = checkGroup(item.role);
              const prevMessage = index > 0 ? messages[index - 1] : null;
              const prevGroup = prevMessage ? checkGroup(prevMessage.role) : null;
              const isNotFirstInRow = prevGroup === currentGroup;

              return (
                <CopilotMessage
                  key={item.msg_id}
                  data={item}
                  hideHeader={isNotFirstInRow}
                />
              );
            })}

            {(isLastMessageFromUser) &&
              <CopilotMessage key="typing" typing data={{ role: "assistant", content: [] }} />
            }
          </>
      }
    </div>
  );
}

function CopilotChatDebugger(props) {
  const { data, clearHistory } = props;
  const [size, setSize] = useState(0);

  function handleSizeToggle() {
    setSize((size + 1) % 3);
  }

  function handleClearHistory() {
    clearHistory();
  }

  return (
    <div style={{
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 100,
      padding: 8,
      paddingTop: 4,
      background: "rgba(0,0,0,0.9)",
      borderRadius: 4,
      overflow: "auto",
      height: size === 0 ? 32 : size === 1 ? 100 : 200,
      width: 300,
      fontSize: 12,
      fontFamily: "Inter, sans-serif",
      lineHeight: 1,
    }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "stretch",
          marginBottom: 0,
          height: 20,
          gap: 6,
        }}
      >
        <button
          onClick={handleSizeToggle}
          style={{
            backgroundColor: 'transparent',
            color: 'rgba(255,255,255,.7)',
            border: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 12,
            lineHeight: 1,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Change size
        </button>
        <button
          onClick={handleClearHistory}
          style={{
            backgroundColor: 'transparent',
            color: 'rgba(255,255,255,.7)',
            border: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 12,
            lineHeight: 1,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Clear history
        </button>
      </div>

      {data.map((snapshot, index) => {
        const currentTimestamp = snapshot?.timestamp ?? "";
        const currentMessages = snapshot?.messages ?? {};
        return (
          <details key={index} style={{ marginBottom: 8 }}>
            <summary style={{ cursor: "pointer" }}>
              {currentTimestamp ? new Date(currentTimestamp).toLocaleString() : "empty"}
            </summary>
            <pre style={{ background: "rgba(255,255,255,.1)", padding: 2, overflow: "auto" }}>
              <div style={{ marginBottom: 4 }}>chats: {Object.keys(currentMessages).length}</div>
              {Object.keys(currentMessages).map(chatId => {
                const currentChatMessages = currentMessages[chatId] ?? [];
                return (
                  <div key={chatId}>
                    <div
                      style={{
                        color: "rgba(255,255,255,.5)",
                        borderLeft: 0,
                        display: "flex",
                        alignItems: "center",
                        gap: 4,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        paddingTop: 6,
                        paddingBottom: 0,
                        marginBottom: 4,
                      }}
                    >
                      {chatId}
                    </div>

                    {currentChatMessages.map((msg, index) => {
                      return (
                        <details key={index} style={{ marginBottom: 4 }}>
                          <summary
                            style={{
                              cursor: "pointer",
                              paddingLeft: 8
                            }}
                          >
                            {msg.msg_id} {msg.status ? "- " + msg.status : ""}
                          </summary>
                          <pre>{JSON.stringify(msg, null, 2)}</pre>
                        </details>
                      );
                    })}
                  </div>
                )
              })}
            </pre>
          </details>
        );
      })}
    </div>
  );
}

function Copilot(props) {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [formHeight, setFormHeight] = useState(0);
  const [messages, setMessages] = useState({});
  const [messagesHistory, setMessagesHistory] = useState([]);
  const messagesRef = useRef(messages);
  const [hasOngoingRun, setHasOngoingRun] = useState(false);
  const [chatIsLoading, setChatIsLoading] = useState(false);
  const [chatError, setChatError] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [scrollToBottomAfterNextUpdate, setScrollToBottomAfterNextUpdate] = useState(false);
  const mountedRef = useRef(false);
  const [isChatInitialized, setIsChatInitialized] = useState(false);
  const [chatRef, setChatRef] = useState(null);
  const setChatMessages = (chatId, chatMessages) => setMessages(prevState => ({ ...prevState, [chatId]: chatMessages }));
  const getChatMessages = (chatId) => messages[chatId] ?? [];

  const {
    setItems: setSidebarItems,
    setIsLoaded: setSidebarIsLoaded,
    getIsLoaded: getSidebarIsLoaded,
    setError: setSidebarError,
    getError: getSidebarError,
    setLinkPath,
    setButtonLabel,
    setButtonTarget,
    setEmptyMessage,
    setHasFavorites,
    setTitle,
    setSorting,
  } = usePageSidebar();
  const params = useParams();
  const warehouseId = params.warehouse_id;
  const chatId = params.id;
  const [cookies] = useCookies(["token"]);
  const size = useWindowSize();
  let mountedCopilotSidebarRef = useRef(false);
  const lastChatIdRef = useRef(null);
  const updateLastChatId = (newId) => lastChatIdRef.current = newId;

  const reformatISOTime = (isoTime) => {
    const date = new Date(isoTime);
    return convertDateObjectToDatestring(date);
  }

  const setupSidebar = () => {
    setLinkPath("/copilot");
    setButtonTarget("/copilot");
    setButtonLabel("New chat");
    setEmptyMessage("You don't have any saved chats");
    setHasFavorites(false);
    setTitle("Your chats");
    setSorting([
      {
        key: 'recent',
        label: 'Recent',
        sortFunction: (list) => [...list],
        meta: "created_at",
        metaFormatter: (string) => reformatISOTime(string),
      },
      {
        key: 'alphabetical',
        label: 'A-Z',
        sortFunction: (list) => list.slice().sort((a, b) => {
          // Sort alphabetically by name, but put items without a name at the end
          const aNameMissing = !a.name || a.name === '';
          const bNameMissing = !b.name || b.name === '';

          if (aNameMissing && bNameMissing) return 0;
          if (aNameMissing) return 1;
          if (bNameMissing) return -1;
          return a.name.localeCompare(b.name);
        }),
        meta: "created_at",
        metaFormatter: (string) => reformatISOTime(string),
      },
    ]);
  }

  const updateCopilotSidebar = (signal) => {
    mountedCopilotSidebarRef.current = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.listCopilotSessions(warehouseId, signal)
        .then(
          (result) => {
            if (!mountedCopilotSidebarRef.current) return;
            if (result === null) {
              setSidebarItems([]);
            } else {
              setSidebarItems(result);
            }
            setupSidebar();
            setSidebarIsLoaded(true);
          },
          (error) => {
            if (!mountedCopilotSidebarRef.current) return;
            setSidebarError(error);
            setSidebarIsLoaded(true);
          }
        );
    }
  }

  const clearHistory = () => {
    setMessagesHistory([]);
  }

  function isScrolledToBottom() {
    return window.innerHeight + window.scrollY >= document.body.offsetHeight - 50;
  }

  function scrollToBottom() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }

  function hasMessageUpdates(currentMessages, newMessages) {
    const test = JSON.stringify(currentMessages) !== JSON.stringify(newMessages);
    return test;
  }

  function getChat() {
    if (chatId === "" || typeof chatId === "undefined") return;
    if (!messagesRef.current) return;

    setChatIsLoading(true);
    mountedRef.current = true;

    const api = new CognyAPI(cookies.token);
    api.getCopilotSession(warehouseId, chatId)
      .then(
        (info) => {
          if (!mountedRef.current) return;
          var has_ongoing_run = false;
          var msg_list = info.msgs ?? [];
          if (info.run_steps != null) {
            for (var i = 0; i < info.run_steps?.length; i++) {
              var run_steps = info.run_steps[i];
              if (run_steps.Run.status === "in_progress" || run_steps.Run.status === "queued" || run_steps.Run.status === "requires_action") {
                has_ongoing_run = true;
              }
              if (run_steps.Steps == null) {
                continue;
              }
              for (var j = 0; j < run_steps.Steps.length; j++) {
                // timestamp in seconds
                var created_at = new Date().getSeconds();
                if (run_steps.Steps[j].created_at != null) {
                  created_at = run_steps.Steps[j].created_at;
                }

                if (run_steps.Steps[j].type === "message_creation" && run_steps.Steps[j].status === "completed") {
                  continue
                }

                msg_list.push({ "role": "tool", "message": run_steps.Steps[j], "created_at": created_at, "msg_id": run_steps.Steps[j].id });
              }
            }
          }

          msg_list = msg_list?.sort((a, b) => (a.created_at > b.created_at) ? 1 : -1);

          if (hasMessageUpdates(messagesRef.current[chatId], msg_list) && mountedRef.current) {
            let shouldScrollToBottom = isScrolledToBottom();
            if (isInitialLoad) shouldScrollToBottom = true;
            setScrollToBottomAfterNextUpdate(shouldScrollToBottom);

            setChatMessages(chatId, msg_list);
            setHasOngoingRun(has_ongoing_run);
          }
          setChatIsLoading(false);
          setIsInitialLoad(false);
          setIsChatInitialized(true);
        },
        (error) => {
          if (!mountedRef.current) return;
          setChatError(error);
          setChatIsLoading(false);
          setHasOngoingRun(false);
          setIsInitialLoad(false);
        }
      );
  }

  // every time messages is updated, push the full messages array to a timestamped entry in the history object
  useEffect(() => {
    if (!debug) return;
    setMessagesHistory(
      currentState => {
        return [
          ...currentState,
          {
            timestamp: new Date().toISOString(),
            messages: messages
          }
        ];
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, debug]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (scrollToBottomAfterNextUpdate) {
      // scrolling chat to bottom
      scrollToBottom(chatRef);
    } else {
      // not scrolling chat to bottom
    }

    setScrollToBottomAfterNextUpdate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    updateCopilotSidebar();

    return () => {
      mountedCopilotSidebarRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  useEffect(() => {
    let polling;

    // behave differently if chat id was changed by user or app
    if (lastChatIdRef.current === "new") {
      // app-initiated chat id change
      setIsInitialLoad(false);
      lastChatIdRef.current = null;
    } else {
      // user-initiated chat id change
      setIsInitialLoad(true);
    }
    lastChatIdRef.current = chatId;

    if (cookies.token) {
      if (chatId !== "" && typeof chatId !== "undefined") {
        getChat(chatId);

        polling = setInterval(() => {
          getChat(chatId);
        }, 10000);
      }
    } else {
      mountedRef.current = false;
      setChatError(null);
      setChatIsLoading(false);
      setChatMessages(chatId, []);
      clearInterval(polling);
    }

    return () => {
      mountedRef.current = false;
      setChatError(null);
      setChatIsLoading(false);
      setChatMessages(chatId, []);
      clearInterval(polling);
      // setIsInitialLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, chatId])

  if (chatError) {
    return <ErrorBox error={chatError} />;
  }

  if (getSidebarError()) {
    return <ErrorBox error={getSidebarError()} />;
  }

  if (!getSidebarIsLoaded()) {
    return <div className="Copilot"></div>;
  }

  return (
    <div className="Copilot">
      {debug && <CopilotChatDebugger data={messagesHistory} clearHistory={clearHistory} />}
      {
        chatIsLoading && (isInitialLoad || !isChatInitialized) && lastChatIdRef.current !== "new" ?
          <div className="Copilot__loading"><LoadingDots /></div>
          :
          <>
            <CopilotHeader
              setHeaderHeight={setHeaderHeight}
              size={size}
              messages={getChatMessages(chatId)}
              updateCopilotSidebar={updateCopilotSidebar}
            />
            <CopilotLog
              chatId={chatId}
              hasOngoingRun={hasOngoingRun}
              chatIsLoading={chatIsLoading}
              messages={getChatMessages(chatId)}
              setRef={setChatRef}
              style={{
                paddingTop: headerHeight + 32,
                paddingBottom: formHeight + 32
              }}
            />
          </>
      }
      <CopilotForm
        setFormHeight={setFormHeight}
        messages={getChatMessages(chatId)}
        setMessages={setChatMessages}
        size={size}
        chatId={chatId}
        hasOngoingRun={hasOngoingRun}
        updateCopilotSidebar={updateCopilotSidebar}
        updateLastChatId={updateLastChatId}
        setHasOngoingRun={setHasOngoingRun}
      />
    </div>
  );
}

export default Copilot;
