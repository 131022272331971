import './style.scss';
import { LineChart, Line, Label, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';

function labelFormatter(value) {
  switch (value) {
    case 'processing':
      return 'Processing';
    case 'data_sources':
      return 'Data sources';
    case 'tracking_server':
      return 'Tracking server';
    case 'GB':
      return 'Gigabytes';
    default:
      return value;
  }
}

function dateFormatter(dateString, shortMonthFormat = false) {
  // Regular expression to match YYYY-MM format
  const yearMonthRegex = /^\d{4}-\d{2}$/;

  // Check if dateString matches the YYYY-MM format
  if (yearMonthRegex.test(dateString)) {
    // Extract the year and month
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5);

    // Array of month names
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    // Convert month number to month name
    let monthName = monthNames[parseInt(month) - 1];

    // If shortMonthFormat is true, use only the first three characters of the month name
    if (shortMonthFormat) {
      monthName = monthName.substring(0, 3);
    }

    // Return the reformatted date string
    return `${monthName} ${year}`;
  }

  // If the format is not YYYY-MM, return the original string
  return dateString;
}

const DashboardChart = (props) => {
  return (
    <div className="DashboardChart">
      {props.title && <h2 className="DashboardChart__title">{props.title}</h2>}
      {props.description && <div className="DashboardChart__description">{props.description}</div>}
      {props.children && <div className="DashboardChart__chart">{props.children}</div>}
    </div>
  );
}

const CustomXTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#555" transform="translateX(-50%)" style={{ fontSize: '10px' }}>
        {dateFormatter(payload.value, true)}
      </text>
    </g>
  );
};

const CustomYTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="end" fill="#555" transform="" style={{ fontSize: '10px' }}>
        {payload.value}
      </text>
    </g>
  );
};

const CustomReferenceLineLabel = (props) => {
  const { viewBox } = props;
  return (
    <text
      x={viewBox.x + viewBox.width - 8}
      y={viewBox.y}
      dy={12}
      fill={props.fill}
      textAnchor="end"
      style={{
        fontSize: 12,
        fontWeight: 500,
        filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5)) drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.5)) drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.5)) drop-shadow(-1px 0px 1px rgba(0, 0, 0, 0.5))",
      }}>
      {props.value}
    </text>
  );
};

const CustomLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="DashboardChart__legend">
      {payload.map((entry, index) => (
        <li
          className="DashboardChart__legend-item"
          key={`item-${index}`}
          style={{ color: entry.color }}
        >
          <span
            className="DashboardChart__legend-dot"
            style={{ backgroundColor: entry.color }}
          />
          <span style={{ lineHeight: "1em", fontSize: 12 }}>{labelFormatter(entry.value)}</span>
        </li>
      ))}
    </ul>

  );
};

const CustomTooltip = (props) => {
  const { active, payload, label, unit, title } = props;
  if (active && payload && payload.length) {
    return (
      <div className="DashboardChart__tooltip" style={{ backgroundColor: 'black', padding: 16 }}>
        <div className="DashboardChart__tooltip-title" style={{ fontWeight: 600, fontSize: 12 }}>{title} {dateFormatter(label)}</div>
        <div className="DashboardChart__tooltip-content">
          {payload.map(item => {
            return (
              <div
                key={item.name}
                style={{
                  color: item.color,
                  lineHeight: "1em",
                  fontSize: 12,
                  display: "flex",
                  alignItems: "center",
                  marginTop: 6,
                  gap: 4
                }}
              >
                <span>{labelFormatter(item.name)}</span>
                <span style={{
                  backgroundColor: item.color,
                  borderRadius: 16,
                  paddingLeft: 6,
                  paddingRight: 6,
                  paddingTop: 2,
                  paddingBottom: 2,
                  fontWeight: 600,
                  color: "rgba(0,0,0,.8)",
                  fontSize: 11,
                }}>{item.value} {unit}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};

function DashboardCharts(props) {
  const { warehouse, processing, credits } = props.data;

  return (
    <div className="DashboardCharts">
      {warehouse.status === 'Active' &&
        <div className="DashboardCharts__charts">
          {credits.credit_usage &&
            <DashboardChart title="Credit usage" description="This shows where your credits has been spent over the last quarter.">
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  data={credits.credit_usage}
                  margin={{
                    top: 5,
                    right: 0,
                    left: -20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid stroke="#222" />
                  <XAxis
                    dataKey="month"
                    tick={CustomXTick}
                  />
                  <YAxis
                    domain={([dataMin, dataMax]) => { return [dataMin, Math.max(dataMax, warehouse.included_credits + 100)]; }}
                    tick={CustomYTick}
                  />
                  <Tooltip content={<CustomTooltip title="" unit="credits" />} cursor={{ fill: "rgba(255,255,255,.05)" }} />
                  <Legend content={CustomLegend} />
                  <Bar type="montone" dataKey="processing" stackId="a" fill="#A7CDF1" radius={[0, 0, 2, 2]} />
                  <Bar type="montone" dataKey="data_sources" stackId="a" fill="#8884d8" />
                  <Bar type="montone" dataKey="tracking_server" stackId="a" fill="#FFD600" radius={[2, 2, 0, 0]} />
                  <ReferenceLine y={credits.included_credits} stroke="#99E5B7" strokeDasharray="3 3" strokeWidth={2}>
                    <Label content={CustomReferenceLineLabel} value="Included Credits" position="insideTopRight" fill="#99E5B7" />
                  </ReferenceLine>
                </BarChart>
              </ResponsiveContainer>
            </DashboardChart>
          }

          {processing.data &&
            <DashboardChart title="Data processing" description="How many gigabytes of data your account has processed.">
              <ResponsiveContainer width="100%" height={250}>
                <LineChart
                  data={processing.data}
                  margin={{
                    top: 5,
                    right: 0,
                    left: -20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid stroke="#222" />
                  <XAxis dataKey="Date" tick={CustomXTick} />
                  <YAxis tick={CustomYTick} />
                  <Tooltip content={<CustomTooltip title="" unit="GB" />} />
                  <Legend content={CustomLegend} />
                  <Line type="montone" dataKey="GB" stroke="#A7CDF1" dot={false} strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </DashboardChart>
          }
        </div>
      }
    </div>
  );
}

export default DashboardCharts;
