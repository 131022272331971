import './style.scss';
import Tabs from 'components/molecules/Tabs';
import Icon from 'components/atoms/Icon';
import SettingsUsers from 'components/organisms/SettingsUsers';
import SettingsBilling from 'components/organisms/SettingsBilling';
import { useAppData } from 'hooks/useAppData';

function SettingsPage(props) {
  const { getPath } = useAppData();

  var subpath = "users";

  return (
    <div className="SettingsPage">
      <div className="SettingsPage__welcome">
        <h1 className="SettingsPage__title">Settings</h1>
      </div>
      <Tabs active={subpath} basepath={getPath("settings") + "/"}>
        <div icon={<Icon icon="user" className="Tab__Icon" />} label="Users" slug="users">
          <SettingsUsers />
        </div>
        <div icon={<Icon icon="approve" className="Tab__Icon" />} label="Billing" slug="billing">
          <SettingsBilling />
        </div>
      </Tabs>
    </div>
  );
}

export default SettingsPage;
