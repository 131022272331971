import { useEffect, useState } from 'react';
import { useDependencies } from 'hooks/useDependecies';

export const useCheckAccess = (pageKey, warehouseId) => {
  const { checkAccess } = useDependencies();
  const [hasAccess, setHasAccess] = useState(null);

  useEffect(() => {
    const verifyAccess = async () => {
      if (pageKey === null) return;

      const access = await checkAccess(pageKey, warehouseId);
      setHasAccess(access);
    };
    verifyAccess();
  }, [checkAccess, pageKey, warehouseId]);

  return hasAccess;
};
