import React, { useState } from 'react';
import './style.scss';
import ErrorBox from 'components/molecules/ErrorBox';
import ModalDialog from 'components/molecules/ModalDialog';

function ErrorWidget(props) {
  const [modalState, setModalState] = useState({
    open: false,
    sent: false,
    primary: {
      label: "",
      onClick: () => { }
    },
  });

  const updateModalState = (newState) => {
    setModalState(currentState => ({ ...currentState, ...newState }));
  }

  return (
    <div className="ErrorWidget">
      <ModalDialog
        size="small"
        className="ErrorWidget__toggle"
        title="Report a bug"
        label="Report a bug"
        modalTitle={!modalState.sent ? "Report a bug" : "Your bug report has been sent"}
        state={{ modalState, updateModalState }}
        toolbar={
          !modalState.sent ?
            {
              primary: {
                ...modalState.primary
              },
              secondary: {
                label: "Cancel",
                onClick: () => { updateModalState({ ...modalState, open: false }) }
              }
            }
            :
            false
        }
      >
        <ErrorBox modal modalClass="ErrorWidget" state={{ modalState, updateModalState }} />
      </ModalDialog>
    </div>
  );
}

export default ErrorWidget;