import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Page from 'components/organisms/Page';

import NotFound from 'pages/NotFound';

import ValidateWarehouseId from 'components/organisms/ValidateWarehouseId';

import Billing from 'pages/Billing';

import AddAccount from 'components/molecules/AddAccount';

import Home from 'pages/Home';
import HomeView from 'components/organisms/HomeView';

import Dashboard from 'pages/Dashboard';
import DashboardMain from 'components/organisms/DashboardMain';

import Warehouses from 'pages/Warehouses';
import SetupWarehouse from 'components/molecules/SetupWarehouse';

import SSGTM from 'pages/SSGTM';
import SSGTMList from 'components/organisms/SSGTMList';
import SSGTMGuide from 'components/organisms/SSGTMGuide';
import SSGTMDetails from 'components/organisms/SSGTMDetails';

import Datasources from 'pages/Datasources';
import DatasourceSidebar from 'components/molecules/DatasourceSidebar';
import DatasourceGuide from 'components/organisms/DatasourceGuide';
import DatasourceDetails from 'components/organisms/DatasourceDetails';

import Monitor from 'pages/Monitor';

import Copilot from 'pages/Copilot';

import ImpactAnalysisPage from 'pages/ImpactAnalysis';
import ImpactAnalysisList from 'components/organisms/ImpactAnalysisList';
import ImpactAnalysisDetail from 'components/organisms/ImpactAnalysisDetail';
import ImpactAnalysisAdd from 'components/organisms/ImpactAnalysisAdd';

import DataVis from 'pages/DataVis';
import DataVisForm from 'components/organisms/DataVisForm';

import Settings from 'pages/Settings';
import SettingsPage from 'components/organisms/SettingsPage';
import SettingsAddUser from 'components/organisms/SettingsAddUser';

import Legal from 'pages/Legal';
import Terms from 'outside/pages/Terms';
import Privacy from 'outside/pages/Privacy';

import Report from 'pages/Report';

import Testing from 'pages/Testing';

const routes = [
  {
    path: "*",
    element: (
      <Page
        title="Not found"
        size="small"
        sidebar={false}
        isError
      >
        <NotFound />
      </Page>
    )
  },
  {
    index: true,
    element: (
      <Navigate to="home" replace />
    )
  },
  {
    path: "home",
    element: (
      <Page
        title="Home"
        sidebar={false}
      >
        <Home>
          <HomeView />
        </Home>
      </Page>
    )
  },
  {
    path: "new_account",
    element: (
      <Page
        title="New account"
        sidebar={false}
      >
        <Warehouses>
          <AddAccount />
        </Warehouses>
      </Page>
    )
  },
  {
    path: "terms",
    element: (
      <Page
        title="Terms"
        sidebar={false}
      >
        <Legal>
          <Terms raw />
        </Legal>
      </Page>
    )
  },
  {
    path: "privacy",
    element: (
      <Page
        title="Privacy"
        sidebar={false}
      >
        <Legal>
          <Privacy raw />
        </Legal>
      </Page>
    )
  },
  {
    path: ":warehouse_id",
    element: (
      <ValidateWarehouseId
        failed={
          <Page
            title="Not found"
            size="small"
            sidebar={false}
            isError
          >
            <NotFound />
          </Page>
        }
      >
        <Outlet />
      </ValidateWarehouseId>
    ),
    children: [
      {
        index: true,
        element: (
          <Navigate to="dashboard" replace />
        )
      },
      {
        path: "billing",
        element: (
          <Page
            title="Billing"
          >
            <Billing />
          </Page>
        )
      },
      {
        path: "setup_warehouse",
        element: (
          <Page
            title="Setup warehouse"
            checkAccess="warehouse"
            size="small"
          >
            <Dashboard>
              <SetupWarehouse />
            </Dashboard>
          </Page>
        )
      },
      {
        path: "dashboard",
        element: (
          <Page
            title="Dashboard"
            size="large"
          >
            <Dashboard>
              <DashboardMain />
            </Dashboard>
          </Page>
        )
      },
      {
        path: "server-side-gtm",
        element: (
          <Outlet />
        ),
        children: [
          {
            index: true,
            element: (
              <Page
                title="Server-side tracking"
                checkAccess="sst"
                size="medium"
              >
                <SSGTM>
                  <SSGTMList />
                </SSGTM>
              </Page>
            )
          },
          {
            path: "new",
            element: (
              <Page
                title="New server-side tracker"
                checkAccess="sst"
                size="medium"
              >
                <SSGTM>
                  <SSGTMGuide />
                </SSGTM>
              </Page>
            )
          },
          {
            path: ":id",
            element: (
              <Page
                title="Edit server-side tracker"
                checkAccess="sst"
                size="medium"
              >
                <SSGTM>
                  <SSGTMDetails />
                </SSGTM>
              </Page>
            )
          },
        ]
      },
      {
        path: "monitor",
        element: (
          <Outlet />
        ),
        children: [
          {
            index: true,
            element: (
              <Page
                title="GDPR monitoring"
                checkAccess="billing"
                size="large"
              >
                <Monitor />
              </Page>
            )
          },
          {
            path: "new",
            element: (
              <Page
                title="New GDPR monitor"
                checkAccess="billing"
                size="large"
              >
                <Monitor add />
              </Page>
            )
          },
          {
            path: ":id",
            element: (
              <Page
                title="View compliance monitor"
                checkAccess="billing"
                size="large"
              >
                <Monitor />
              </Page>
            )
          },
        ]
      },
      {
        path: "datasources",
        element: (
          <Outlet />
        ),
        children: [
          {
            index: true,
            element: (
              <Page
                title="Data sources"
                pageSidebar={<DatasourceSidebar />}
                checkAccess="datasources"
              >
                <Datasources>
                  <DatasourceGuide />
                </Datasources>
              </Page>
            )
          },
          {
            path: "new",
            element: (
              <Page
                title="Add data source"
                pageSidebar={<DatasourceSidebar />}
                checkAccess="datasources"
              >
                <Datasources>
                  <DatasourceGuide />
                </Datasources>
              </Page>
            )
          },
          {
            path: ":id",
            element: (
              <Page
                title="Data source details"
                pageSidebar={<DatasourceSidebar />}
                checkAccess="datasources"
              >
                <Datasources>
                  <DatasourceDetails />
                </Datasources>
              </Page>
            )
          },
        ]
      },
      {
        path: "copilot",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: (
              <Page
                title="Copilot"
                checkAccess="copilot"
                pageSidebar="copilot"
              >
                <Copilot />
              </Page>
            )
          },
          {
            path: ":id",
            element: (
              <Page
                title="Copilot"
                checkAccess="copilot"
                pageSidebar="copilot"
              >
                <Copilot />
              </Page>
            )
          },
        ]
      },
      {
        path: "datavis",
        element: (
          <Page
            title="Data visualization"
            checkAccess="datavis"
            size="medium"
          >
            <DataVis>
              <DataVisForm />
            </DataVis>
          </Page>
        )
      },
      {
        path: "impact",
        element: (
          <Outlet />
        ),
        children: [
          {
            index: true,
            element: (
              <Page
                title="Impact analysis"
              >
                <ImpactAnalysisPage>
                  <ImpactAnalysisList />
                </ImpactAnalysisPage>
              </Page>
            )
          },
          {
            path: "new",
            element: (
              <Page
                title="Add impact analysis"
              >
                <ImpactAnalysisPage>
                  <ImpactAnalysisAdd />
                </ImpactAnalysisPage>
              </Page>
            )
          },
          {
            path: ":id",
            element: (
              <Page
                title="Impact analysis details"
              >
                <ImpactAnalysisPage>
                  <ImpactAnalysisDetail />
                </ImpactAnalysisPage>
              </Page>
            )
          },
        ]
      },
      {
        path: "settings/*",
        element: (
          <Page
            title="Settings"
          >
            <Settings>
              <SettingsPage />
            </Settings>
          </Page>
        )
      },
      {
        path: "access",
        element: (
          <Outlet />
        ),
        children: [
          {
            index: true,
            element: (
              <Navigate to="new" replace />
            )
          },
          {
            path: "new",
            element: (
              <Page
                title="Add user"
              >
                <Settings>
                  <SettingsAddUser />
                </Settings>
              </Page>
            )
          },
        ]
      },
      {
        path: "report",
        element: (
          <Page
            title="Report"
            size="medium"
          >
            <Report />
          </Page>
        )
      },
      {
        path: "testing",
        element: (
          <Outlet />
        ),
        children: [
          {
            index: true,
            element: (
              <Page
                title="Testing"
                pageSidebar="testing"
              >
                <Testing />
              </Page>
            )
          },
          {
            path: "new",
            element: (
              <Page
                title="New testing"
                pageSidebar="testing"
              >
                <Testing />
              </Page>
            )
          },
          {
            path: ":id",
            element: (
              <Page
                title="Testing"
                pageSidebar="testing"
              >
                <Testing />
              </Page>
            )
          },
        ]
      },

    ]
  }
];

export default routes;