import './style.scss';

function OutsideBox(props) {
  const classBase = props.classBase ? props.classBase.split(" ") : [];
  let classNames = "";

  classBase.forEach(function (item) {
    if (item.length) classNames = classNames + item + "__outer ";
  });

  classNames = classNames + "OutsideBox ";

  if (props.nobg) {
    classNames = classNames + "OutsideBox--nobg ";
  }

  return (
    <div className={classNames}>
      {props.children}
    </div>
  );
}

export default OutsideBox;
