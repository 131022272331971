import './style.scss';

function Input(props) {
  const { className = "", type = "text", name, id, placeholder, onChange, value, disabled, inputAttributes } = props;
  return (
    <input
      className={"Input " + className}
      key={id}
      type={type}
      name={name}
      id={id}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      disabled={disabled}
      {...inputAttributes}
    />
  );
}

export default Input;
