import './style.scss';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as Burger } from './burger.svg';
import { useEffect, useRef, useState } from 'react';
import Icon from 'components/atoms/Icon';

const menuItemsData = [
  {
    label: "Features",
    children: [
      {
        label: "AI data analyst",
        href: "/",
      },
      {
        label: "GDPR monitor",
        href: "/gdpr",
      },
      {
        label: "Server-side tracker",
        href: "/sst",
      },
    ],
  },
  {
    label: "Pricing",
    href: "/pricing",
  },
];

const scrollToTop = () => {
  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }
};

const OutsideMenuItem = (props) => {
  const { item, onClick } = props;
  const [open, setOpen] = useState(false);
  const itemId = "item_" + item.label.toLowerCase().replace(" ", "-");
  const location = useLocation();
  const menuRef = useRef(null);

  const isActiveLink = (path) => {
    const currentPath = location.pathname;
    return currentPath.includes(`/sv${path}`) || currentPath === path;
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("click", handleOutsideClick);
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  return (
    <div className="OutsideMenuItem" ref={menuRef}>
      {
        item.children ?
          <>
            <button
              className={"OutsideMenuItem__link" + (open ? " OutsideMenuItem__link--toggled" : "")}
              aria-expanded={open ? "true" : "false"}
              aria-haspopup="true"
              aria-controls={itemId}
              onClick={() => {
                setOpen(prev => !prev);
              }}
            >
              <span className="OutsideMenuItem__label">{item.label}</span>
              <Icon
                icon="chevronDown"
                className="OutsideMenuItem__chevron"
              />
            </button>

            <div className="OutsideMenuItem__dropdown" id={itemId}>
              {item.children.map((child, childIndex) => {
                return (
                  <NavLink
                    className={
                      ({ isActive }) =>
                        "OutsideMenuItem__dropdown-link"
                        + ((isActive || isActiveLink(item.href)) ? " OutsideMenuItem__dropdown-link--current" : "")
                    }
                    to={child.href}
                    key={childIndex}
                    onClick={() => {
                      onClick && onClick();
                      scrollToTop();
                      setOpen(false);
                    }}
                  >
                    <span>{child.label}</span>
                  </NavLink>
                );
              })}
            </div>
          </>
          :
          <>
            <NavLink
              className={
                ({ isActive }) =>
                  "OutsideMenuItem__link"
                  + ((isActive || isActiveLink(item.href)) ? " OutsideMenuItem__link--current" : "")
              }
              to={item.href}
              onClick={() => {
                scrollToTop();
              }}
            >
              <span className="OutsideMenuItem__label">{item.label}</span>
            </NavLink>
          </>
      }
    </div>
  );
}

const OverlayMenuItem = (props) => {
  const { item, onClick } = props;
  const location = useLocation();
  const itemId = "item_" + item.label.toLowerCase().replace(" ", "-");

  const isActiveLink = (path) => {
    const currentPath = location.pathname;
    return currentPath.includes(`/sv${path}`) || currentPath === path;
  };

  return (
    <div className="OverlayMenuItem">
      {
        item.children ?
          <>
            <div className={"OverlayMenuItem__parent"}>
              <span>{item.label}</span>
            </div>

            <div className="OverlayMenuItem__children" id={itemId}>
              {item.children.map((child, childIndex) => {
                return (
                  <NavLink
                    className={
                      ({ isActive }) =>
                        "OverlayMenuItem__link"
                        + ((isActive || isActiveLink(child.href)) ? " OverlayMenuItem__link--current" : "")
                    }
                    to={child.href}
                    key={childIndex}
                    onClick={() => {
                      onClick && onClick();
                      scrollToTop();
                    }}
                  >
                    <span className="OverlayMenuItem__label">{child.label}</span>
                  </NavLink>
                );
              })}
            </div>
          </>
          :
          <>
            <NavLink
              className={
                ({ isActive }) =>
                  "OverlayMenuItem__link"
                  + ((isActive || isActiveLink(item.href)) ? " OverlayMenuItem__link--current" : "")
              }
              to={item.href}
              onClick={() => {
                onClick && onClick();
                scrollToTop();
              }}
            >
              <span className="OverlayMenuItem__label">{item.label}</span>
            </NavLink>
          </>
      }
    </div>
  );
}

const OutsideMenu = (props) => {
  const { navOpen, setNavOpen } = props.toggle;

  return (
    <div className="OutsideMenu">
      <div className="OutsideMenu__menu">
        <button
          className={"OutsideMenu__toggle" + (navOpen ? " OutsideMenu__toggle--toggled" : "")}
          aria-expanded={navOpen ? "true" : "false"}
          aria-haspopup="true"
          aria-controls="outside-menu-overlay"
          onClick={() => setNavOpen(prev => !prev)}
        >
          <Burger />
          <span>Toggle Menu</span>
        </button>

        <div className="OutsideMenu__links">
          {menuItemsData.map((item, index) =>
            <OutsideMenuItem
              key={index}
              item={item}
              onClick={() => setNavOpen(false)}
            />
          )}
        </div>

        <Link className="OutsideMenu__signin" to="/login"><span>Sign in</span></Link>
      </div>

      <div
        className={"OutsideMenu__overlay" + (navOpen ? " OutsideMenu__overlay--open" : "")}
        id="outside-menu-overlay"
      >
        <div className="OutsideMenu__overlay-backdrop"></div>
        <div className="OutsideMenu__overlay-menu">
          {menuItemsData.map((item, index) =>
            <OverlayMenuItem
              key={index}
              item={item}
              onClick={() => setNavOpen(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function OutsideHeader(props) {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <header className="OutsideHeader">
      <div className="OutsideHeader__inner">
        <NavLink
          onClick={
            () => {
              scrollToTop();
              if (window.innerWidth < 768) {
                setNavOpen(false);
              }
            }
          }
          className="OutsideHeader__logo"
          to="/"
        >
          <span>Cogny</span>
        </NavLink>

        <OutsideMenu toggle={{ navOpen, setNavOpen }} />

      </div>
    </header>
  );
}

export default OutsideHeader;
