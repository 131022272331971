import './style.scss';
import YouTube from 'react-youtube';
import ReactMarkdown from 'react-markdown';

function DashboardInfo(props) {
  const { title, body, youtube, image } = props.data;
  const { children } = props;
  const onClick = props.onClick;

  return (
    <div className="DashboardInfo">
      <div className="DashboardInfo__inner">
        <div className="DashboardInfo__content">
          {title && <h2 className="DashboardInfo__title">{title}</h2>}
          {body && <div className="DashboardInfo__body"><ReactMarkdown children={body} /></div>}
          {children && <div className="DashboardInfo__body">{children}</div>}
          {false && <button className="DashboardInfo__toggle" onClick={onClick}>Hide this message</button>}
        </div>
        <div className="DashboardInfo__media">
          {youtube && <YouTube videoId={youtube} className="DashboardInfo__video" />}
          {image && <img src={image} alt="" />}
        </div>
      </div>
    </div>
  );
}

export default DashboardInfo;
