import './style.scss';
import useBodyClass from 'hooks/useBodyClass';
import BlogPosts from 'outside/components/BlogPosts';
import FAQ, { faqData } from 'outside/components/FAQ';
import CTAHero from 'outside/components/CTAHero';
import Customers from 'outside/components/Customers';
import Integrations from 'outside/components/Integrations';
import USPs, { USPsIcons } from 'outside/components/USPs';
import Features, { FeaturesIcons } from 'outside/components/Features';
import PricingTiers from 'outside/components/PricingTiers';

function SST(props) {
  const lang = props.lang;
  useBodyClass('outside-wide');

  const formatNumber = (num) => {
    const formatWithSuffix = (num, divisor, suffix) => {
      const dividedNum = num / divisor;
      // Check if the number is whole (no decimals) after division
      if (Math.floor(dividedNum) === dividedNum) {
        return dividedNum + suffix; // Return without decimal point
      } else {
        return dividedNum.toFixed(1) + suffix; // Format to one decimal place and add suffix
      }
    }

    if (num >= 1000000000) {
      return formatWithSuffix(num, 1000000000, 'b');
    } else if (num >= 1000000) {
      return formatWithSuffix(num, 1000000, 'm');
    } else if (num >= 1000) {
      return formatWithSuffix(num, 1000, 'k');
    } else {
      return num.toString(); // Convert to string if less than 1000
    }
  }

  const meta_en = {
    starts_at: "Starts at",
    event_capacity: "Event capacity",
    per_month: <>per<br /> month</>,
    credit_usage: "Cogny credit consumed",
    extra_cost: "Additional credits cost",
    per_credit: <>per<br /> credit</>,
    vat_excl: "Prices excl VAT",
  }

  const meta_sv = {
    event_capacity: "Eventkapacitet",
    per_month: <>per<br /> månad</>,
    credit_usage: "Cogny credit-användning",
    extra_cost: "Extra kostnad för credits",
    per_credit: <>per<br /> credit</>,
    vat_excl: "Priser exkl moms",
  }

  const meta = lang === "sv" ? meta_sv : meta_en;

  const generateSections = (tier) => [
    {
      tier: tier.name.charAt(0).toUpperCase() + tier.name.slice(1),
      type: "header"
    },
    {
      type: "price",
      title: meta.starts_at,
      value: "€" + tier.price,
      unit: meta.per_month,
      main: true
    },
    {
      type: "capacity",
      title: meta.event_capacity,
      value: formatNumber(tier.events),
      unit: meta.per_month,
      main: false
    },
    {
      type: "usage",
      title: meta.credit_usage,
      value: tier.credits,
      unit: meta.per_month
    },
    {
      type: "CTA",
      terms: meta.vat_excl,
    }
  ];

  return (
    <div className="SST">
      <CTAHero
        lang={lang}
        title={
          lang === "sv" ?
            "Server-side tracking utan krångel"
            :
            "Make server-side tracking a breeze"
        }
        tagline={
          lang === "sv" ?
            "Gå med idag för att förbättra användarupplevelsen och öka säkerheten"
            :
            "Sign up today to speed up your user-experience and increase integrity"
        }
        cta="hubspot"
        usps={
          <USPs
            items={
              lang === "sv" ?
                [
                  {
                    icon: <USPsIcons.Viking />,
                    text: <>Made in<br /> Sweden</>,
                  },
                  {
                    icon: <USPsIcons.Anonymous />,
                    text: <>Gratis<br /> anonymisering</>,
                  },
                  {
                    icon: <USPsIcons.Fast />,
                    text: <>Installera på<br /> 20 minuter</>,
                  }
                ]
                :
                [
                  {
                    icon: <USPsIcons.Viking />,
                    text: <>Made in<br /> Sweden</>,
                  },
                  {
                    icon: <USPsIcons.Anonymous />,
                    text: <>Free<br /> anonymization</>,
                  },
                  {
                    icon: <USPsIcons.Fast />,
                    text: <>20 minute<br /> install</>,
                  }
                ]
            }
          />
        }
      />

      <Customers lang={lang} />

      <Features
        items={
          lang === "sv" ?
            [
              {
                preTitle: <>Cookie-säkerhet</>,
                icon: <FeaturesIcons.Cookies className="Features__icon" />,
                title: <>Förstaparts-cookies<br /> smakar bättre</>,
              },
              {
                preTitle: <>Jättesnabbt</>,
                icon: <FeaturesIcons.GDPR className="Features__icon" />,
                title: <>Snabbare sidhastighet<br /> med server-side-script</>,
              },
              {
                preTitle: <>Följ GDPR-reglerna</>,
                icon: <FeaturesIcons.Pagespeed className="Features__icon" />,
                title: <>Sluta oroa er över<br /> EU-reglerna</>,
              }
            ]
            :
            [
              {
                preTitle: <>Cookie<br /> security</>,
                icon: <FeaturesIcons.Cookies className="Features__icon" />,
                title: <>First-party cookies<br /> just taste better</>,
              },
              {
                preTitle: <>Blazing<br /> fast</>,
                icon: <FeaturesIcons.GDPR className="Features__icon" />,
                title: <>Increase site-speed<br /> with server-side scripts</>,
              },
              {
                preTitle: <>GDPR<br /> compliant</>,
                icon: <FeaturesIcons.Pagespeed className="Features__icon" />,
                title: <>Stop worrying about<br /> EU regulation</>,
              }
            ]
        }
      />

      <Integrations lang={lang} />

      <FAQ faq={lang === "sv" ? faqData.sst.sv : faqData.sst.en} />

      <BlogPosts
        filter={[10, 11]}
        cols={2}
        title={
          lang === "sv" ?
            "Mer på vår blogg"
            :
            "More on our blog"
        }
        description={
          lang === "sv" ?
            "Läs våra jämförelser mellan Cogny och andra produkter"
            :
            "Read our comparisons of Cogny and other products"
        }
      />

      <PricingTiers
        lang={lang}
        title={
          lang === "sv" ?
            "Priser"
            :
            "Pricing"
        }
        intro={
          lang === "sv" ?
            <>
              I Cogny använder du credits för att få tillgång till funktioner, med kostnader baserade på datavolym och användning.<br />
              Detta är vad varje paket erbjuder – välj det som passar dig bäst.
            </>
            :
            <>
              In Cogny, using features consumes credits, with costs based on data volume and usage.<br />
              This is what each tier offers – choose the one that best fits your needs.
            </>
        }
        features={{
          title: lang === "sv" ? "Behöver du mer än en server-side tracker?" : "Do you need more than one server-side tracker?",
          content: <>You can set up 12 micro-sized trackers on one small tier as every micro server uses 75 credits, 12 x 75 = 900 credits will be used every month of the 900 included in the small tier. By doing this instead of setting up 12 micro á 9 EUR you get a 8% discount on you set up.</>,
        }}
        generateSections={generateSections}
        theme="gray"
      />

    </div>
  );
}

export default SST;
