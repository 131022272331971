import './style.scss';
import OutsideBox from 'outside/components/OutsideBox';
import { useState, useRef, useEffect } from 'react';
import { ReactComponent as SvgLegend } from './legend.svg';
import { ReactComponent as SvgLabels } from './labels.svg';
import { ReactComponent as SvgLabelsDiag } from './labels_diag.svg';

const SvgChart = (props) => {
  const [width] = useState(680);
  const [height] = useState(280);
  const [rows] = useState(8);
  const [top, setTop] = useState(null);
  const { alt, className } = props;

  useEffect(() => {
    setTop(height / rows);
  }, [height, rows])

  const round = (num) => {
    return Math.round(num * 1000) / 1000;
  }

  const bars = [
    {
      stroke: "#A77AFF",
      ya: 260,
      yb: 34,
    },
    {
      stroke: "#9D63FE",
      ya: 210,
      yb: 85,
    },
    {
      stroke: "#924DFC",
      ya: 185,
      yb: 160,
    },
    {
      stroke: "#8836FB",
      ya: 110,
      yb: 185,
    },
    {
      stroke: "#7D1FF9",
      ya: 70,
      yb: 210,
    },
    {
      stroke: "#7711F8",
      ya: 34,
      yb: 260,
    }
  ];

  const barXs = (index) => {
    const distance = width / bars.length;
    let lines = [];
    for (let x = 1; x <= bars.length; x++) {
      const line = round((distance * x) - (distance / 2));
      lines.push(line);
    }
    return typeof index === "undefined" ? lines : lines[index];
  }

  const vLines = () => {
    const distance = width / bars.length;
    let lines = [];
    for (let x = 1; x < bars.length; x++) {
      const line = {
        x1: round(distance * x),
        x2: round(distance * x),
        y1: 0,
        y2: height,
      }
      lines.push(line);
    }
    return lines;
  }

  // const hLines = () => {
  //   const distance = height / rows;
  //   let lines = [];
  //   for (let x = 1; x < 8; x++) {
  //     const line = {
  //       x1: 0,
  //       x2: 8,
  //       y1: round(distance * x),
  //       y2: round(distance * x),
  //     }
  //     lines.push(line);
  //   }
  //   return lines;
  // }

  const valueLine = () => {
    const pointY = [252, 170, 188, 97, 61, 26];
    const barX = barXs();
    let points = [];
    for (let x = 0; x < barX.length; x++) {
      const point = {
        x: barX[x],
        y: pointY[x]
      }
      points.push(point);
    }

    let pointsString = "";
    for (let x = 0; x < points.length; x++) {
      if (x > 0) pointsString += " ";
      pointsString += points[x].x + "," + points[x].y;
    }
    return pointsString;
  }

  return (
    <svg className={"SvgChart " + className} width={width} height={height} viewBox={"0 0 " + width + " " + height} fill="none" xmlns="http://www.w3.org/2000/svg">

      <rect width={width} height={height} rx="16" fill="#262626" />
      <g className="SvgChart__lines">
        {
          vLines().map((line, index) => {
            const { x1, x2, y1, y2 } = line;
            return <line key={"vline" + index} x1={x1} x2={x2} y1={y1} y2={y2} />;
          })
        }
        {
          /*
          hLines().map((line, index) => {
            const { x1, x2, y1, y2 } = line;
            return <line key={"hline" + index} x1={x1} x2={x2} y1={y1} y2={y2} />;
          })
          */
        }
      </g>
      <g className="SvgChart__darkBars">
        {
          bars.map((bar, index) => {
            const x1 = barXs(index);
            const x2 = barXs(index);
            const y1 = height;
            const y2 = Math.min.apply(null, [bar.ya, bar.yb]);

            return <line key={"darkBar" + index} x1={x1} x2={x2} y1={y1} y2={y2} />;
          })
        }
      </g>

      <polyline className="SvgChart__valueLine" points={valueLine()} stroke="url(#valueLineGradient)" />

      <g className="SvgChart__bars" transform="matrix(1 0 0 -1 0 315)">
        {
          bars.map((bar, index) => {
            const x1 = barXs(index);
            const x2 = barXs(index);
            const yNext = alt ? bar.ya : bar.yb;

            return (
              <line
                key={"bar" + index}
                x1={x1}
                x2={x2}
                y1={top}
                y2={height}
                style={{
                  stroke: bar.stroke,
                  strokeDasharray: height,
                  strokeDashoffset: yNext,
                  transitionDelay: 50 * index + "ms",
                  //transform: "translateY("+(yNext)+"px)",
                }}
              />
            );
          })
        }
      </g>

      <defs>
        <linearGradient id="valueLineGradient" x1="680" y1="102" x2="3.99996" y2="102" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A6FFDF" />
          <stop offset="1" stopColor="#2A3F32" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function TimeSpent(props) {
  const [checked, setChecked] = useState(false);
  const counter = useRef(0)
  counter.current++;

  return (
    <OutsideBox classBase="TimeSpent">
      <div className={"TimeSpent" + (checked ? " TimeSpent--on" : "")}>
        <div className="TimeSpent__top">
          <h2 className="TimeSpent__title">{props.title}</h2>
          <div className="TimeSpent__intro">{props.intro}</div>
        </div>
        <div className="TimeSpent__bottom">
          <SvgLegend className="TimeSpent__legend" />
          <SvgChart className="TimeSpent__chart" alt={checked} />
          <SvgLabels className="TimeSpent__labels" />
          <SvgLabelsDiag className="TimeSpent__labels TimeSpent__labels--diag" />
          <div className="TimeSpent__control">
            <div className="TimeSpent__enable"><span>Enable Cogny</span></div>
            <div className="TimeSpent__arrow"></div>
            <label className={"TimeSpent__toggle" + (checked ? " TimeSpent__toggle--on" : "")}>
              <input type="checkbox" defaultChecked={checked} onChange={() => setChecked(!checked)} />
              <div className="TimeSpent__toggleButton" />
            </label>
          </div>
        </div>
      </div>
    </OutsideBox>
  );
}

export default TimeSpent;
