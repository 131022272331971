import './style.scss';
import { useEffect, useState } from 'react';
import LoadingDots from 'components/atoms/LoadingDots';
import { NavLink } from 'react-router-dom';
import useDatasources from 'hooks/useDatasources';
import Button from 'components/atoms/Button';
import { useAppData } from 'hooks/useAppData';

const DatasourceListSection = (props) => {
  const { list, title } = props;
  const sorts = props.sorts;
  const [sort, setSort] = useState(0);
  const [sortedList, setSortedList] = useState(false);
  const { getPath } = useAppData();

  useEffect(() => {
    let newList = list;
    if (sorts[sort] === "usage") {
      newList.sort((a, b) => (b.usage_stats_total > a.usage_stats_total) ? 1 : -1);
    } else if (sorts[sort] === "last sync") {
      const missingData = newList.filter(item => item.fivetran_succeeded_at.length === 0);

      const hasData = newList.filter(item => item.fivetran_succeeded_at.length > 0).sort((a, b) => new Date(b.fivetran_succeeded_at) - new Date(a.fivetran_succeeded_at));

      newList = [...hasData, ...missingData];
    } else if (sorts[sort] === "size") {
      newList.sort((a, b) => b.tables.length - a.tables.length);
    }
    /*
    else if (sorts[sort] === "name") {
      newList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
    */

    setSortedList(newList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const nextSort = () => setSort(currentSort => (currentSort + 1) % sorts.length);

  return (
    <div className="DatasourceList__section">
      <div className="DatasourceList__sectionHeader">
        <h2 className="DatasourceList__title">{title}</h2>
        {sorts ?
          <Button className="DatasourceList__sort" icon="sort" iconSide="left" onClick={nextSort}>{sorts[sort]}</Button>
          :
          <div className="DatasourceList__count">{sortedList.length}</div>
        }
      </div>
      <div className="DatasourceList__items">
        {sortedList && sortedList.map((item, index) => (
          <NavLink
            className={({ isActive }) => "DatasourceList__item" + (isActive ? " DatasourceList__item--current" : "")}
            to={getPath("datasources", item.gid)}
            key={item.gid}
          >
            <div className="DatasourceList__label">{item.name}</div>
            <div className="DatasourceList__meta">
              {sorts[sort] === "usage" &&
                <>{!isNaN(item.usage_stats_total) ? Math.round(item.usage_stats_total * 100) + "%" : "-"}</>
              }

              {sorts[sort] === "last sync" &&
                <>{item.fivetran_succeeded_at ? item.fivetran_succeeded_at : "-"}</>
              }

              {sorts[sort] === "size" &&
                <>{item.tables.length ? item.tables.length : "-"} tables</>
              }

              {sorts[sort] === "name" && <>-</>}
            </div>

          </NavLink>
        ))
        }
      </div>
    </div>
  );
}

function DatasourceList(props) {
  const datasourceData = useDatasources();
  const error = datasourceData.error;
  const isLoaded = datasourceData.isLoaded;
  const datasources = datasourceData.data;
  const sorts = ['usage', 'last sync', 'size'];

  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  }

  if (!isLoaded) return (
    <div className="DatasourceList">
      <div className="DatasourceList__loading">
        <LoadingDots />
      </div>
    </div>
  );

  return (
    <div className="DatasourceList">
      <div className="DatasourceList__inner">
        {
          datasources.length ?
            <div>
              <DatasourceListSection title="Data sources" list={datasources} sorts={sorts} />
            </div>
            :
            <div className="DatasourceList__empty">You have not added any data sources</div>
        }
      </div>
    </div>
  );
}

export default DatasourceList;
