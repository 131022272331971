import { useState, useEffect } from 'react';
import './style.scss';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import LoadingDots from 'components/atoms/LoadingDots';
import ErrorBox from 'components/molecules/ErrorBox';
import CognyAPI from 'components/_classes/CognyAPI';
import PageHeader from 'components/organisms/PageHeader';
import Button from 'components/atoms/Button';
import ModalDialog from 'components/molecules/ModalDialog';
import Icon from 'components/atoms/Icon';
import useWindowSize from 'hooks/useWindowSize';
import Form from 'components/atoms/Form';
import Field from 'components/molecules/Field';

const MonitorDetailsList = (props) => {
  const { onClick, active, items } = props;

  const sorting = false;
  const title = "Report log";
  const hasFavorites = false;
  const emptyMessage = "There's nothing here";

  const [sortedList, setSortedList] = useState(items);

  const [sort, setSort] = useState(0);
  const nextSort = () => setSort(currentState => (currentState + 1) % sorting.length);

  const [source, setSource] = useState(0);
  const sources = ['All', 'Favorites'];
  const nextSource = () => setSource(currentState => (currentState + 1) % sources.length);

  const handleClick = (e, item) => {
    e.preventDefault();
    onClick(item);
  }

  useEffect(() => {
    if (sorting && sorting.length > 0) {
      const sortFunction = sorting[sort].sortFunction;
      const newOrder = sortFunction([...items]);
      setSortedList(newOrder);
    }
  }, [sort, items, sorting]);

  const MonitorDetailsSources = () => {
    const newSources = hasFavorites ? sources : [sources[0]];

    return (
      <>
        {
          newSources.map(item => {
            return (
              <Button
                key={item}
                className="MonitorDetailsList__source"
                onClick={nextSource}
                disabled={item === newSources[source]}
              >
                {title ?? item}
              </Button>
            )
          })
        }
      </>
    );
  }

  return (
    <div className="MonitorDetailsList">
      <div className="MonitorDetailsList__header">
        <div className="MonitorDetailsList__sources">
          <MonitorDetailsSources />
        </div>

        {sorting ?
          <Button className="MonitorDetailsList__sort" icon="sort" iconSide="left" onClick={nextSort}>{sorting[sort].label}</Button>
          :
          <div className="MonitorDetailsList__count">{sortedList.length}</div>
        }
      </div>
      <div className="MonitorDetailsList__items">
        {sortedList && sortedList
          .filter(item => {
            if (sources[source] === "Favorites" && item.favorite !== true) {
              return false;
            } else {
              return true;
            }
          })
          .map(item => {
            const dateObj = new Date(item.generated_at);
            const pad = (n, x) => n.toString().padStart(x, "0");
            const date = <>{dateObj.getFullYear()}-{pad(dateObj.getMonth() + 1, 2)}-{pad(dateObj.getDate(), 2)}</>;
            const time = <>{pad(dateObj.getHours(), 2)}:{pad(dateObj.getMinutes(), 2)}</>;
            const label = <>{date} <span style={{ display: "none" }}>{time}</span></>;

            const tests = item.tests;
            const totalOfAllTests = Object.keys(tests).reduce((acc, key) => {
              var v = 0;
              if (tests[key].high) v += tests[key].high.length;
              if (tests[key].medium) v += tests[key].medium.length;
              if (tests[key].low) v += tests[key].low.length;

              return acc + v;
            }, 0);

            return (
              <button
                onClick={(e) => handleClick(e, item)}
                key={item.generated_at}
                className={"MonitorDetailsList__item" + (item === active ? " MonitorDetailsList__item--current" : "")}
              >
                <div className="MonitorDetailsList__label">{label}</div>
                <div className="MonitorDetailsList__meta">{totalOfAllTests} issues</div>
              </button>
            )
          })
        }
      </div>
      <div className="MonitorDetailsList__empty">
        {
          sources[source] === "Favorites" ?
            "You don't have any favorites"
            :
            emptyMessage ?? "There's nothing here"
        }
      </div>
    </div>
  );
}

const EmailNotificationsDropdown = (props) => {
  const { form, updateFormValue } = props.state;

  const [access, setAccess] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cookies] = useCookies(["token", "project"]);
  const params = useParams();
  const warehouseId = params.warehouse_id;

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouseAccess(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setAccess(result.access);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setError(error);
            setIsLoaded(true);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  const handleChange = (e) => {
    updateFormValue("alert_email", e.target.value);
  }

  if (!isLoaded) return <div className="MonitorDetails__emailDropdown"><LoadingDots /></div>;

  if (error) return <div className="MonitorDetails__emailDropdown"><ErrorBox error={error} /></div>;

  return (
    <div className="MonitorDetails__emailDropdown">
      <form>
        {access.map(user => {
          return (
            <div key={user.id}>
              <input
                type="radio"
                value={user.email}
                id={user.id}
                name="alert_email"
                onChange={handleChange}
                checked={user.email === form.alert_email}
              />
              <label htmlFor={user.id}>{user.email}</label>
            </div>
          );
        })}
      </form>
    </div>
  );
}

const EmailNotifications = (props) => {
  const { form, updateFormValue } = props.state;
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="MonitorDetails__emailToggleContainer">
        <Button iconLeft="request" iconRight="chevronDown" onClick={() => setOpen(!open)} className="MonitorDetails__emailToggle">
          <span>Email notifications</span>
        </Button>
        {open && <EmailNotificationsDropdown state={{ form, updateFormValue }} />}
      </div>
    </>
  );
}

const MonitorTest = (props) => {
  const { test } = props;
  return (
    <div
      className={
        "MonitorTest " + (test.severity ? "MonitorTest--" + test.severity : "")
      }
      key={test.id}
    >
      <div className="MonitorTest__name">
        {test.id}
      </div>
      <div className="MonitorTest__severity">
        {test.severity}
      </div>
    </div>
  );
}

const MonitorIssue = (props) => {
  const { type, issue } = props;

  const [open, setOpen] = useState(false);

  switch (type) {
    case "cookie":
      issue.title = issue.cookie;
      break;
    case "domain":
      issue.title = issue.domain;
      break;
    case "service":
      issue.title = issue.service;
      break;
    default:
      break;
  }

  const getHighestSeverity = (issue) => {
    if (issue.test.length === 0) return "low";
    return issue.test.reduce((acc, test) => {
      if (test.severity === "high") return "high";
      if (test.severity === "medium" && acc !== "high") return "medium";
      return acc;
    }, "low");
  }

  return (
    <div className={"MonitorIssue MonitorIssue--" + getHighestSeverity(issue) + (open ? " MonitorIssue--open" : "")}>
      <div className="MonitorIssue__toggle" onClick={() => setOpen(!open)}>
        <div className={"MonitorIssue__dot MonitorIssue__dot--" + getHighestSeverity(issue)} />
        <h2 className="MonitorIssue__title">
          {issue.title}
          {issue.property && <span className="MonitorIssue__id">{issue.property}</span>}
        </h2>
        <Icon className="MonitorIssue__icon" icon={open ? "chevronUp" : "chevronDown"} />
      </div>

      <div className="MonitorIssue__content">
        {
          type === "service" &&
          <>
            <div className="MonitorIssue__row">
              <div className="MonitorIssue__key">Property</div>
              <div className="MonitorIssue__value"><code>{issue.property}</code></div>
            </div>
            <div className="MonitorIssue__row">
              <div className="MonitorIssue__key">Description</div>
              <div className="MonitorIssue__value">{issue.description}</div>
            </div>
            <div className="MonitorIssue__row MonitorIssue__failed">
              <div className="MonitorIssue__key">Failed tests</div>
              <div className="MonitorIssue__tests">
                {issue.test.map(test => <MonitorTest key={test.id} test={test} />)}
              </div>
            </div>
          </>
        }
        {
          type === "domain" &&
          <>
            <div className="MonitorIssue__row">
              <div className="MonitorIssue__key">Description</div>
              <div className="MonitorIssue__value">{issue.description}</div>
            </div>
            <div className="MonitorIssue__row MonitorIssue__failed">
              <div className="MonitorIssue__key">Failed tests</div>
              <div className="MonitorIssue__tests">
                {issue.test.map(test => <MonitorTest key={test.id} test={test} />)}
              </div>
            </div>
          </>
        }
        {
          type === "cookie" &&
          <>
            {/* <div className="MonitorIssue__row">
              <div className="MonitorIssue__key">Domain</div>
              <div className="MonitorIssue__value">{issue.domain}</div>
            </div> */}
            <div className="MonitorIssue__row">
              <div className="MonitorIssue__key">Description</div>
              <div className="MonitorIssue__value">{issue.description}</div>
            </div>
            <div className="MonitorIssue__row MonitorIssue__failed">
              <div className="MonitorIssue__key">Failed tests</div>
              <div className="MonitorIssue__tests">
                {issue.test.map(test => <MonitorTest key={test.id} test={test} />)}
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
};

const MonitorResult = (props) => {
  const result = props.item;

  // parse the date and time
  const dateObj = new Date(result.generated_at);
  const pad = (n, x) => n.toString().padStart(x, "0");
  const date = <>{dateObj.getFullYear()}-{pad(dateObj.getMonth() + 1, 2)}-{pad(dateObj.getDate(), 2)}</>;
  const time = <>{pad(dateObj.getHours(), 2)}:{pad(dateObj.getMinutes(), 2)}</>;

  // parse all results and build a new flat array with all issues, with each issue including the test it belongs to and the severity
  const allIssues = Object.keys(result.tests).reduce((acc, key) => {
    const test = result.tests[key];
    var issues = [];

    if (test?.high?.length > 0) issues = test.high.map(issue => { return { ...issue, test: key, severity: "high" } });
    if (test?.medium?.length > 0) issues = issues.concat(test.medium.map(issue => { return { ...issue, test: key, severity: "medium" } }));
    if (test?.low?.length > 0) issues = issues.concat(test.low.map(issue => { return { ...issue, test: key, severity: "low" } }));

    return acc.concat(issues);
  }, []);

  const totalNumberOfIssues = allIssues.length;

  const mergedIssues = allIssues.reduce((acc, issue) => {
    const existingIssue = acc.find(i => (i.cookie && i.cookie === issue.cookie) || (!i.cookie && i.domain && i.domain === issue.domain));
    if (existingIssue) {
      existingIssue.test.push({ id: issue.test, severity: issue.severity });
      return acc;
    } else {
      return acc.concat({ ...issue, test: [{ id: issue.test, severity: issue.severity }] });
    }
  }, []);

  const domainDescriptions = [
    { domain: "facebook.com", description: "Social media platform, advertising, and user tracking." },
    { domain: "google.com", description: "Search engine, advertising, analytics, and various web services." },
    { domain: "google-analytics.com", description: "Web analytics service." },
    { domain: "googletagmanager.com", description: "Tag management system." },
    { domain: "gstatic.com", description: "Google's domain for hosting static content." },
    { domain: "doubleclick.net", description: "Digital advertising platform." },
    { domain: "youtube.com", description: "Video sharing and hosting platform." },
    { domain: "fonts.googleapis.com", description: "Web font service." },
    { domain: "fonts.gstatic.com", description: "Host for Google Fonts files." },
    { domain: "googlesyndication.com", description: "Google AdSense for online advertising." },
    { domain: "googletagservices.com", description: "Google tag services, part of Google's ad services." },
    { domain: "addtoany.com", description: "Social sharing platform." },
    { domain: "cloudfront.net", description: "Content Delivery Network (CDN) by Amazon." },
    { domain: "cloudflare.com", description: "CDN and web infrastructure services." },
    { domain: "cloudflare.net", description: "Associated with Cloudflare services." },
    { domain: "typekit.net", description: "Adobe Fonts service." },
    { domain: "newrelic.com", description: "Performance monitoring for web applications." },
    { domain: "nr-data.net", description: "Data collection endpoint for New Relic." },
    { domain: "azureedge.net", description: "Microsoft Azure CDN services." },
    { domain: "appdynamics.com", description: "Application performance management." },
    { domain: "onetrust.com", description: "Privacy law compliance, including GDPR." },
    { domain: "tiktok.com", description: "Social media platform for sharing short videos." },
    { domain: "polyfill.io", description: "Service for extending browser functionality." },
    { domain: "twitter.com", description: "Social networking and microblogging platform." },
    { domain: "linkedin.com", description: "Professional networking platform." },
    { domain: "instagram.com", description: "Photo and video sharing social networking service." },
    { domain: "r9cdn.net", description: "Potential CDN or hosting service, further investigation needed." },
    { domain: "usabilla.com", description: "Likely a user feedback tool, specifics to be confirmed." },
    { domain: "pubmatic.com", description: "Advertising technology company, details of services unclear." },
    { domain: "rubiconproject.com", description: "Online advertising technology firm, specifics needed." },
    { domain: "adnxs.com", description: "Ad technology platform, part of Xandr, Inc." },
    { domain: "clarity.ms", description: "Potentially related to web analytics or user experience, further research required." },
  ];
  const cookieDescriptions = [
    { cookie: "__gads", description: "Associated with Google DoubleClick for Publishers, used for ad targeting and optimization." },
    { cookie: "_ga", description: "Used by Google Analytics to distinguish individual users." },
    { cookie: "_gid", description: "Works alongside _ga to differentiate users on a per-session basis." },
    { cookie: "_fbp", description: "Used by Facebook Pixel for advertising and tracking conversions." },
    { cookie: "__gpi", description: "Used in conjunction with Google DoubleClick's __gads for ad features." },
    { cookie: "_conv_s", description: "Likely used for conversion tracking and site analytics, further details needed." },
    { cookie: "_conv_v", description: "Possibly related to conversion tracking, site analytics, and remarketing, specifics unclear." },
    { cookie: "_fw_crm_v", description: "Potentially associated with CRM tools, exact purpose and service to be determined." }
  ];
  const serviceDescriptions = [
    {
      id: "UA",
      service: "Google Universal Analytics",
      description: "Used for detailed analysis of website traffic and user behavior to improve site performance and marketing strategies."
    },
    {
      id: "GA4",
      service: "Google Analytics 4",
      description: "Offers advanced tracking of user interactions for enhanced website analytics and cross-platform user insights."
    }
  ];

  const cookieIssues = mergedIssues.filter(issue => issue.cookie).map(issue => {
    const cookieInfo = cookieDescriptions.find(cookie => issue.cookie.indexOf(cookie.cookie) > -1);
    return {
      ...issue,
      description: cookieInfo?.description ?? "No description available"
    }
  });

  const domainIssues = mergedIssues.filter(issue => !issue.cookie && issue.domain).map(issue => {
    const domainInfo = domainDescriptions.find(domain => issue.domain.indexOf(domain.domain) > -1);
    return {
      ...issue,
      description: domainInfo?.description ?? "No description available"
    }
  });

  const serviceIssues = mergedIssues.filter(issue => (issue.GA4 || issue.UA)).map(issue => {
    const serviceInfo = issue.GA4 ? serviceDescriptions.find(service => service.id === "GA4") : serviceDescriptions.find(service => service.id === "UA");
    const property = issue.GA4 ?? issue.UA;
    const id = issue.GA4 ? "GA4" : "UA";
    return {
      ...issue,
      description: serviceInfo?.description ?? "No description available",
      service: serviceInfo?.service ?? "Name not available",
      property: property,
      id: id
    }
  });

  Array.prototype.toSortedBySeverity = function () {
    return this.sort((a, b) => {
      if (a.severity === "high") return -1;
      if (a.severity === "medium" && b.severity !== "high") return -1;
      return 1;
    });
  }

  if (!result) return <LoadingDots />;

  return (
    <div className="MonitorResult">
      <h2 className="MonitorResult__title">{date} <span>{time}</span></h2>
      <div className="MonitorResult__text">Found {totalNumberOfIssues} issues</div>
      <div className="MonitorResult__groups">
        {serviceIssues.length > 0 &&
          <div className="MonitorResult__group">
            <h3 className="MonitorResult__subtitle">Services</h3>
            <div className="MonitorResult__issues">
              {serviceIssues.toSortedBySeverity().map((issue, index) => <MonitorIssue type="service" key={index} issue={issue} />)}
            </div>
          </div>
        }

        {domainIssues.length > 0 &&
          <div className="MonitorResult__group">
            <h3 className="MonitorResult__subtitle">Domains</h3>
            <div className="MonitorResult__issues">
              {domainIssues.toSortedBySeverity().map((issue, index) => <MonitorIssue type="domain" key={index} issue={issue} />)}
            </div>
          </div>
        }

        {cookieIssues.length > 0 &&
          <div className="MonitorResult__group">
            <h3 className="MonitorResult__subtitle">Cookies</h3>
            <div className="MonitorResult__text">These are cookies that were set without consent.</div>
            <div className="MonitorResult__issues">
              {cookieIssues.toSortedBySeverity().map((issue, index) => <MonitorIssue type="cookie" key={index} issue={issue} />)}
            </div>
          </div>
        }
      </div>
    </div>
  );
}

const MonitorChart = (props) => {
  const { results } = props;
  const { width } = useWindowSize();

  const getIssuesData = (result) => {
    //parse all results and build a new array with all issues, with each issue including the test it belongs to and the severity
    const allIssues = Object.keys(result.tests).reduce((acc, key) => {
      const test = result.tests[key];
      let issues = [];
      if (test?.high?.length > 0) issues = test.high.map(issue => { return { ...issue, test: key, severity: "high" } });
      if (test?.medium?.length > 0) issues = issues.concat(test.medium.map(issue => { return { ...issue, test: key, severity: "medium" } }));
      if (test?.low?.length > 0) issues = issues.concat(test.low.map(issue => { return { ...issue, test: key, severity: "low" } }));

      return acc.concat(issues);
    }, []);

    const cookieIssues = allIssues.filter(issue => issue.cookie);
    const domainIssues = allIssues.filter(issue => !issue.cookie);

    return {
      cookies: cookieIssues,
      domains: domainIssues,
      date: result.generated_at,
      high: allIssues.filter(issue => issue.severity === "high"),
      medium: allIssues.filter(issue => issue.severity === "medium"),
      low: allIssues.filter(issue => issue.severity === "low"),
    }
  }

  const getIssuesFromLast10Results = (results) => {
    return results.results.slice(0, 10).map(result => {
      return getIssuesData(result);
    });
  }

  const getChartData = (results) => {
    const resultData = getIssuesFromLast10Results(results);
    const chartData = resultData.map(result => {
      return {
        date: result.date.substring(0, 10),
        high: result.high.length,
        medium: result.medium.length,
        low: result.low.length
      }
    });
    return chartData.toReversed();
  }

  function labelFormatter(value) {
    switch (value) {
      case 'cookies':
        return 'Cookies';
      case 'domains':
        return 'Domains';
      case 'high':
        return 'Critical';
      case 'medium':
        return 'Serious';
      case 'low':
        return 'Moderate';
      default:
        return value;
    }
  }

  function dateFormatter(dateString, shortMonthFormat = false) {
    // Regular expression to match YYYY-MM format
    const yearMonthRegex = /^\d{4}-\d{2}$/;

    // Check if dateString matches the YYYY-MM format
    if (yearMonthRegex.test(dateString)) {
      // Extract the year and month
      const year = dateString.substring(0, 4);
      const month = dateString.substring(5);

      // Array of month names
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      // Convert month number to month name
      let monthName = monthNames[parseInt(month) - 1];

      // If shortMonthFormat is true, use only the first three characters of the month name
      if (shortMonthFormat) {
        monthName = monthName.substring(0, 3);
      }

      // Return the reformatted date string
      return `${monthName} ${year}`;
    }

    // If the format is not YYYY-MM, return the original string
    return dateString;
  }

  const CustomTooltip = (props) => {
    const { active, payload, label, unit, title } = props;
    if (active && payload && payload.length) {
      return (
        <div className="MonitorChart__tooltip" style={{ backgroundColor: 'black', padding: 16 }}>
          <div className="MonitorChart__tooltip-title" style={{ fontWeight: 600, fontSize: 12 }}>{title} {dateFormatter(label)}</div>
          <div className="MonitorChart__tooltip-content">
            {payload.toReversed().map(item => {
              return (
                <div
                  key={item.name}
                  style={{
                    color: item.color,
                    lineHeight: "1em",
                    fontSize: 12,
                    display: "flex",
                    alignItems: "center",
                    marginTop: 6,
                    gap: 4
                  }}
                >
                  <span>{labelFormatter(item.name)}</span>
                  <span style={{
                    backgroundColor: item.color,
                    borderRadius: 16,
                    paddingLeft: 6,
                    paddingRight: 6,
                    paddingTop: 2,
                    paddingBottom: 2,
                    fontWeight: 600,
                    color: "rgba(0,0,0,.8)",
                    fontSize: 11,
                  }}>{item.value} {unit}</span>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  };

  const CustomXTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#555" transform="translateX(-50%)" style={{ fontSize: '10px' }}>
          {dateFormatter(payload.value, true)}
        </text>
      </g>
    );
  };

  const CustomYTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} textAnchor="end" fill="#555" transform="" style={{ fontSize: '10px' }}>
          {payload.value}
        </text>
      </g>
    );
  };

  const getBreakpoints = (width, large, medium, small, tiny) => {
    if (tiny && width < 480) return tiny;
    if (small && width < 768) return small;
    if (medium && width < 1024) return medium;
    return large;
  }
  const chartSize = {
    barSize: getBreakpoints(width, 20, 16, 8, 4),
    height: getBreakpoints(width, 230, 200, 180, 160),
  };

  return (
    <div className="MonitorChart">
      <ResponsiveContainer width="100%" height={chartSize.height}>
        <BarChart
          data={getChartData(results)}
          margin={{
            top: 5,
            right: 0,
            left: -20,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke="#222" />
          <XAxis
            dataKey="date"
            tick={CustomXTick}
          />
          <YAxis
            tick={CustomYTick}
          />
          <Tooltip content={<CustomTooltip title="" unit="issues" />} cursor={{ fill: "rgba(255,255,255,.05)" }} />
          <Bar type="montone" dataKey="low" stackId="a" fill="#FFEF99" radius={[0, 0, 2, 2]} barSize={chartSize.barSize} />
          <Bar type="montone" dataKey="medium" stackId="a" fill="#FFB933" radius={[0, 0, 0, 0]} barSize={chartSize.barSize} />
          <Bar type="montone" dataKey="high" stackId="a" fill="#DE6247" radius={[2, 2, 0, 0]} barSize={chartSize.barSize} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

const MonitorOverview = (props) => {
  return (
    <>
      <h2 className="MonitorOverview__title">Report log</h2>
      <div className="MonitorOverview__text">Please select a detailed report to the left</div>
    </>
  );
}

const MonitorDetails = (props) => {
  const [active, setActive] = useState(false);
  const [cookies] = useCookies(["token"]);
  const { item, results } = props;
  const modalState = props.state ? props.state.modalState : undefined;
  const updateModalState = props.state ? props.state.updateModalState : undefined;

  const [form, setForm] = useState({ ...item });
  const updateFormValue = (key, value) => {
    setForm(currentState => ({ ...currentState, [key]: value }));
  }
  const [formError, setFormError] = useState(null);
  const [formIsLoaded, setFormIsLoaded] = useState(true);

  const handleSubmit = (e, formData) => {
    e?.preventDefault();

    setFormIsLoaded(false);

    //send form data to api
    if (cookies.token) {
      // const api = new CognyAPI(cookies.token);
      // api.updateMonitor(sanitizedInputs)
      //   .then(
      //     (result) => {
      //       done(true);
      //       setFormIsLoaded(true);
      //       setForm({ title: false, body: false });
      //     },
      //     (error) => {
      //       console.log(error);
      //       setFormError(error);
      //       done(false);
      //       setFormIsLoaded(true);
      //     }
      //   )
    }
  }

  const handleClick = (result) => {
    setActive(result);
  }

  useEffect(() => {
    if (updateModalState) {

      const toolbarExtras = <>
        <Button icon="trashcan">Delete monitor...</Button>
        <EmailNotifications state={{ form, updateFormValue }} />
      </>;

      updateModalState({ toolbarExtras })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  useEffect(() => {
    if (updateModalState) {
      const primary = {
        label: "Save",
        onClick: (event) => handleSubmit(event, form),
      }
      updateModalState({ primary })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  useEffect(() => {
    if (updateModalState) {
      const formData = {
        data: form,
        isLoaded: formIsLoaded,
        error: formError,
      };
      updateModalState({ form: formData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formIsLoaded, formError])

  useEffect(() => {
    if (results.results.length > 0) {
      setActive(results.results[0]);
    }
  }, [results])

  return (
    <div className="MonitorDetails__main">
      <div className="MonitorDetails__sidebar">
        <MonitorDetailsList items={results.results} onClick={handleClick} active={active} />
      </div>
      <div className="MonitorDetails__content">
        {
          active ?
            <MonitorResult item={active} />
            :
            <MonitorOverview />
        }
      </div>
    </div>
  );
}

const MonitorSite = (props) => {
  const { id, item } = props;
  const [cookies] = useCookies(["token"]);

  const params = useParams();
  const warehouseId = params.warehouse_id;

  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [modalState, setModalState] = useState({
    open: false,
    sent: false,
    primary: {
      label: "",
      onClick: () => { }
    },
  });

  const updateModalState = (newState) => {
    setModalState(currentState => ({ ...currentState, ...newState }));
  }

  const getResults = (result) => {
    const tests = result.tests;
    const numberOfHighResults = Object.keys(tests).reduce((acc, key) => {
      var v = 0;
      if (tests[key].high) v = tests[key].high.length;
      return acc + v;
    }, 0);
    const numberOfTotalIssues = Object.keys(tests).reduce((acc, key) => {
      var v = 0;
      if (tests[key].high) v += tests[key].high.length;
      if (tests[key].medium) v += tests[key].medium.length;
      if (tests[key].low) v += tests[key].low.length;
      return acc + v;
    }, 0);
    const numberOfTestsWithIssues = Object.keys(tests).reduce((acc, key) => {
      return acc + (tests[key].high?.length + tests[key].medium?.length + tests[key].low?.length > 0 ? 1 : 0)
    }, 0);
    return {
      numberOfHighResults,
      numberOfTotalIssues,
      numberOfTestsWithIssues
    }
  }

  const latestResult = results?.results?.length > 0 ? getResults(results.results[0]) : false;

  const getGeneratedAt = (result) => {
    const generatedAt = new Date(result.generated_at);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const pad = (n, x) => n.toString().padStart(x, "0");
    const date = <>{generatedAt.getFullYear()}-{pad(generatedAt.getMonth() + 1, 2)}-{pad(generatedAt.getDate(), 2)}</>;
    const time = <>{pad(generatedAt.getHours(), 2)}:{pad(generatedAt.getMinutes(), 2)}</>;

    if (generatedAt.toDateString() === today.toDateString()) {
      return <>Today, {time}</>;
    } else if (generatedAt.toDateString() === yesterday.toDateString()) {
      return <>Yesterday, {time}</>;
    } else {
      return <>{date} {time}</>;
    }
  }

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getGDPRMonitor(warehouseId, id)
        .then(
          (result) => {
            if (!mounted) return;
            setResults(result);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
      if (!mounted) return;
      //setResults(gdprMonitor);
      //setIsLoaded(true);
    }
    return () => {
      mounted = false;
      setIsLoaded(false);
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDomainFromURL = (url) => {
    const domain = url.replace(/(^\w+:|^)\/\//, '').replace(/\/.*/, '');
    return domain;
  }

  if (error) {
    console.log(error);
    return <>Error: {JSON.stringify(error)}</>;
  }

  if (!isLoaded) return <LoadingDots />;

  return (
    <div className="MonitorSite">
      <div className="MonitorSite__header">
        <h2 className="MonitorSite__domain">{getDomainFromURL(item.url)}</h2>
        <div className="MonitorSite__actions">
          <ModalDialog
            label="Open detailed report..."
            icon="expand"
            iconSide="left"
            size="large"
            className="MonitorSite__toggle"
            modalClassName="MonitorDetails"
            title="Open GDPR monitor"
            modalTitle="GDPR monitor details"
            modalTitleExtra={item.domain}
            state={{ modalState, updateModalState }}
          >
            <MonitorDetails className="MonitorDetails" item={item} results={results} state={{ modalState, updateModalState }} />
          </ModalDialog>
        </div>
      </div>
      <div className="MonitorSite__summary">
        <div className="MonitorSite__history">
          <h2 className="MonitorSite__title">History</h2>
          <MonitorChart results={results} />
        </div>
        <div className="MonitorSite__report">
          {
            results?.results?.length > 0 ?
              <>
                <h2 className="MonitorSite__title">Latest report</h2>
                <div className="MonitorSite__rows">
                  <div className="MonitorSite__row">
                    <div className="MonitorSite__key">Test ran</div>
                    <div className="MonitorSite__result MonitorSite__result--date">{getGeneratedAt(results?.results[0])}</div>
                  </div>
                  <div className="MonitorSite__row">
                    <div className="MonitorSite__key">Found issues</div>
                    <div className="MonitorSite__result MonitorSite__result--issues">{latestResult.numberOfTotalIssues} <span>general issues</span></div>
                  </div>
                  <div className="MonitorSite__row">
                    <div className="MonitorSite__key">Critical</div>
                    <div className="MonitorSite__result MonitorSite__result--critical">{latestResult.numberOfHighResults} <span>critical issues</span></div>
                  </div>
                </div>
              </>
              :
              <>
                <h2 className="MonitorSite__title">You have no results for this monitor yet</h2>
              </>
          }
        </div>
      </div>
    </div>
  );
}

const MonitorNewForm = (props) => {
  const [access, setAccess] = useState([]);
  const [, setError] = useState(null);
  const [, setIsLoaded] = useState(false);

  const [cookies] = useCookies(["token"]);
  const params = useParams();
  const warehouseId = params.warehouse_id;

  const { updateModalState } = props.state;

  const defaultForm = { url: "", frequency: false, alert_email: false };
  const [form, setForm] = useState(defaultForm);
  const updateFormValue = (key, value) => setForm(currentState => ({ ...currentState, [key]: value }));
  const [formError, setFormError] = useState(null);
  const [formIsLoaded, setFormIsLoaded] = useState(true);

  const [validating, setValidating] = useState({ url: false, frequency: false, alert_email: false });

  const urlValidation = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/; // eslint-disable-line no-useless-escape
  const frequencyValidation = /^(daily|weekly)$/;
  const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const Validation = (props) => {
    const { className, rules, message, active } = props;

    if (!active) return;

    if (rules) {
      return <div className={"Validation " + className}>{message}</div>;
    }

    return "";
  };

  const validateForm = () => {
    const isUrlValid = urlValidation.test(form.url) && form.url.length > 0;
    const isFrequencyValid = frequencyValidation.test(form.frequency) && form.frequency.length > 0;
    const isEmailValid = emailValidation.test(form.alert_email) && form.alert_email.length > 0;

    return isUrlValid && isFrequencyValid && isEmailValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidating({ url: true, frequency: true, alert_email: true });

    if (!validateForm()) {
      return;
    }

    setFormIsLoaded(false);

    //send form data to api
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.addGDPRMonitor(warehouseId, form)
        .then(
          (result) => {
            setFormIsLoaded(true);
            setForm(defaultForm);
            updateModalState({ sent: true });
          },
          (error) => {
            console.log(error);
            setFormError(error);
            setFormIsLoaded(true);
          }
        )
    }
  }

  const handleChange = (e, key = false) => {
    let value;
    if (e._reactName) {
      value = e.target.value;
      key = e.target.name;
    } else {
      if (!key) return;
      value = e.value;
    }
    updateFormValue(key, value);
  }

  const handleBlur = (e) => {
    const { name } = e.target;
    setValidating(currentState => ({ ...currentState, [name]: true }));
  }

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getWarehouseAccess(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;

            // sort the access array by email
            result.access.sort((a, b) => {
              if (a.email < b.email) return -1;
              if (a.email > b.email) return 1;
              return 0;
            });

            setAccess(result.access);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setError(error);
            setIsLoaded(true);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  useEffect(() => {
    if (updateModalState) {
      const primary = {
        label: "Save",
        onClick: (event) => handleSubmit(event, form),
      }
      updateModalState({ primary })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  useEffect(() => {
    if (updateModalState) {
      const formData = {
        data: form,
        isLoaded: formIsLoaded,
        error: formError,
      };
      updateModalState({ form: formData });
    }
    return () => {
      if (updateModalState) {
        const formData = {
          data: false,
          isLoaded: true,
          error: null,
        };
        updateModalState({ form: formData });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formIsLoaded, formError])

  return (
    <div className="MonitorNewForm">
      <Form onSubmit={handleSubmit}>
        <div className="FieldRow">
          <Field
            type="text"
            id="url"
            label="Website URL"
            name="Website URL"
            placeholder="https://www.example.com"
            value={form.url}
            onChange={handleChange}
            onBlur={handleBlur}
            validation={<Validation active={validating.url} rules={!urlValidation.test(form.url)} message="You need to enter a correct URL" />}
          />
        </div>

        <div className="FieldRow">
          <Field
            type="select"
            id="frequency"
            className="NewMonitor__frequency"
            name="Frequency"
            options={[{ value: 'daily', label: 'Daily' }, { value: 'weekly', label: 'Weekly' },]}
            onChange={(e) => handleChange(e, "frequency")}
            classNamePrefix="Select"
            placeholder="Select frequency"
            validation={<Validation active={validating.frequency} rules={!frequencyValidation.test(form.frequency)} message="You need to select a frequency" />}
          />
        </div>

        <div className="FieldRow">
          <Field
            type="select"
            id="alert_email"
            className="NewMonitor__email"
            name="Alert email"
            options={access.map(item => { return { value: item.email, label: item.email } })}
            onChange={(e) => handleChange(e, "alert_email")}
            classNamePrefix="Select"
            placeholder="Select alert email"
            validation={<Validation active={validating.alert_email} rules={!emailValidation.test(form.alert_email)} message="You need to select an email" />}
          />
        </div>
      </Form>
      <p>Your first GDPR check will start directly after adding a new monitor and then repeat at your chosen frequency.</p>
      <p>Each check cost 50 Cogny credits.</p>
    </div>
  );
}

const NewMonitor = (props) => {
  const { add } = props;

  const [modalState, setModalState] = useState({
    open: add ? true : false,
    sent: false,
    primary: {
      label: "",
      onClick: () => { }
    }
  });

  const updateModalState = (newState) => {
    setModalState(currentState => ({ ...currentState, ...newState }));
  }

  return (
    <ModalDialog
      iconSide="left"
      icon="plus"
      label="New monitor"
      size="small"
      className="Monitor__new"
      title="New monitor"
      modalClassName="MonitorNew"
      modalTitle={!modalState.sent ? "Add new GDPR monitor" : "A fresh GDPR monitor is on it's way"}
      state={{ modalState, updateModalState }}
      toolbar={
        !modalState.sent &&
        {
          primary: {
            ...modalState.primary
          },
          secondary: {
            label: "Cancel",
            onClick: () => { updateModalState({ ...modalState, open: false }) }
          }
        }
      }
    >
      {
        modalState.sent ?
          <div className="MonitorNew__sent">
            Your new GDPR monitor har started it's initial scan and will appear in your monitor list as soon as it is done.
          </div>
          :
          <MonitorNewForm state={{ modalState, updateModalState }} />
      }
    </ModalDialog>
  );
}

function Monitor(props) {
  const { add } = props;
  const [cookies] = useCookies(["token"]);

  const params = useParams();
  const warehouseId = params.warehouse_id;

  const [monitors, setMonitors] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getGDPRMonitors(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setMonitors(result);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
      if (!mounted) return;
      //setMonitors(gdprMonitors);
      //setIsLoaded(true);
    }
    return () => {
      mounted = false;
      setIsLoaded(false);
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    console.log(error);
    return <ErrorBox error={error} />;
  }

  if (!isLoaded) return <LoadingDots />;

  return (
    <div className="Monitor">
      <PageHeader
        title="GDPR monitoring"
        description="By adding monitoring to your website, you can enable daily GDPR compliance checks. We continuously update our checks with the latest legal requirements and will immediately alert you about any new issues detected on your site."
        button={
          <NewMonitor add={add} />
        }
      />
      <div className="Monitor__sites">
        {monitors.map(item => <MonitorSite item={item} id={item.id} key={item.id} />)}
      </div>
    </div>
  );
}

export default Monitor;
