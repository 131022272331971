import './style.scss';
import React, { useState, useEffect } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import Header from 'components/molecules/Header';
import BackLink from 'components/molecules/BackLink';
import LoadingDots from 'components/atoms/LoadingDots';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import ImpactMetricCard from './components/ImpactMetricCard';
import Menu from './components/Menu';
import { useAppData } from 'hooks/useAppData';

function ImpactAnalysisList(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [impact, setImpact] = useState({});
  const [cookies] = useCookies(["token", "project"]);
  const params = useParams();
  const id = params.id;
  const warehouseId = params.warehouse_id;
  const navigate = useNavigate();
  const { getPath } = useAppData();

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getImpact(warehouseId, id)
        .then(
          (result) => {
            if (!mounted) return;
            setImpact(result);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setError(error);
            setIsLoaded(true);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  const handleRerun = (e) => {
    //e.preventDefault();

    setIsLoaded(false);

    const api = new CognyAPI(cookies.token);
    api.rerunImpactAnalysis(warehouseId, id)
      .then(
        (taskResult) => {
          setIsLoaded(true);

          navigate(getPath("impact"));
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const handleDelete = (e) => {
    //e.preventDefault();

    setIsLoaded(false);

    const api = new CognyAPI(cookies.token);
    api.deleteImpactAnalysis(warehouseId, id)
      .then(
        (taskResult) => {
          setIsLoaded(true);

          navigate(getPath("impact"));
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <>
        <Header>
          <BackLink to={getPath("impact")} />
        </Header>
        <div className="ImpactList">
          {
            !isLoaded ?
              <LoadingDots />
              :
              <>
                <div className="ImpactList__welcome">
                  <h1 className="ImpactList__title">{impact.name}</h1>
                  <Menu onRerun={handleRerun} onDelete={handleDelete} />
                </div>
                <table>
                  <tr>
                    <th>Status</th>
                    <td>{impact.status}</td>
                  </tr>
                  <tr>
                    <th>Pre-period starts</th>
                    <td>{impact.start_date}</td>
                  </tr>
                  <tr>
                    <th>Impact period</th>
                    <td>{impact.change_date} - {impact.stop_date}</td>
                  </tr>
                </table>
                <div className="ImpactList__box ImpactListBox--recurring">
                  {impact.results &&
                    impact.results.length ?
                    impact.results.map(r => {
                      return <ImpactMetricCard result={r} />;
                    })
                    :
                    ""
                  }
                </div>
              </>
          }
        </div>
      </>
    );
  }
}

export default ImpactAnalysisList;
