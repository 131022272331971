import './style.scss';
import OutsideBox from 'outside/components/OutsideBox';

const TermsContent = () => (
  <div className="Terms">
    <h1>Terms and Conditions</h1>
    <p>Please carefully review these Terms of Use ("Terms") before accessing and using the Cogny.com website ("Website"), a project operated by Cogny AB ("we," "us," or "our").</p>
    <p>These Terms outline the conditions under which you may use the Website. By accessing or using the Website, you acknowledge your agreement to comply with these Terms. If you do not agree with these Terms, please refrain from using the Website.</p>

    <p>The terms "we," "us," or "our" refer to Cogny AB, a legal entity registered under the laws of Sweden, with its headquarters located in Stockholm.</p>

    <p>Kindly note that all materials on the Website are for informational purposes only. No content on the Website constitutes professional advice.</p>

    <h2>You can reach us at:</h2>
    <p>
      Cogny AB<br />
      Registered address: Medborgarplatsen 25, 118 72 Stockholm<br />
      Contact email address: privacy@cogny.com
    </p>

    <h2>1. General Terms</h2>

    <p>1.1 These Terms establish a legally binding agreement between you and Cogny AB.</p>

    <p>1.2 Swedish laws shall govern your use of the Website.</p>

    <p>1.3 By accessing and using the Website, you confirm that you meet the following criteria:</p>
    <ul>
      <li>You possess the legal capacity required to enter into these Terms.</li>
      <li>You are not restricted from using the Website as a consumer or business user.</li>
      <li>You are not located in a country subject to a Swedish Government embargo or designated as a "terrorist-supporting" country by the Swedish Government.</li>
      <li>You are not listed on any Swedish Government list of prohibited or restricted parties.</li>
    </ul>

    <p>1.4 Cogny AB reserves the right to modify, delete, or amend these Terms at any time. Any new or modified terms will become effective upon publication. We will notify you of substantial changes to the Terms through notifications on the Website or via email.</p>

    <p>Should you disagree with the updated Terms, please cease using the Website. Periodically review these Terms for updates.</p>

    <h2>2. Services</h2>

    <p>2.1 The Website offers various services ("Services"), including but not limited to:</p>
    <ul>
      <li>Hosting for Google Tag Manager ("sGTM") containers.</li>
      <li>Logs of incoming and outgoing requests sent to the sGTM container.</li>
      <li>Tracking the number of requests by each vendor.</li>
      <li>Consultation and audits of server-side tracking.</li>
      <li>Setting up server-side tracking.</li>
      <li>Client support via email and chat.</li>
      <li>Access to a blog containing articles on server-side tracking.</li>
      <li>Data Warehousing</li>
      <li>Automated data transformation</li>
      <li>Automated data analytics</li>
      <li>Data transfer from 300+ sources</li>
    </ul>
    <p>Details regarding Services are available on the Website's subscription plans or product pages.</p>

    <p>2.2 Cogny AB provides Services according to chosen subscription plans. We reserve the right to change plan content and pricing, with changes applying in the next billing cycle.</p>

    <p>2.3 Services are not substitutes for professional advice and carry no liability for outcomes.</p>

    <h2>3. Account Registration</h2>

    <p>3.1 Before using Services, create a personal account ("Account") on the Website.</p>

    <p>3.2 To open an Account, you must:</p>
    <ul>
      <li>Have the legal capacity to form a binding contract (natural persons).</li>
      <li>Be an authorized representative of a legal entity (legal persons).</li>
    </ul>

    <p>3.3 Create an Account by providing your email address as your login. After email confirmation, set your Account password.</p>

    <p>3.4 Ensure accurate information when opening an Account.</p>

    <p>3.5 Do not create an Account for others unless legally authorized. Do not impersonate or misrepresent identities or affiliations.</p>

    <p>3.6 Keep your Account secure. Sharing login credentials is at your risk.</p>

    <p>3.7 Report any security breach or unauthorized Account use.</p>

    <p>3.8 Cogny AB may suspend Accounts for violations or non-compliance.</p>

    <p>3.9 Ensure information accuracy and notify Cogny AB of any changes.</p>

    <h2>4. Service Fees</h2>

    <p>4.1 Payment Packages:</p>
    <p>Cogny offers three different pricing packages: Small, Medium, and Large. Each package provides a varying number of credits that can be used to access and utilize the platform's services.</p>
    <ul>
      <li>Small Package: 900 Credits</li>
      <li>Medium Package: 2990 Credits</li>
      <li>Large Package: 6655 Credits</li>
    </ul>
    <p>Credit Usage:</p>
    <p>Each package allows you to access the services offered by Cogny. The services may include data storage, analytics, data pipeline, server side tracking, and other features, as outlined in the package description.</p>

    <p>4.2 Pricing:</p>
    <p>The pricing for each package is as follows:</p>
    <ul>
      <li>Small Package: € 0.11 per credit up to 900 credits</li>
      <li>Medium Package: € 0.10 per credit up to 2,990 credits</li>
      <li>Large Package: € 0.09 per credit up to 6,655 credits</li>
    </ul>

    <p>4.3 Payment Schedule:</p>
    <p>Payments for credits are to be made at the end of each month, and the minimum purchase quantity is 900 credits. Payment can be made via Stripe inc supported payment methods for each country, including credit card, bank transfer, or any other method specified by Cogny.</p>

    <p>4.4 Billing Cycle:</p>
    <p>Billing cycles are monthly and will commence at the end of each month. Cogny will provide an invoice detailing the number of credits used during the previous billing cycle.</p>

    <p>4.5 Overages:</p>
    <p>If you exceed the number of credits included in your chosen package, additional credits can be purchased at the same rate as your package. Overages will be billed on a monthly basis.</p>

    <p>The pricing for overages for each package is as follows:</p>
    <ul>
      <li>Small Package: € 0.12 per credit</li>
      <li>Medium Package: € 0.11 per credit</li>
      <li>Large Package: € 0.10 per credit</li>
    </ul>

    <p>4.6 Renewal and Termination:</p>
    <p>Subscriptions will automatically renew at the end of each billing cycle unless canceled by the user. Cancellation requests must be submitted 7 days before the next billing cycle. Unused credits do not carry over to the next billing cycle.</p>

    <p>4.7 Refunds:</p>
    <p>Cogny does not offer refunds for unused credits or early termination of a subscription.</p>

    <p>4.8 Changes to Pricing and Terms:</p>
    <p>Cogny reserves the right to modify pricing and these payment terms with prior notice to users. Changes will be communicated via email and will take effect at the beginning of the next billing cycle.</p>

    <p>4.9 Disputes:</p>
    <p>Any disputes regarding billing or payments should be communicated to Cogny's customer support within 5 days of receiving the invoice.</p>

    <p>By using Cogny's services, you agree to these Payment Terms. If you have any questions or concerns, please contact Cogny's customer support at support@cogny.com.</p>

    <p>By continuing to use Cogny's services, you acknowledge and agree to these Payment Terms.</p>

    <h2>5. User Conduct</h2>

    <p>When using the Website and Services, you agree not to:</p>
    <ul>
      <li>Violate these Terms or applicable laws.</li>
      <li>Infringe intellectual property rights.</li>
      <li>Harm or disrupt the Website or servers.</li>
      <li>Gain unauthorized access.</li>
      <li>Impersonate identities.</li>
      <li>Violate any laws.</li>
    </ul>

    <h2>6. Liability</h2>

    <p>To the extent permitted by law:</p>
    <ul>
      <li>Cogny AB is not liable for accuracy or completeness of the Website or Content.</li>
      <li>Cogny AB is not responsible for linked websites.</li>
      <li>Cogny AB is not liable for damages or losses.</li>
    </ul>

    <h2>7. Content, Intellectual Property, and Links</h2>

    <p>Website content and components are protected by intellectual property laws. Unauthorized use is prohibited.</p>

    <h2>8. Confidentiality</h2>

    <p>Refer to Privacy Policy and Cookie Notice for data and cookie handling.</p>

    <h2>9. Dispute Resolution</h2>

    <p>Attempt dispute resolution through negotiations or privacy@cogny.com. Disputes unresolved in 30 days resolved by Swedish courts.</p>

    <h2>10. Final Terms</h2>

    <p>Termination by Cogny AB at its discretion.</p>
    <p>Entire agreement between you and Cogny AB.</p>
    <p>Matters not addressed by these Terms subject to Swedish law.</p>
    <p>Changes to Terms apply upon use.</p>
    <p>For questions, contact privacy@cogny.com.</p>
  </div>
);

function Terms(props) {
  if (props.raw) return <TermsContent />;

  return (
    <OutsideBox classBase="Terms">
      <TermsContent />
    </OutsideBox>
  );
}

export default Terms;
