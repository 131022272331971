import './style.scss';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import LoadingDots from 'components/atoms/LoadingDots';
import Field from 'components/molecules/Field';
import { useCookies } from 'react-cookie';
import PageHeader from 'components/organisms/PageHeader';
import WelcomeTitle from 'components/atoms/WelcomeTitle';
import SignOutLink from 'components/atoms/SignOutLink';
import { useAppData } from 'hooks/useAppData';

function FieldRow(props) {
  return (
    <div className="FieldRow">
      {props.children}
    </div>
  );
}

function AddAccount(props) {
  const [accountName, setAccountName] = useState("");
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [takenAccountName, setTakenAccountName] = useState("you've entered");
  const navigate = useNavigate();
  const [cookies] = useCookies(["token", "project"]);
  const params = useParams();
  const warehouseId = params.warehouse_id;
  const { getCustomPath } = useAppData();

  const nameValidation = /^[a-z][a-z0-9-]*$/;

  const validateTask = (name) => {
    return nameValidation.test(name) && name.length && typeof name !== "undefined";
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateTask(accountName)) {
      return;
    }

    const newWarehouse = {
      name: accountName,
    };

    if (cookies.token) {
      setIsLoaded(false);
      const api = new CognyAPI(cookies.token);
      if (window.dataLayer) {
        window.dataLayer.push({ event: "add_warehouse", token: cookies.token });
      }

      api.addWarehouse(newWarehouse)
        .then(
          (result) => {
            setIsLoaded(true);

            navigate(getCustomPath(result.warehouse_id, "dashboard"));
          },
          (error) => {
            setIsLoaded(true);
            setTakenAccountName(accountName);
            setError(error);
          }
        )
    }
  }

  const Validation = (props) => {
    const { className, rules, message } = props;

    if (accountName !== "" && rules) {
      return <div className={"Validation " + className}>{message}</div>;
    }

    return "";
  };

  const ErrorMessage = (props) => {
    const { error, className = "" } = props;

    if (typeof error.message === "undefined") return "";
    if (error.message === "warehouse already exists") {
      return (
        <div className={"ErrorMessage " + className}>The account name {takenAccountName} already exists. Please enter a new name and try again.</div>
      );
    }

    return "";
  }

  return (
    <div className="AddAccount">
      {warehouseId ?
        <PageHeader pretitle="New account" title="Account details" />
        :
        <>
          <div className="AddAccount__welcome">
            <WelcomeTitle />
            <SignOutLink dark />
          </div>

          <h2 className="AddAccount__title">Let's begin by creating an account</h2>
        </>
      }
      <Form onSubmit={handleSubmit}>
        <FieldRow>
          <Field
            type="text"
            id="AddAccount__name"
            className="AddAccount__name"
            name="Account name"
            disabled={!isLoaded}
            value={accountName}
            onChange={(e) => { setAccountName(e.target.value) }}
            validation={<Validation rules={!nameValidation.test(accountName)} message="Name must only containg lower case letters (a-z) and/or numbers and not start with a number" />}
            placeholder="Your account name"
          />
        </FieldRow>
        <FieldRow>
          {isLoaded ?
            <Button type="submit" className="AddAccount__submit" icon="arrowRight" iconSide="right" disabled={accountName !== "" && !nameValidation.test(accountName)}>
              {warehouseId ? "Create new account" : "Continue"}
            </Button>
            :
            <Button type="submit" className="AddAccount__submit" iconSide="right" disabled>
              <LoadingDots />
            </Button>
          }
        </FieldRow>
        {error &&
          <FieldRow>
            <ErrorMessage error={error} className="AddAccount__error" />
          </FieldRow>
        }
      </Form>
    </div>
  )
}

export default AddAccount;
