const questions = [
  {
    question: "What is server-side tagging?",
    answer: `
Server-side tagging involves collecting and processing data on a server, rather than directly in the user's browser (client-side). This method shifts much of the data processing workload from the client to a server environment, offering greater control over data handling, privacy and site speed.
`,
  },
  {
    question: "How do I set up server-side tagging?",
    answer: `
To set up server-side tagging, you need a server-side tagging container (like Google Tag Manager's server-side version). The process generally involves:

1. Set up a server using cogny.com
2. Create a server-side container in your tag management system
3. In **Container Settings**, name your new container and select Server. Click Create. Once the container was created, you will see a setup screen.
4. Click Manually provision tagging server
5. Copy container configuration
6. Paste container configuration into Cogny's setup guide for Server side tracking 
7. Follow the setup instructions for custom subdomain in Cogny
8. Add the following DNS records to your domain
    * As an A record pointing to 65.21.216.240
    * Click verify
    * Done
9. Configuring data streams to send data to this container
10. Implementing tags within the server-side environment to handle the incoming data
`,
  },
  {
    question: "Is server-side tagging GDPR compliant?",
    answer: `
Server-side tagging can enhance GDPR compliance by offering more control over data collection and processing. It allows anonymizing and controlling user data before it's sent to third-party services, potentially reducing the risk of privacy breaches. However, GDPR compliance also depends on other factors like data consent and how data is managed and stored.
`,
  },
  {
    question: "Why use server-side tracking?",
    answer: `
Server-side tracking offers several benefits, including:
* Improved data privacy and security
* Reduced browser workload, leading to better website, seo & conversion performance
* Enhanced data accuracy, as it's less susceptible to ad blockers
* Greater control over data processing and management
`,
  },
  {
    question: "Is server-side more secure?",
    answer: `
Yes, server-side tagging is generally more secure than client-side tagging. It offers better control over data, allowing you to sanitize and secure information before it reaches third-party services. It also reduces exposure to browser-based threats.
`,
  },
  {
    question: "What is the difference between server-side and client-side tagging?",
    answer: `
Client-side tagging involves collecting data directly in the user's browser and sending it to various analytics and marketing platforms. Server-side tagging, on the other hand, sends this data to a server first, where it can be processed, filtered, or modified before being sent to third-party services.
`,
  },
  {
    question: "Can a page have only one server-side tag?",
    answer: `
Technically, a page can have just one server-side tag, but this limits the flexibility and capability of your tracking setup. Multiple tags can be used for various purposes, like tracking different user interactions or sending data to different analytics platforms.
`,
  },
  {
    question: "What are the disadvantages of client-side tagging?",
    answer: `
Client-side tagging has some drawbacks, including:
* Increased vulnerability to ad blockers and privacy tools
* Potentially slower page load times due to numerous third-party scripts
* Higher risk of data breaches or leakage
* Less control over data processing and collection
`,
  },
  {
    question: "What is Google Tag Manager server-side?",
    answer: `
Google Tag Manager (GTM) server-side is a version of GTM that allows you to move much of your data collection and processing from the client-side (user's browser) to a server. It provides better control over data handling and can improve site performance and data privacy.
`,
  },
  {
    question: "Is GTM server-side free?",
    answer: `
Google Tag Manager's standard version is free, including its server-side tagging features. However, you might incur costs related to server hosting if you use hosting services like Cogny.com.
`,
  },
  {
    question: "How much is Google Tag Manager server-side?",
    answer: `
While GTM server-side itself is free, the cost comes from the server you use to run your server-side container. If you use Google Cloud, for example, the costs depend on the resources you use, like computing power and data transfer. A “small” google cloud server is around 140 USD per month where the equivalent on Cogny.com is only 99 EUR per month approx 25% less expensive. Cogny will take care of all setup and maintenance, Cogny also offers anonymization of IP and Cookie ID for free.
`,
  },
  {
    question: "Should I use server-side tagging?",
    answer: `
Server-side tagging is worth considering if:
* You want more control and security over your data collection and processing
* Your site deals with sensitive user data and you aim for higher compliance standards like GDPR
* You're looking to improve website performance by reducing client-side load
* You require more sophisticated data processing capabilities
* However, assess your specific needs, resources, and technical capabilities before deciding. Server-side tagging can be more complex to set up and manage than client-side tagging
`,
  },
];

export default questions;
