import './style.scss';
import React, { useState, useEffect } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import SSGTMCard from 'components/atoms/SSGTMCard';
import LoadingDots from 'components/atoms/LoadingDots';
import Button from 'components/atoms/Button';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useAppData } from 'hooks/useAppData';

/* 
apiData = [
  {
    "gid": "1200844750643442",
    "status": "Requests",
    "name": "Special audit tools",
    "notes": ""
  }
];
*/

function SSGTMList(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ssgtms, setSSGTMs] = useState([]);
  const [cookies] = useCookies(["token", "project"]);
  const params = useParams();
  const warehouseId = params.warehouse_id;
  const { getPath } = useAppData();

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getSSGTMs(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setIsLoaded(true);
            setSSGTMs(result);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div className="SSGTMList">
        <div className="SSGTMList__welcome">
          <h1 className="SSGTMList__title">Server-side tracking</h1>
          <Button type="link" href={getPath("server-side-gtm", "new")} className="SSGTMList__addTask" icon="plus">New server-side tracker</Button>
        </div>
        {
          !isLoaded ?
            <LoadingDots />
            :
            <div className="SSGTMList__box SSGTMListBox--recurring">
              {
                ssgtms.length ?
                  ssgtms.map(ds => {
                    return (
                      <SSGTMCard key={ds.id} task={ds} warehouseId={warehouseId} />
                    );
                  })
                  :
                  <p>You have not set up server-side tracking</p>
              }
            </div>
        }
      </div>
    );

  }
}

export default SSGTMList;
