import "./style.scss";
import ErrorBox from "components/molecules/ErrorBox";
import { useState } from "react";
import Button from "components/atoms/Button";
import { useAppData } from "hooks/useAppData";

function ErrorPage(props) {
  const [reportError, setReportError] = useState(false);
  const { getPrePath } = useAppData();
  const error = props.error;

  console.log(error);

  if (reportError) {
    return (
      <div className="ErrorPage">
        <ErrorBox
          pretitle="An error has occurred"
          title={error.message || "Error"}
          buttonText="Report"
          error={error}
        />
      </div>
    );
  }

  return (
    <div className="ErrorPage">
      <h1 className="ErrorPage__title">An error has occured</h1>
      <div className="ErrorPage__content">
        <div className="ErrorPage__intro">
          {error?.messageObject?.map((segment, index) => {
            if (segment.type === 'highlighted') {
              return <span key={index} className="ErrorPage__highlighted">{segment.text}</span>;
            } else {
              return <span key={index}>{segment.text}</span>;
            }

          })}
        </div>

        <div className="ErrorPage__option">
          <div>If you have found a link that leads here, please report it</div>
          <Button onClick={() => setReportError(true)}>Open error report</Button>
        </div>

        <div className="ErrorPage__option">
          <div>Or if you just want to keep going</div>
          <Button type="link" href={getPrePath()}>Go back to the beginning</Button>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;