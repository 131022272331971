import './style.scss';
import loading from './loading.svg';

function LoadingDots(props) {
  const { className = "", alt = "" } = props;
  return (
    <img className={"LoadingDots " + className} src={loading} alt={alt} {...props.imgAttributes} />
  );
}

export default LoadingDots;