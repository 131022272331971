import './style.scss';
import React from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import 'katex/dist/katex.min.css';
import rehypeSanitize from "rehype-sanitize";
import Code from "components/molecules/Code";

const ImageBlock = (props) => {
  const { src, alt } = props;
  return <img src={src} alt={alt} className="MarkdownBlock__image" />;
}

const TableBlock = ({ node, children }) => {
  return (
    <div className="MarkdownBlock__table-container">
      <table className="MarkdownBlock__table">
        {children}
      </table>
    </div>
  );
}

const CodeBlock = (props) => {
  const { className = "", children } = props;
  const match = /language-(\w+)/.exec(className || '');
  const language = match ? match[1] : null;

  if (language) {
    return (
      <Code
        language={language}
        className={"MarkdownBlock__code"}
      >
        {children}
      </Code>
    );
  }

  return (
    <code className={"MarkdownBlock__inline-code"}>
      {children}
    </code>
  );

};

const PreBlock = (props) => {
  const { children } = props;

  if (children && typeof children !== "string") {
    return <>{children}</>;
  } else {
    return <pre className="MarkdownBlock__pre">{children}</pre>;
  }
};

const StrongBlock = (props) => {
  const { children } = props;

  return <strong className="MarkdownBlock__strong">{children}</strong>;
}

function MarkdownBlock(props) {
  const { children } = props;

  return (
    <div className="MarkdownBlock">
      <Markdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeKatex, [rehypeSanitize, {}]]}
        components={{
          table: TableBlock,
          code: CodeBlock,
          pre: PreBlock,
          strong: StrongBlock,
          img: ImageBlock,
        }}
      >
        {children}
      </Markdown>
    </div>
  );
}

export default MarkdownBlock;