import Button from 'components/atoms/Button';
import DatasourceList from 'components/organisms/DatasourceList';
import { useAppData } from 'hooks/useAppData';

function DatasourceSidebar() {
  const { getPath } = useAppData();

  return (
    <div className="DatasourceSidebar">
      <Button type="link" href={getPath("datasources", "new")} className="DatasourceSidebar__new" icon="plus">New data source</Button>
      <DatasourceList />
    </div>
  )
}

export default DatasourceSidebar;