import './style.scss';
import React from 'react';
import { ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, Label } from 'recharts';

function ImpactMetricCard(props) {
	const { result } = props;

	var r = result;

	function r2(x) {
		return Math.round(x * 100) / 100;
	}

	/*var tags = [
		{ "gid": "status"
		, "name": props.task.status}
	];*/

	var change_date = "";

	var obs = [];
	for (const p in r.pre_data[r.metric]) {
		var dstr1 = new Date(parseInt(p)).toISOString().split('T')[0]
		obs.push({
			date: dstr1,
			value: r.pre_data[r.metric][p]
		})
		change_date = dstr1;
	}
	for (const p in r.post_data[r.metric]) {
		var dstr2 = new Date(parseInt(p)).toISOString().split('T')[0]
		obs.push({
			date: dstr2,
			value: r.post_data[r.metric][p]
		})
	}

	var pred = [];
	var band = [];
	for (const p in r.inferences['complete_preds_means']) {
		var dstr3 = new Date(parseInt(p)).toISOString().split('T')[0]
		pred.push({
			date: dstr3,
			value: r.inferences['complete_preds_means'][p]
		});
		band.push({
			date: dstr3,
			value: [r.inferences['complete_preds_lower'][p], r.inferences['complete_preds_upper'][p]]
		});
	}
	//          color=color,
	//          alpha=0.4


	var point_effects = {
		mean: [],
		band: [],
	};
	for (const p in r.inferences['point_effects_means']) {
		var dstr4 = new Date(parseInt(p)).toISOString().split('T')[0]
		point_effects.mean.push({
			date: dstr4,
			value: r.inferences['point_effects_means'][p]
		});
		point_effects.band.push({
			date: dstr4,
			value: [r.inferences['point_effects_lower'][p], r.inferences['point_effects_upper'][p]]
		});
	}

	var cumulative_effects = {
		mean: [],
		band: [],
	};
	for (const p in r.inferences['post_cum_effects_means']) {
		var dstr5 = new Date(parseInt(p)).toISOString().split('T')[0]
		cumulative_effects.mean.push({
			date: dstr5,
			value: r.inferences['post_cum_effects_means'][p]
		});
		cumulative_effects.band.push({
			date: dstr5,
			value: [r.inferences['post_cum_effects_lower'][p], r.inferences['post_cum_effects_upper'][p]]
		});
	}
	var summary = r.summary_data;

	function ftt(values, name, props) {
		if (typeof values == "object") {
			return [r2(values[0]) + " - " + r2(values[1]), name];
		}
		return r2(values);
	}

	return (
		<div className="ImpactMetricCard" key={result.metric}>
			<div className="ImpactMetricCard__main">
				<h3 className="ImpactMetricCard__title">{result.metric}</h3>
				{ /* <Tags tags={tags} /> */}
				<table>
					<tr>
						<th></th>
						<th>Average</th>
						<th>Cumulative</th>
					</tr>

					<tr>
						<th>Actual</th>
						<td>{r2(summary['average']['actual'])}</td>
						<td>{r2(summary['cumulative']['actual'])}</td>
					</tr>

					<tr>
						<th>Prediction (s.d.)</th>
						<td>{r2(summary['average']['predicted'])}</td>
						<td>{r2(summary['cumulative']['predicted'])}</td>
					</tr>
					<tr>
						<th>95% CI</th>
						<td>[{r2(summary['average']["predicted_lower"])}, {r2(summary['average']["predicted_upper"])}]</td>
						<td>[{r2(summary['cumulative']["predicted_lower"])}, {r2(summary['cumulative']["predicted_upper"])}]</td>
					</tr>

					<tr>
						<td> </td>
					</tr>
					<tr>
						<th>Absolute effect (s.d.)</th>
						<td>{r2(summary['average']['abs_effect'])}</td>
						<td>{r2(summary['cumulative']['abs_effect'])}</td>
					</tr>
					<tr>
						<th>95% CI</th>
						<td>[{r2(summary['average']["abs_effect_lower"])}, {r2(summary['average']["abs_effect_upper"])}]</td>
						<td>[{r2(summary['cumulative']["abs_effect_lower"])}, {r2(summary['cumulative']["abs_effect_upper"])}]</td>
					</tr>
					<tr>
						<td> </td>
					</tr>
					<tr>
						<th>Relative effect (s.d.)</th>
						<td>{r2(summary['average']['rel_effect'] * 100)}%</td>
						<td>{r2(summary['cumulative']['rel_effect'] * 100)}%</td>
					</tr>
					<tr>
						<th>95% CI</th>
						<td>[{r2(summary['average']["rel_effect_lower"] * 100)}%, {r2(summary['average']["rel_effect_upper"] * 100)}%]</td>
						<td>[{r2(summary['cumulative']["rel_effect_lower"] * 100)}%, {r2(summary['cumulative']["rel_effect_upper"] * 100)}%]</td>
					</tr>
				</table>
				<pre>
					Posterior tail-area probability p: {r2(r.p_value)}<br />
					Posterior prob. of a causal effect: {r2((1 - r.p_value) * 100)}%
				</pre>
			</div>
			<div className="ImpactMetricCard__meta">
				<div>
					<h3>Observation vs prediction</h3>
					<ComposedChart width={550} height={250}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="date" type="category" allowDuplicatedCategory={false} />
						<YAxis dataKey="value" />
						<Tooltip contentStyle={{ 'background': '#444' }} formatter={ftt} />
						<Legend />
						<ReferenceLine x={change_date} stroke="red" label={<Label value="Change" position="insideTopRight" />} />
						<Area dataKey="value" data={band} name={"confidence interval"} key={"interval"} stroke="#090" />
						<Line dataKey="value" data={pred} name={"predicted"} key={"pred"} dot={false} stroke="#0d0" strokeDasharray="4 1" />
						<Line dataKey="value" data={obs} name={"observed"} key={"obs"} stroke="#fff" dot={false} />
					</ComposedChart>
					<h3>Estimated effect day by day</h3>
					<ComposedChart width={550} height={250}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="date" type="category" allowDuplicatedCategory={false} />
						<YAxis dataKey="value" />
						<Tooltip contentStyle={{ 'background': '#444' }} formatter={ftt} />
						<Legend />
						<ReferenceLine x={change_date} stroke="red" label={<Label value="Change" position="insideTopRight" />} />
						<Area dataKey="value" data={point_effects['band']} name={"confidence interval"} key={"interval"} stroke="#090" />
						<Line dataKey="value" data={point_effects['mean']} name={"point effect"} key={"pred"} dot={false} stroke="#0d0" strokeDasharray="4 1" />
					</ComposedChart>
					<h3>Estimated cumulative effect</h3>
					<ComposedChart width={550} height={250}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="date" type="category" allowDuplicatedCategory={false} />
						<YAxis dataKey="value" />
						<Tooltip contentStyle={{ 'background': '#444' }} formatter={ftt} />
						<Legend />
						<ReferenceLine x={change_date} stroke="red" label={<Label value="Change" position="insideTopRight" />} />
						<Area dataKey="value" data={cumulative_effects['band']} name={"confidence interval"} key={"interval"} stroke="#090" />
						<Line dataKey="value" data={cumulative_effects['mean']} name={"cumulative effect"} key={"pred"} dot={false} stroke="#0d0" strokeDasharray="4 1" />
					</ComposedChart>
				</div>
			</div>
		</div>
	);
}

export default ImpactMetricCard;
