import './style.scss';
import React, { useState, useEffect } from 'react';
import CognyAPI from 'components/_classes/CognyAPI';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
//import { Elements } from '@stripe/react-stripe-js';
//import { PaymentElement } from '@stripe/react-stripe-js';

//import { loadStripe } from '@stripe/stripe-js/pure';

function SettingsBilling(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  //const [stripeOptions, setStripeOptions] = useState([]);
  const [billingData, setBillingData] = useState({});
  const [cookies] = useCookies(["token"]);
  const params = useParams();
  const warehouseId = params.warehouse_id;

  useEffect(() => {
    let mounted = true;
    if (cookies.token) {
      const api = new CognyAPI(cookies.token);
      api.getBillingLink(warehouseId)
        .then(
          (result) => {
            if (!mounted) return;
            setBillingData(result);
            setIsLoaded(true);
          },
          (error) => {
            if (!mounted) return;
            setIsLoaded(true);
            setError(error);
          }
        );
    }
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div className="SettingsBilling">
        <div>
          {isLoaded ?
            <>
              {billingData.billing_type === "stripe" ?
                <div>
                  <h2>Stripe billing</h2>
                  <a className="Button" href={billingData.link} rel="noreferrer">Manage billing</a>
                </div>
                :
                <div>
                  <h2>Invoice billing</h2>
                  <p>Contact invoice@cogny.com if you have any questions.</p>
                </div>
              }
            </>
            : ""}
        </div>
      </div>
    );
  }
}

export default SettingsBilling;
