const questions = [
  {
    question: "How credits work",
    answer: `
- Credits are the currency within our platform that you use to operate features like Processing, Data Sources, and Tracking server.

- The number of credits each feature uses can vary. For example, processing 1GB of data might use 1 credit, whereas more complex operations will use more.
`,
  },
  {
    question: "Monthly credit tiers",
    answer: `
Cogny offers four distinct tiers, each providing a monthly allocation of credits to suit different levels of usage. Here's what you need to know about each tier.
- **Micro** - Designed for light users, perfect for small-scale projects.
- **Small** - Ideal for growing businesses with moderate data needs.
- **Medium** - Geared towards large businesses requiring extensive data processing.
- **Large** - Tailored for enterprises with strategic, high-volume data operations.
`,
  },
  {
    question: "What happens if you exceed your monthly credits?",
    answer: `
Should your operations exceed the monthly credit allotment, additional credits will automatically be purchased to keep your services running smoothly. The cost per additional credit is fixed and depends on your tier.
`,
  },
  {
    question: "Monitoring credit usage",
    answer: `
Easily keep track of your credit usage through our dashboard, which offers

- **Detailed Breakdown** - View how credits are spent across Processing, Data Sources, and Tracking server to identify high-usage areas.

- **Credit Usage Graph** - Check your current credit usage against your monthly credit limit in a clear, visual format.

Stay informed and efficiently manage your resources with real-time updates and comprehensive insights, ensuring you maximize the value derived from Cogny.
`,
  },
];

export default questions;
