import './style.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import LoadingDots from 'components/atoms/LoadingDots';
import Field from 'components/molecules/Field';
import { useCookies } from 'react-cookie';

import PageHeader from 'components/organisms/PageHeader';
import { useAppData } from 'hooks/useAppData';

function FieldRow(props) {
  return (
    <div className="FieldRow">
      {props.children}
    </div>
  );
}

function SetupWarehouse(props) {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [formData, setFormData] = useState("");
  const [formError, setFormError] = useState(null);
  const [formIsLoaded, setFormIsLoaded] = useState(true);

  const navigate = useNavigate();
  const params = useParams();
  const [cookies] = useCookies(["token"]);
  // eslint-disable-next-line no-useless-escape
  const emailValidation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;
  const warehouseId = params.warehouse_id;
  const { getPath } = useAppData();

  const validateEmail = (email) => {
    return email === "" || emailValidation.test(email);
  }

  useEffect(() => {
    let mounted = true;
    const api = new CognyAPI(cookies.token);
    api.getWarehouse(warehouseId)
      .then(
        (result) => {
          if (!mounted) return;
          setData(result);
          setIsLoaded(true);
        },
        (error) => {
          if (!mounted) return;
          setError(error);
          setIsLoaded(true);
        }
      );
    return () => {
      mounted = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, warehouseId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail(formData)) {
      return;
    }

    const newWarehouse = {
      analytics_email: formData,
    };

    if (cookies.token) {
      setFormIsLoaded(false);
      const api = new CognyAPI(cookies.token);

      if (window.dataLayer) {
        window.dataLayer.push({ event: "setup_warehouse", token: cookies.token });
      }

      api.setupWarehouse(warehouseId, newWarehouse)
        .then(
          (result) => {
            setFormIsLoaded(true);
            navigate(getPath("dashboard"));
          },
          (error) => {
            console.log("Error in form", error);
            setFormIsLoaded(true);
            setFormError(error);
          }
        )
    }
  }

  const Validation = (props) => {
    const { className, rules, message } = props;

    if (formData !== "" && rules) {
      return <div className={"Validation " + className}>{message}</div>;
    }

    return "";
  };

  const ErrorMessage = (props) => {
    const { error, className = "" } = props;

    if (typeof error.message === "undefined") {
      return "";
    } else {
      return (
        <div className={"ErrorMessage " + className}>{error.message}</div>
      );
    }
  }

  const LoadingMessage = (props) => {
    const { className = "", children } = props;

    return (
      <div className={"LoadingMessage " + className}>{children}</div>
    );
  }

  const ProjectId = (props) => {
    const { data, isLoaded, error, className = "" } = props;

    let projectId = <LoadingDots />;

    if (isLoaded && error) {
      projectId = <>n/a</>;
    }

    if (data.name) {
      projectId = <>cogny-{data.name}</>;
    }

    return (
      <span className={"ProjectId " + className}>
        {projectId}
      </span>
    );
  }

  return (
    <div className="SetupWarehouse">
      <PageHeader pretitle="Data warehouse" title="Create data warehouse" />

      <div className="SetupWarehouse">

        {data.status === "Active" ?
          <>You have already created a data warehouse.</>
          :
          <Form className="SetupWarehouse__form" onSubmit={handleSubmit}>
            <div className="SetupWarehouse__info">
              <p>Cogny need to create a BigQuery data warehouse to store all your data. After completion, you will see it in your Google console.</p>
              <p>The project ID will be <ProjectId data={data} isLoaded={isLoaded} error={error} /></p>
            </div>

            <FieldRow>
              <Field
                type="text"
                id="SetupWarehouse__analytics_email"
                className="SetupWarehouse__title"
                name="Google Analytics User (optional)"
                disabled={!formIsLoaded}
                value={formData}
                onChange={(e) => { setFormData(e.target.value) }}
                validation={<Validation rules={!validateEmail(formData)} message="Please enter a valid email" />}
                placeholder="Email address of user who will connect Google Analytics 4"
              />
            </FieldRow>

            <FieldRow>
              {formIsLoaded ?
                <Button type="submit" className="SetupWarehouse__submit" icon="arrowRight" iconSide="right" disabled={formData !== "" && !emailValidation.test(formData)}>
                  Continue
                </Button>
                :
                <Button type="submit" className="SetupWarehouse__submit" iconSide="right" disabled>
                  <LoadingDots />
                </Button>
              }
            </FieldRow>

            {formError &&
              <FieldRow>
                <ErrorMessage error={formError} className="SetupWarehouse__error" />
              </FieldRow>
            }

            {!formIsLoaded &&
              <FieldRow>
                <LoadingMessage isLoaded={formIsLoaded} className="SetupWarehouse__loadingMessage">Please wait while we're preparing your BigQuery data warehouse. This usually takes around a minute to complete.</LoadingMessage>
              </FieldRow>
            }
          </Form>
        }
      </div>
    </div>
  )
}

export default SetupWarehouse;
