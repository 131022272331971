import './style.scss';

function Form(props) {
  const { className = "", id, onSubmit, children } = props;
  return (
    <form className={"Form " + className} id={id} onSubmit={onSubmit}>
      {children}
    </form>
  );
}

export default Form;