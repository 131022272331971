import "./style.scss";
import Button from "components/atoms/Button";
import LoadingDots from "components/atoms/LoadingDots";
import React, { useCallback, useEffect, useRef, useState } from "react";

function ModalDialog(props) {
  const { modalClassName = "" } = props;
  const { toolbar } = props;
  const modalState = props.state ? props.state.modalState : undefined;
  const updateModalState = props.state ? props.state.updateModalState : undefined;

  // local state as fallback
  const [localOpen, setLocalOpen] = useState(false);

  // pick between local and prop state
  const open = modalState?.open !== undefined ? modalState.open : localOpen;
  const setOpen = updateModalState ? (value) => updateModalState({ open: value }) : setLocalOpen;

  const ref = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open)
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (open) {
      ref.current?.showModal();
    } else {
      ref.current?.close();
    }
  }, [open]);

  const handleModalClick = (e) => {
    if (e.target.classList.contains('ModalDialog')) setOpen(false);
  }

  return (
    <>
      {props.label &&
        <Button
          className={props.className}
          onClick={handleClick}
          onMouseDown={(e) => e.stopPropagation()}
          title={props.title}
          iconSide={props.iconSide}
          icon={props.icon}
          theme={props.theme}
        >
          {props.label}
        </Button>
      }

      {!props.label && props.button &&
        <button
          className={props.className}
          onClick={handleClick}
          onMouseDown={(e) => e.stopPropagation()}
          title={props.title}
        >
          {props.button}
        </button>
      }

      {open &&
        <dialog
          ref={ref}
          className={"ModalDialog " + modalClassName}
          onClick={handleModalClick}
          data-size={props.size ?? null}
        >
          {props.modalTitle &&
            <div className="ModalDialog__header">
              <h1 className="ModalDialog__title">
                {props.modalTitle}
                {props.modalTitleExtra &&
                  <span>{props.modalTitleExtra}</span>
                }
              </h1>
            </div>
          }

          <main className="ModalDialog__main">
            {props.children}
          </main>

          <div className="ModalDialog__toolbar">
            {props.state.modalState.toolbarExtras &&
              <div className="ModalDialog__toolbarExtras">
                {props.state.modalState.toolbarExtras}
              </div>
            }

            {props.toolbar && props.state.modalState.form ?
              <>{props.state.modalState.form.isLoaded ?
                <>
                  {toolbar.secondary &&
                    <Button
                      type="button"
                      onClick={toolbar.secondary.onClick}
                      className="ModalDialog__button--secondary"
                    >
                      {toolbar.secondary.label}
                    </Button>
                  }

                  {toolbar.primary &&
                    <Button
                      type="submit"
                      onClick={toolbar.primary.onClick}
                      className="ModalDialog__button--primary"
                      icon="arrowRight"
                      iconSide="right"
                    >
                      {toolbar.primary.label}
                    </Button>
                  }
                </>
                :
                <Button
                  type="submit"
                  className="ModalDialog__button--primary"
                  disabled
                >
                  <LoadingDots />
                </Button>
              }
              </>
              :
              <Button onClick={() => setOpen(!open)}>Close</Button>
            }
          </div>
        </dialog>
      }
    </>
  )
};

export default ModalDialog;