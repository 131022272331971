import './style.scss';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from 'components/atoms/Button';
import LoadingDots from 'components/atoms/LoadingDots';
import { usePageSidebar } from 'hooks/usePageSidebar';
import { useAppData } from 'hooks/useAppData';

const PageSidebarList = (props) => {
  const { getData, getBasePath } = usePageSidebar();
  const { items, emptyMessage, hasFavorites, title, sorting } = getData();
  const basePath = getBasePath();

  const [sortedList, setSortedList] = useState(items ?? []);

  const [sort, setSort] = useState(0);
  const nextSort = () => setSort(currentState => (currentState + 1) % sorting.length);

  const [source, setSource] = useState(0);
  const sources = ['All', 'Favorites'];
  const nextSource = () => setSource(currentState => (currentState + 1) % sources.length);

  const metaKey = sorting[sort].meta;

  useEffect(() => {
    if (sorting && sorting.length > 0) {
      const sortFunction = sorting[sort].sortFunction;
      const newOrder = sortFunction(items ?? []);
      setSortedList(newOrder);
    }
  }, [sort, items, sorting]);

  const PageSidebarSources = () => {
    const newSources = hasFavorites ? sources : [sources[0]];

    return (
      <>
        {
          newSources.map(item => {
            return (
              <Button
                key={item}
                className="PageSidebarList__source"
                onClick={nextSource}
                disabled={item === newSources[source]}
              >
                {title ?? item}
              </Button>
            )
          })
        }
      </>
    );
  }

  return (
    <div className="PageSidebarList">
      <div className="PageSidebarList__header">
        <div className="PageSidebarList__sources">
          <PageSidebarSources />
        </div>

        {sorting ?
          <Button className="PageSidebarList__sort" icon="sort" iconSide="left" onClick={nextSort}>{sorting[sort].label}</Button>
          :
          <div className="PageSidebarList__count">{sortedList.length}</div>
        }
      </div>
      <div className="PageSidebarList__items">
        {sortedList && sortedList
          .filter(item => {
            if (sources[source] === "Favorites" && item.favorite !== true) {
              return false;
            } else {
              return true;
            }
          })
          .map(item => {
            let meta = item[metaKey];
            if (sorting[sort].metaFormatter) {
              const metaFormatter = sorting[sort].metaFormatter;
              meta = metaFormatter(meta);
            }

            return (
              <NavLink
                key={item.id}
                className={({ isActive }) => "PageSidebarList__item" + (isActive ? " PageSidebarList__item--current" : "")}
                to={basePath + item.id}
              >
                {item.name !== "" ?
                  <div className="PageSidebarList__label">{item.name}</div>
                  :
                  <div className="PageSidebarList__label PageSidebarList__label--warning">ERROR! NAME IS MISSING</div>
                }
                <div className="PageSidebarList__meta">{meta}</div>

              </NavLink>
            )
          })
        }
      </div>
      <div className="PageSidebarList__empty">
        {
          sources[source] === "Favorites" ?
            "You don't have any favorites"
            :
            emptyMessage ?? "There's nothing here"
        }
      </div>
    </div>
  );
}

function PageSidebar() {
  const { getData } = usePageSidebar();
  const { isLoaded, error, buttonLabel, buttonTarget } = getData();
  const { getPath } = useAppData();

  if (error) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  }

  if (!isLoaded) return (
    <div className="PageSidebar">
      <div className="PageSidebar__loading">
        <LoadingDots />
      </div>
    </div>
  );

  return (
    <div className="PageSidebar">
      <div className="PageSidebar__inner">
        <Button type="link" href={getPath(buttonTarget ?? null)} className="PageSidebar__new" icon="plus">{buttonLabel ?? "New item"}</Button>
        <PageSidebarList />
      </div>
    </div>
  )
}

export default PageSidebar;