import './style.scss';
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CognyAPI from 'components/_classes/CognyAPI';
import { useParams } from 'react-router-dom';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import SubmitStatus from 'components/molecules/SubmitStatus';
import LoadingDots from 'components/atoms/LoadingDots';
import BackLink from 'components/molecules/BackLink';
import Header from 'components/molecules/Header';
import Field from 'components/molecules/Field';
import { useCookies } from 'react-cookie';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginButton from './LoginButton';
import axios from 'axios';
import config from './config';
import { useAppData } from 'hooks/useAppData';


function FieldRow(props) {
  return (
    <div className="FieldRow">
      {props.children}
    </div>
  );
}

const SubmitButton = ({ isLoaded, error }) => (
  <>
    <Button type="submit" className="SSGTMGuide__submit" icon="arrowRight" iconSide="right" disabled={!isLoaded}>Add server-side tracker</Button>
    <SubmitStatus isLoaded={isLoaded} error={error} parentClass="SSGTMGuide" successMessage="" />
  </>
);

function SSGTMGuide(props) {
  const [task, setTask] = useState({});
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [, setValidation] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const params = useParams();
  const [cookies] = useCookies(["token", "project"]);
  const nameValidation = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][a-zA-Z0-9-]*[A-Za-z0-9])$/;
  const gtmCodeValidation = /^.+$/;
  const warehouseId = params.warehouse_id;
  const [accessToken, setAccessToken] = useState("");
  const [gtmAccounts, setGtmAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContainer, setSelectedContainer] = useState("");
  const [, setLoading] = useState(false);
  const [containers, setContainers] = useState([]);
  const [newContainerName, setNewContainerName] = useState('');
  const [domain, setDomain] = useState("");
  const [setupType, setSetupType] = useState("");
  const { getPath } = useAppData();

  const onLoginSuccess = useCallback((response) => {
    setAccessToken(response.access_token);
    setIsLoggedIn(true);
    // Fetch user info
    axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        Authorization: `Bearer ${response.access_token}`
      }
    }).then(res => {
      setUser(res.data);
    }).catch(err => {
      console.log('Failed to fetch user info:', err);
    });
  }, []);

  const onLogout = () => {
    setIsLoggedIn(false);
    setUser({});
    setAccessToken("");
  };

  useEffect(() => {
    const fetchGtmAccounts = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/tagmanager/v2/accounts', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setGtmAccounts(response.data.account || []);
      } catch (error) {
        console.error('Failed to list GTM accounts:', error);
      }
    };

    if (accessToken) {
      fetchGtmAccounts();
    }
  }, [accessToken]);

  useEffect(() => {
    if (selectedAccount) {
      fetchContainers();
      setSelectedContainer('');
    }
  }, [selectedAccount, accessToken]);

  const fetchContainers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://www.googleapis.com/tagmanager/v2/accounts/${selectedAccount}/containers`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
      );
      const serverContainers = response.data.container.filter(container => container.usageContext.includes('server'));
      setContainers(serverContainers);

      if (serverContainers.length === 1) {
        setSelectedContainer(serverContainers[0].containerId);
      } else if (serverContainers.length > 0) {
        setSelectedContainer("");
      }

      setLoading(false);
    } catch (error) {
      console.error('Failed to list containers:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedContainer) {
      fetchAndGenerateConfig(selectedAccount, selectedContainer);
    }
  }, [selectedContainer, accessToken, selectedAccount]);

  const fetchAndGenerateConfig = async (selectedAccount, containerId) => {
    if (!containerId || !accessToken || !selectedAccount) return console.error('Missing required parameters');
    setLoading(true);
    try {
      const containerDetailsUrl = `https://www.googleapis.com/tagmanager/v2/accounts/${selectedAccount}/containers/${containerId}`;
      const containerDetailsResponse = await axios.get(containerDetailsUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const publicId = containerDetailsResponse.data.publicId;
      const environmentDetailsUrl = `${containerDetailsUrl}/environments/1`;
      const environmentsResponse = await axios.get(environmentDetailsUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const authorizationCode = environmentsResponse.data.authorizationCode;

      const configString = `id=${publicId}&env=1&auth=${authorizationCode}`;
      const configCode = btoa(configString);

      return configCode;
    } catch (error) {
      console.error('Failed to fetch container or environment details:', error);
    } finally {
      setLoading(false);
    }
  };

  const createContainer = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `https://www.googleapis.com/tagmanager/v2/accounts/${selectedAccount}/containers`, {
        name: newContainerName,
        usageContext: ['server'],
      }, {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
      );
      const newContainer = response.data;
      return newContainer.containerId;
    } catch (error) {
      console.error('Failed to create container:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const validateTask = (task) => {
    return task.host && task.gtm_code &&
      nameValidation.test(task.host) &&
      gtmCodeValidation.test(task.gtm_code);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newTaskState = { ...task };

    if (setupType === 'new') {
      const newContainerId = await createContainer();
      const newConfigCode = await fetchAndGenerateConfig(selectedAccount, newContainerId);
      newTaskState = { ...task, gtm_code: newConfigCode };
    } else if (setupType === 'existing') {
      const existingConfigCode = await fetchAndGenerateConfig(selectedAccount, selectedContainer);
      newTaskState = { ...task, gtm_code: existingConfigCode };
    }

    setTask(newTaskState);

    const validate = validateTask(newTaskState);

    if (!validate) {
      setValidation(false);
      setError({ message: 'Validation failed. Please check your input.' });
      return;
    }
    setValidation(true);

    setIsLoaded(false);

    if (cookies.token) {
      const api = new CognyAPI(cookies.token);

      if (window.dataLayer) {
        window.dataLayer.push({ event: "add_ssgtm", token: cookies.token });
      }
      api.addSSGTM(warehouseId, task)
        .then(
          (taskResult) => {
            setIsLoaded(true);
            setTask(taskState => ({
              ...taskState,
              ...taskResult
            }));

            navigate(getPath("server-side-gtm"));
          },
          (error) => {
            setIsLoaded(true);
            console.log(error);
            setError(error);
          }
        )
    }
  }

  const handleChange = (e) => {
    const idmap = {
      "SSGTMGuide__host": "host",
      "SSGTMGuide__gtm_code": "gtm_code",
      "SSGTMGuide__size": "size",
    };

    if (e.target && idmap[e.target.id]) {
      setTask(taskState => {
        const narr = { ...taskState };
        narr[idmap[e.target.id]] = e.target.value;
        return narr;
      });
    } else {
      setTask(taskState => {
        const narr = { ...taskState };
        narr['size'] = e.value;
        return narr;
      });
    }
  };

  const handleDomainChange = (e) => {
    const newDomain = e.target.value;
    setDomain(newDomain);
    setTask(taskState => ({ ...taskState, host: `gtm.${newDomain}` }));
    setNewContainerName(`${newDomain} - SST`);
  };

  const Validation = ({ id }) => {
    if (id === 'host') {
      if (!nameValidation.test(task.host)) {
        return "Host must be a valid domain name.";
      }
    }
    if (id === 'gtm_code') {
      if (typeof task.gtm_code === 'undefined' || !gtmCodeValidation.test(task.gtm_code)) {
        return "GTM Container code must be set.";
      }
    }
    return "";
  };

  const sizes = [
    { id: 'micro', name: 'Micro', description: 'Up to 400 000 monthly events', price: "75" },
    { id: 'small', name: 'Small', description: 'Up to 4 000 000 monthly events', price: "900" },
    { id: 'medium', name: 'Medium', description: 'Up to 40 000 000 monthly events', price: "2 990" },
    { id: 'large', name: 'Large', description: 'More than 500 000 000 monthly events', price: "6 655" },
    { id: 'xlarge', name: 'XLarge', description: 'Contact us tom@cogny.com', price: "custom" },
  ];

  const options = sizes.map(c => {
    return { value: c.id, label: c.name, description: c.description };
  });

  return (
    <>
      <GoogleOAuthProvider clientId={config.googleClientId}>
        <Header>
          <BackLink to={getPath("server-side-gtm")} />
        </Header>
        <div className="SSGTMGuide">
          <h1 className="SSGTMGuide__title">Add server-side tracker</h1>
          <Form className="SSGTMGuide__form" onSubmit={handleSubmit}>
            <FieldRow>
              <Field
                type="select"
                id="setupType"
                name="Setup Type"
                classNamePrefix="Select"
                options={[
                  { value: 'new', label: 'Create new container' },
                  { value: 'existing', label: 'Use Existing Container' },
                  { value: 'manual', label: 'Manual setup' },
                ]}
                onChange={(selectedOption) => {
                  setSetupType(selectedOption.value);
                }}
                placeholder="Select setup type"
              />
            </FieldRow>
            {setupType && (
              <>
                {(setupType === 'new' || setupType === 'existing') && !isLoggedIn && (
                  <LoginButton onLoginSuccess={onLoginSuccess} onLogout={onLogout} isLoggedIn={isLoggedIn} user={user} />
                )}
                {(isLoggedIn || setupType === 'manual') && (
                  <>
                    {setupType === 'new' && (
                      <>
                        <LoginButton onLoginSuccess={onLoginSuccess} onLogout={onLogout} isLoggedIn={isLoggedIn} user={user} />
                        <FieldRow>
                          <Field
                            type="select"
                            id="accountSelect"
                            name="Account"
                            classNamePrefix="Select"
                            options={gtmAccounts.map(account => ({
                              value: account.accountId,
                              label: account.name
                            }))}
                            onChange={(selectedOption) => setSelectedAccount(selectedOption.value)}
                            placeholder="Select an account"
                            isSearchable={true}
                          />
                        </FieldRow>
                        <FieldRow>
                          <Field
                            type="text"
                            id="SSGTMGuide__domain"
                            className="SSGTMGuide__domain"
                            name="Domain"
                            value={domain}
                            onChange={handleDomainChange}
                            placeholder="yourdomain.com"
                          />
                        </FieldRow>
                      </>
                    )}
                    {setupType === 'existing' && (
                      <>
                        <LoginButton onLoginSuccess={onLoginSuccess} onLogout={onLogout} isLoggedIn={isLoggedIn} user={user} />
                        <FieldRow>
                          <Field
                            type="select"
                            id="accountSelect"
                            name="Account"
                            classNamePrefix="Select"
                            options={gtmAccounts.map(account => ({
                              value: account.accountId,
                              label: account.name
                            }))}
                            onChange={(selectedOption) => setSelectedAccount(selectedOption.value)}
                            placeholder="Select an account"
                            isSearchable={true}
                          />
                        </FieldRow>
                        <FieldRow>
                          <Field
                            type="select"
                            id="containerSelect"
                            name="Available Server Containers"
                            classNamePrefix="Select"
                            options={containers.map(container => ({ value: container.containerId, label: container.name }))}
                            onChange={(selectedOption) => setSelectedContainer(selectedOption.value)}
                            placeholder="Select a container"
                            isSearchable={true}
                          />
                        </FieldRow>
                        <FieldRow>
                          <Field
                            type="text"
                            id="SSGTMGuide__domain"
                            className="SSGTMGuide__domain"
                            name="Domain"
                            value={domain}
                            onChange={handleDomainChange}
                            placeholder="yourdomain.com"
                          />
                        </FieldRow>
                      </>
                    )}
                    {setupType === 'manual' && (
                      <>
                        <FieldRow>
                          <Field
                            type="text"
                            id="SSGTMGuide__host"
                            className="SSGTMGuide__host"
                            name="Host"
                            value={task.host || ''}
                            onChange={handleChange}
                            validation={<Validation id="host" />}
                            placeholder="gtm.yourdomain.com"
                          />
                        </FieldRow>
                        <FieldRow>
                          <Field
                            type="text"
                            id="SSGTMGuide__gtm_code"
                            className="SSGTMGuide__gtm_code"
                            name="Container configuration"
                            value={task.gtm_code ? task.gtm_code : ''}
                            onChange={handleChange}
                            validation={<Validation id="gtm_code" />}
                            placeholder="aWXXXXYYYYYZZZZZZZZZZ.."
                          />
                        </FieldRow>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {setupType && (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Size</th>
                      <th>Recommended for sites</th>
                      <th>Cogny credits used monthly</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sizes.map(c => {
                      return (
                        <tr key={c.id}>
                          <td>{c.name}</td>
                          <td>{c.description}</td>
                          <td align="right">{c.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <FieldRow>
                  <Field
                    type="select"
                    id="SSGTMGuide__size"
                    className="SSGTMGuide__size"
                    name="Size"
                    options={options}
                    onChange={handleChange}
                    classNamePrefix="Select"
                    placeholder="Select size..."
                  />
                </FieldRow>
                <SubmitButton isLoaded={isLoaded} error={error} />
              </>
            )}
          </Form>
          {isLoaded ? "" : <LoadingDots />}
          {error ? <div>{error.message}</div> : ""}
        </div>
      </GoogleOAuthProvider>
    </>
  );
}

export default SSGTMGuide;
