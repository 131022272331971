import './style.scss';
import newBackground from './new-background.webp';
import newBackgroundAlt from './new-background-alt1.webp';
import { useOutsideData } from 'hooks/useOutsideData';
import useWindowSize from 'hooks/useWindowSize';

function OutsideContent(props) {
  const { children, background } = props;
  const { getOutsideBackgroundStyle } = useOutsideData();
  const outsideBackgroundStyle = getOutsideBackgroundStyle();
  const windowSize = useWindowSize();

  let preset;

  let backgroundImage = "";
  if (background === "main") {
    backgroundImage = newBackground;
  } else if (background === "alt") {
    backgroundImage = newBackgroundAlt;
  }

  if (background === "main" || background === "alt" || background === "faded") {
    const backgroundPosition = windowSize.width >= 768 ? "1600px auto" : "680px auto";
    preset = {
      backgroundImage: "url(" + backgroundImage + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top center",
      backgroundSize: backgroundPosition,
      backgroundBlendMode: "normal",
      width: "100%",
      height: 978,
      top: 0,
      left: "50%",
      right: 0,
      transform: "translateX(-50%)",
      opacity: background === "faded" ? 0.5 : 1,
    }
  }

  const hasBackgroundStyle = typeof outsideBackgroundStyle === "object" && Object.keys(outsideBackgroundStyle).length !== 0;

  let backgroundClassName = "OutsideContent__background ";

  return (
    <main className="OutsideContent">
      <div
        className={backgroundClassName}
        style={
          background === "custom" && hasBackgroundStyle ? outsideBackgroundStyle : preset
        }
      />

      <div className="OutsideContent__container">
        {children}
      </div>
    </main>
  );
}

export default OutsideContent;
