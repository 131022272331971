import { useAppData } from 'hooks/useAppData';
import './style.scss';
import { Link } from 'react-router-dom';

const menuItemsData = [
  {
    label: "Terms",
    href: "/terms",
  },
  {
    label: "Privacy",
    href: "/privacy",
  }
];

const scrollToTop = () => {
  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, currentScroll - (currentScroll / 5));
  }
};

const MenuItems = (props) => {
  const { getPrePath } = useAppData();

  return (
    <>
      {menuItemsData.map((item, index) => {
        return <Link onClick={scrollToTop} className="Footer__item" to={getPrePath(item.href)} key={index}>{item.label}</Link>
      })}
    </>
  );
}

function Footer(props) {
  const { className = "" } = props;
  const currentYear = new Date().getFullYear();
  return (
    <footer className={"Footer " + className}>

      &copy; {currentYear} Cogny <span style={{ margin: "0 5px" }}>&ndash;</span> <MenuItems />
    </footer>
  );
}

export default Footer;