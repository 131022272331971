const parseErrorMessage = (message) => {
  // Regular expression to find backtick-delimited text
  const regex = /`([^`]+)`|([^`]+)/g;
  let match;
  const messageObject = [];
  let plainMessage = '';

  // Parse the message and build messageObject and plainMessage
  while ((match = regex.exec(message)) !== null) {
    if (match[1]) {  // Matched text within backticks
      messageObject.push({ type: 'highlighted', text: match[1] });
      plainMessage += match[1]; // Append highlighted text without backticks to plainMessage
    } else if (match[2]) {  // Matched text outside of backticks
      messageObject.push({ type: 'string', text: match[2] });
      plainMessage += match[2]; // Append regular text to plainMessage
    }
  }

  return { messageObject, message: plainMessage };
};

export default parseErrorMessage;